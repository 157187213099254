import ISorting from "../models/sorting-model";
import IUser, { IUserMapper } from "../models/user-model";
import { SESSION_STORAGE_JWT_TOKEN } from "../Utils/constants";
import SessionService from "./Session-Service";
const BASE_URL = `${process.env.REACT_APP_BASE_URL}/consumers`;

class UserService {

    async getAll(limit?: number, from?: number, order?: ISorting, filters?: { [key: string]: string | Date | null | undefined }): Promise<any> {
        const requestOptions: RequestInit = {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${SessionService.getItem(SESSION_STORAGE_JWT_TOKEN)}`
            },
        }

        const urlParams = new URLSearchParams({
            ...limit && { _limit: limit.toString() },
            ...from && { _start: from.toString() },
            ...order && { _sort: `${order.orderBy}:${order.orderDirection}`},
            ...filters && { ...filters }
        })

        return await fetch(`${BASE_URL}?${urlParams}`, requestOptions)
            .then(res => res.json())
    }

    async getOne(id: number): Promise<IUser> {

        const requestOptions: RequestInit = {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${SessionService.getItem(SESSION_STORAGE_JWT_TOKEN)}`
            },
        }

        return await fetch(`${BASE_URL}/${id}`, requestOptions)
            .then(res => res.json())
            .then(res => IUserMapper.map(res.payload))

    }

    update(id: number, data: IUser) {
        const requestOptions: RequestInit = {
            method: 'PUT',
            headers: { 
                'Content-Type': 'application/json', 
                'Authorization': `Bearer ${SessionService.getItem(SESSION_STORAGE_JWT_TOKEN)}`
            },
            body: JSON.stringify(IUserMapper.mapReverse(data))
        }
        return fetch(`${BASE_URL}/${id}`, requestOptions)
            .then(res => res.json())
    }

    async getUsersNumber(clubs?: string[], categories?: string[]): Promise<any> {
        const requestOptions: RequestInit = {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${SessionService.getItem(SESSION_STORAGE_JWT_TOKEN)}`
            },
        }

        const urlParams = new URLSearchParams({
            ...clubs &&  { clubs: `[${clubs}]`},
            ...categories && { categories: `[${categories}]` }
        })
        return await fetch(`${BASE_URL}/premium?${urlParams}`, requestOptions)
            .then(res => res.json())
    }

    async delete(id: number) {
        const requestOptions: RequestInit = {
            method: 'DELETE',
            headers: { 
                'Content-Type': 'application/json', 
                'Authorization': `Bearer ${SessionService.getItem(SESSION_STORAGE_JWT_TOKEN)}`
            },
        }
        return fetch(`${process.env.REACT_APP_BASE_URL}/user/${id}`, requestOptions)
            .then(res => res.json())
    }
}

export default new UserService
