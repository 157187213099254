import locale from '../languages/locale';
export default interface IAnagrafica {
    id: number,
    username: string,
    email: string,
    provider?: string,
    confirmed?: boolean,
    blocked?: boolean,
}


export enum FiltersEntries {
    ANAGRAFICA= 'anagrafica_null',
    EMAIL= 'anagrafica.email_contains',
    USERNAME = 'anagrafica.username_contains',
    BLOCKED = 'anagrafica.blocked',
    NAME ='name_contains',
    PIVA = 'piva_contains',
    JOURNAL_NAME = 'journal_name_contains',
    QUESTION_ID = 'id',
    QUESTION_TITLE = 'title',
    QUESTION_NAME = 'name',
    QUESTION_CLUB = 'club',
    QUESTION_STATUS = 'status',
    QUESTION_EXPIRY_FROM = 'expiryFrom',
    QUESTION_EXPIRY_TO = 'expiryTo',
    QUESTION_PUBBLICATION_FROM = 'publicationFrom',
    QUESTION_PUBLICATION_TO = 'publicationTo',
    QUESTION_EXPECTED_FROM = 'expectedFrom',
    QUESTION_EXPECTED_TO = 'expectedTo'
}

export enum FiltersOptions {
    IGNORE = 'ignore',
    INCLUDE = 'include',
    PENDING = 'pending',
    APPROVED = 'approved',
    REFUSED = 'refused',
    IGNORE_INVITED = 'ignoreInvited',
    INCLUDE_INVITED = 'includeInvited'
}

export enum FiltersValues {
    FALSE = 'false',
    EMPTY = ' ',
    TRUE = 'TRUE'
}

export enum AnagraficaFilter {
    HIDE = 'hide',
    SHOW = 'show'
}
