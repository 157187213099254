import IJournalist, {IJournalistMapper} from "../models/journalist-model";
import ISorting from "../models/sorting-model";
import { SESSION_STORAGE_JWT_TOKEN } from "../Utils/constants";
import SessionService from "./Session-Service";
const BASE_URL = `${process.env.REACT_APP_BASE_URL}/journalist`;

class JournalistService {

    async getAll(limit?: number, from?: number, order?: ISorting, filters?: { [key: string]: string | Date | null | undefined }): Promise<any> {
        const requestOptions: RequestInit = {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${SessionService.getItem(SESSION_STORAGE_JWT_TOKEN)}`
            },
        }
        const urlParams = new URLSearchParams({
            ...limit && { _limit: limit.toString() },
            ...from && { _start: from.toString() },
            ...order && { _sort: `${order.orderBy}:${order.orderDirection}`},
            ...filters && { ...filters }
        })

        return await fetch(`${BASE_URL}?${urlParams}`, requestOptions)
            .then(res => res.json())
    }

    async getOne(id: number): Promise<IJournalist> {
        const requestOptions: RequestInit = {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${SessionService.getItem(SESSION_STORAGE_JWT_TOKEN)}`
            },
        }
        return await fetch(`${BASE_URL}/${id}`, requestOptions)
            .then(res => res.json())
            .then(res => IJournalistMapper.map(res.payload))
    }

    update(id: number, data: IJournalist) {
        const requestOptions: RequestInit = {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${SessionService.getItem(SESSION_STORAGE_JWT_TOKEN)}`
            },
            body: JSON.stringify(IJournalistMapper.mapReverse(data))
        }
        return fetch(`${BASE_URL}/${id}`, requestOptions)
            .then(res => res.json())
    }

    add(journalist: IJournalist) {
        const requestOptions: RequestInit = {
            method: 'POST',
            headers: {
                'Content-type': 'application/json',
                'Authorization': `Bearer ${sessionStorage.getItem(SESSION_STORAGE_JWT_TOKEN)}`
            },
            body: JSON.stringify(journalist)
        }
        return fetch(`${BASE_URL}s`, requestOptions)
            .then(res => res.json())
    }
    async delete(journalistId: number) {
        const requestOptions: RequestInit = {
            method: 'DELETE',
            headers: {
                'Content-type': 'application/json',
                'Authorization': `Bearer ${SessionService.getItem(SESSION_STORAGE_JWT_TOKEN)}`
            },

        }
        return fetch(`${process.env.REACT_APP_BASE_URL}/user/${journalistId}`, requestOptions)
            .then(res => res.json())
    }
}

export default new JournalistService
