import { Chip } from '@material-ui/core';
import { StarRate } from '@material-ui/icons';
import MaterialTable, { Column } from 'material-table';
import React, { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import locale from '../../../../../../languages/locale';
import IQuestion, { IQuestionFilters } from '../../../../../../models/question-model';
import { SortOrder } from '../../../../../../models/sorting-model';
import QuestionService from '../../../../../../services/Question-Service';
import { PAGINATION_DEFAULT_SIZE } from '../../../../../../Utils/constants';
import { Utils } from '../../../../../../Utils/utils';
import TableView from '../../../../components/TableView';
import useStyles from "../../styles";

function QuestionsPending() {
    const classes = useStyles();
    const location = useLocation();
    const history = useHistory();
    const [isLoading, setIsLoading] = useState(true);
    const [data, setData] = useState<IQuestion[]>([]);

    const tableKeyField: string = 'id'

    const columns: Column<object>[] = [
        {
            render: (rowData: any) =>
                rowData.is_premium ? <StarRate color='secondary' /> : <></>,
            cellStyle: { paddingTop: 0, paddingBottom: 0, width: '5%' },
            sorting: false
        },
        {
            title: locale.table.id,
            field: tableKeyField,
        },
        {
            title: locale.table.title,
            field: 'title',
        },
        {
            title: locale.table.category,
            field: 'category',
        },
        {
            title: locale.table.pubblication_date,
            field: 'specs.publication',
            type: 'date'
        },
        {
            title: locale.table.expiry_date,
            field: 'specs.expiry',
            type: 'date',
        },
        {
            title: locale.table.event_date,
            field: 'specs.expected',
            type: 'date'
        },
        {
            title: locale.table.status,
            render: (rowData: any) => {
                const questionStatus = rowData.specs?.approved
                return <Chip 
                    label={Utils.getQuestionStatusLabel(questionStatus)} 
                    classes={{ root: classes[Utils.getQuestionStatusColor(questionStatus)] }} 
                />
            },
            cellStyle: { paddingTop: 0, paddingBottom: 0 }
        }
    ]

    const order = { orderBy: 'expiry', orderDirection: SortOrder.ASC }
    const filter: IQuestionFilters = {
        approved_null: 'true'
    }

    //SERVICE GET
    useEffect(() => {
        if (isLoading) {
            QuestionService.getAll(PAGINATION_DEFAULT_SIZE, 0, order, filter)
                .then(res => {
                    if (res.payload)
                        setData(res.payload)
                    else
                        setData([])
                })
                .then(() => setIsLoading(false))
        }
    }, [isLoading]);

    const onEdit = (rowData: any) => { history.push(`${location.pathname}/questions/${rowData.id}`) };

    return <>
            <TableView
                tableKeyField={tableKeyField}
                title={locale.table.pending_questions}
                columns={columns}
                data={data}
                isLoading={isLoading}
                options={{
                    pageSize: PAGINATION_DEFAULT_SIZE,
                    actionsColumnIndex: -1,
                    search: false,
                    sorting: false,
                    paging: false
                }}
                actions={[{
                    icon: 'drive_file_rename_outline',
                    tooltip: locale.shared.edit,
                    onClick: (e: any, rowData: any) => { onEdit(rowData) },
                },]}
            />
        </>
}

export default QuestionsPending;
