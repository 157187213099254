import './App.css';
import {BrowserRouter} from "react-router-dom";
import {Redirect, Route, Switch} from "react-router";
import SignIn from "./pages/sign-in/SignIn";
import {createMuiTheme, MuiThemeProvider} from "@material-ui/core";
import SignUp from "./pages/sign-up/SignUp";
import Dashboard from "./pages/dashboard/Dashboard";
import { SnackbarProvider } from 'notistack';
import Checkout from './pages/checkout/Checkout';

function App() {
    const theme = createMuiTheme({
        palette: {
            primary: {
                main: '#447D74'
            },
            secondary: {
                main: '#F97B65'
            }
        }
    });

    return (
        <div className="App">
            <MuiThemeProvider theme={theme}>
                <SnackbarProvider maxSnack={3}>
                    <BrowserRouter>
                        <Switch>
                            <Route path={'/payment:success?:session_id?'} component={Checkout} />
                            <Route path={'/dashboard'} component={Dashboard}/>
                            <Route path={'/sign-up'} component={SignUp}/>    
                            <Route exact path={'/'} component={SignIn}/>
                            <Redirect from="*" to="/" />
                        </Switch>
                    </BrowserRouter>
                </SnackbarProvider>
            </MuiThemeProvider>
        </div>
    );
}

export default App;
