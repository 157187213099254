import { Mapper } from "./Mapper";
import moment from 'moment';
import IPremium, { IPremiumMapper, IServerPremium } from './premium-model';
import IQuestion, { IQuestionMapper, IServerQuestion} from './question-model';

export default interface ITransaction {
    id: string,
    premium: number,
    payment: number,
    invoiceNumber: string,
    invoiceDate: Date,
    question: number,
    questionTitle?: string
    /*TODO add premium && question interface */
};

export interface IServerTransaction {
    id: string,
    company: number,
    Payment: number,
    invoice_number: string,
    invoice_date: string,
    question: number,

    /*TODO add premium && question interface */

};

export abstract class ITransactionMapper implements Mapper<ITransaction, IServerTransaction> {
    
    static map(o: IServerTransaction): ITransaction {
        const object = {} as ITransaction

        object.id = o.id
        object.premium = o.company
        object.payment = o.Payment
        object.invoiceNumber = o.invoice_number
        object.invoiceDate = new Date(o.invoice_date)
        object.question = (o.question)
               
        /* TODO map question && premium */

        return object
    };

    static mapReverse(o: ITransaction): IServerTransaction {
        const object = {} as IServerTransaction

        object.id = o.id
        object.company = o.premium
        object.Payment = o.payment
        object.invoice_number = o.invoiceNumber
        object.invoice_date = moment(o.invoiceDate).toISOString()
        object.question = (o.question)

        /* TODO map question && premium */

        return object
    };

     static mapArray(models: IServerTransaction[]): ITransaction[] {
        return models.map(m => ITransactionMapper.map(m))
    };

    static mapReverseArray(models: ITransaction[]) : IServerTransaction[] {
        return models.map(m => ITransactionMapper.mapReverse(m))
    }; 
}