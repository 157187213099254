import React, {useState, useEffect} from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import TransactionService from '../../services/Transaction-Service';
import { Card, CardHeader, CardContent, Box, CircularProgress, CardActions, Button } from '@material-ui/core'
import locale from '../../languages/locale';
import './Checkout.css';
import { Utils } from '../../Utils/utils';
import PaymentService from '../../services/Payment-Service';
import { useSnackbar } from 'notistack';

function Checkout() {
    let params : any = {};
    params.session_id= new URLSearchParams(useLocation().search).get('session_id')
    params.success = new URLSearchParams(useLocation().search).get('success')
    const [success, setSuccess] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(true);
    const history = useHistory();
    const { enqueueSnackbar } = useSnackbar();
    const [retry, setRetry] = useState<boolean>(false);
    const [questionId, setQuestionId] = useState<string | undefined>();

    useEffect(() => {
        if(params.success === 'true'){
            TransactionService.postTransaction(params.session_id)
                .then(res => {
                    if(res.error)
                        setSuccess(false);
                    else
                        setSuccess(true);
                })
                .catch(err => {
                    console.log('Incoming error ', err)
                    setSuccess(false);
                })
                .finally(() => setLoading(false))
        } else {
            setLoading(false)
        }
    },[]);

    useEffect(() => {
        if (retry) {
            setLoading(true)
            TransactionService.retrieveSession(params.session_id)
                .then(res => {
                    if (res?.metadata?.question_id)
                        setQuestionId(res.metadata.question_id)
                    else {
                        setLoading(false)
                        Utils.responseError(enqueueSnackbar)
                    }
                })
                .catch(err => {
                    console.log('Incoming error ', err)
                    setLoading(false)
                    Utils.responseError(enqueueSnackbar)
                })
                .finally(() => setRetry(false))
        }
    }, [retry]);

    useEffect(() => {
        if (questionId) {
            TransactionService.getSessionId(questionId)
                .then(res => {
                    if (res.error) {
                        console.log('Incoming error ', res.err)
                        setLoading(false)
                        Utils.responseError(enqueueSnackbar)
                    } else {
                        PaymentService.redirectToCheckout(res.payload.id)
                    }
                })
                .catch(err => {
                    console.log('Incoming error ', err)
                    setLoading(false)
                    Utils.responseError(enqueueSnackbar)
                })
        }
    }, [questionId]);

    return <>
        <Card 
            key='checkout-card'
            className='checkout__card'
        >
            <CardHeader
                key='checkout-header'
                title={locale.checkout.title}
            />
            {loading ?
                <Box
                    display={'flex'}
                    justifyContent={'center'}
                    alignItems={'center'}
                    height={'50%'}
                    margin={'1rem 0'}
                >
                    <CircularProgress />
                </Box>
                :
                <>
                    <CardContent 
                        key='checkout-body'
                        className='checkout__card-body'
                    >
                        {success ?
                                locale.checkout.positiveOutcome
                        :
                            locale.checkout.negativeOutcome
                        }
                    </CardContent>
                    <CardActions 
                        className='checkout__card-footer'
                    >
                        
                        {!success && 
                            <Button
                                type="button"
                                variant="contained"
                                color="secondary"
                                size="small"
                                onClick={() => setRetry(true)}
                            >
                                {locale.checkout.retry}
                            </Button>
                        }
                        <Button
                            type="button"
                            variant="contained"
                            color="primary"
                            size="small"
                            onClick={() => history.push('/dashboard/questions')}
                        >
                            {locale.checkout.next}
                        </Button>
                        
                    </CardActions>
                </>
            }
        </Card>
    </>  
}
export default Checkout
