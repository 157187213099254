import { SESSION_STORAGE_JWT_TOKEN } from '../Utils/constants';
import SessionService from './Session-Service';
import IPrice from '../models/price-model' 

const BASE_URL = `${process.env.REACT_APP_BASE_URL}/price`;

class PriceService {
    async getPrice(amount: number): Promise<any> {
        const requestOptions: RequestInit = {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${SessionService.getItem(SESSION_STORAGE_JWT_TOKEN)}`
            },
        }
        return await fetch(`${BASE_URL}/${amount}`, requestOptions)
            .then(res => res.json())
    }
}

export default new PriceService;