import React, {useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import "./User-detail.css";
import IUser from '../../../models/user-model';
import IClub from '../../../models/club-model';
import UserService from '../../../services/User-Service';
import AnagraficaService from "../../../services/Anagrafica-Service";
import ClubService from "../../../services/Club-Service";
import CustomForm, {FormType, DataType, FieldsProps} from '../../../pages/dashboard/subs/question-form/customForm';
import Tab from '@material-ui/core/Tab';
import TabContext from '@material-ui/lab/TabContext';
import TabList from '@material-ui/lab/TabList';
import TabPanel from '@material-ui/lab/TabPanel';
import Paper from '@material-ui/core/Paper';
import { useSnackbar } from 'notistack';
import { Utils } from '../../../Utils/utils';
import { List, ListItem, ListItemIcon, ListItemText } from '@material-ui/core';
import EventIcon from '@material-ui/icons/Event';
import PublishIcon from '@material-ui/icons/Publish';
import GetAppIcon from '@material-ui/icons/GetApp';
import StarsIcon from '@material-ui/icons/Stars';
import QuestionAnswerIcon from '@material-ui/icons/QuestionAnswer';
import RateReviewIcon from '@material-ui/icons/RateReview';
import ThumbUpIcon from '@material-ui/icons/ThumbUp';
import ThumbDownIcon from '@material-ui/icons/ThumbDown';
import AccountBoxIcon from '@material-ui/icons/AccountBox';
import StarIcon from '@material-ui/icons/Star';
import UploadService from '../../../services/Upload-Service';
import {Dialog, DialogContent, DialogActions, DialogTitle, Button, Grid} from '@material-ui/core';
import Avatar from '@material-ui/core/Avatar';
import ArrowBack from '@material-ui/icons/ArrowBack';
import { AvailableInterfaces } from '../../../models/available-interfaces';

function UserDetail() {

    const { enqueueSnackbar } = useSnackbar();
    const [isLoading, setIsLoading] = useState(true);
    let params : any = useParams();
    const [showDialog, setShowDialog] = useState(false);
    const [showImagePicker, setShowImagePicker] = useState(false);
    const [disableButton, setDisableButton] = useState(true); 
    const [tabValue, setTabValue] = useState('1');
    const [initCheck, setInitCheck] = useState(false);
    const [clubs, setClubs] = useState<IClub[]>([]);
    const [pics, setPics] = useState<any[]>([]);
    const [currentUser, setCurrent] = useState<IUser>({
        id: 0,
        username: '',
        email: '',
        challenges: 0,
        nonUserChallenged: 0,
        userChallenged: 0,
        challengesReceived: 0,
        challengesWon: 0,
        challengesMatched: 0,
        challengesLost: 0,
        pointsEarned: 0,
        pointsSpent: 0,
        balance: 0,
        lastAnswer: new Date(),
        club: [],
        clubArray: [], 
        profilePic: '',
        questionAmount: 0, 
        answerAmount: 0, 
        anagrafica: {id: 0, username: '', email: ''}
    });

    const [backupUser, setBackup] = useState<IUser>({
        id: 0,
        username: '',
        email: '',
        challenges: 0,
        nonUserChallenged: 0,
        userChallenged: 0,
        challengesReceived: 0,
        challengesWon: 0,
        challengesMatched: 0,
        challengesLost: 0,
        pointsEarned: 0,
        pointsSpent: 0,
        balance: 0,
        lastAnswer: new Date(),
        club: [],
        clubArray: [], 
        profilePic: '',
        questionAmount: 0, 
        answerAmount: 0, 
        anagrafica: {id: 0, username: '', email: ''}
    });

    const [fields, setFields] = useState<FieldsProps[]>([
        {
            id: 'profilePic',
            required: false,
            formType: FormType.IMAGEPICKER,
            dataType: DataType.LINK,
            label: 'Profile Picture',
            link: '',
            show: true,
            error: false,
            touched: false,
            position: 0,
            customClass: "user-detail__avatar",
            omitRecap: true
        },
        {
            id:'username',
            required: true,
            formType: FormType.INPUT,
            dataType: DataType.STRING,
            label: 'Username',
            helperText: 'Please enter an username',
            show: true,
            error: false,
            touched: false,
            position: 1,
            customClass: "user-detail__field"
        },
        {
            id: 'email',
            required: true,
            formType: FormType.INPUT,
            dataType: DataType.STRING,
            label: 'E-mail',
            helperText: "Please enter a valid email address",
            show: true,
            error: false,
            touched: false,
            position: 2,
            customClass: "user-detail__field"
        },
        {
            id:'club',
            required: false,
            formType: FormType.AUTOCOMPLETE,
            dataType: DataType.OBJECT,
            label: 'Clubs',
            show: true,
            error: false,
            touched: false,
            position: 3,
            customClass: "user-detail__field",
            selectEntries: []
        }
        
    ]);
    const challenges = [
        {
            id: 'challenges',
            label: 'Amount of challenges',
            icon: <StarIcon/>
        },
        {
            id: 'nonUserChallenged',
            label: "Amount of non-users challenged",
            icon: <AccountBoxIcon/>
        },
        {
            id: 'userChallenged',
            label: "Amount of users challenged",
            icon: <AccountBoxIcon/>

        },
        {
            id: 'challengesReceived',
            label: "Amount of received challenges",
            icon: <StarIcon/>
        },
        {
            id: 'challengesWon',
            label: "Amount of won challenges",
            icon: <ThumbUpIcon/>
        },
        {
            id: 'challengesMatched',
            label: "Amount of matched challenges",
            icon: <StarIcon/>
        },
        {
            id: 'challengesLost',
            label: 'Amount of lost challenges',
            icon: <ThumbDownIcon/>
        }
    ];

    const stats = [
        {
            id: 'pointsEarned',
            label: 'Points earned so far',
            icon: <GetAppIcon/>
        },
        {
            id: 'pointsSpent',
            label: 'Points spent so far',
            icon: <PublishIcon/>
        },
        {
            id: 'balance',
            label: 'Current balance',
            icon: <StarsIcon/>
        },
        {
            id: 'lastAnswer',
            label: 'Date of last-posted answer',
            icon: <EventIcon/>
        },
        {
            id: 'questionAmount',
            label: 'Premium questions he was chosen for',
            icon: <QuestionAnswerIcon/>
        },
        {
            id: 'answerAmount',
            label: 'Total amount of answers',
            icon: <RateReviewIcon/>
        }
    ];

    //INIT GET
    useEffect(() => {
        if(isLoading) {
            UserService.getOne(params.id)
                .then(res => {
                    setCurrent({...res})
                    setBackup({...res});
                })
            ClubService.getClub()
                .then(res => {
                    setClubs(res)
                    var newFields = [...fields];
                    newFields[3].selectEntries = res;
                    setFields([...newFields])
                })
                .then(() => setIsLoading(false));
            UploadService.getPics("consumer")
                .then(res => {
                    setPics(res)
                })
        };
        setInitCheck(true);
    }, [isLoading]);

    //SET TOUCHED, GET PROFILE PIC
    useEffect(() => {
        if(initCheck){
            fields.map(entry => {
                var newFields = [...fields];
                if(currentUser[entry.id]){
                    newFields[entry.position].touched = true;
                }else{
                    newFields[entry.position].touched = false;
                };
                if(clubs) newFields[3].selectEntries = clubs;
                if(entry.id === 'profilePic' && currentUser.profilePic){
                    newFields[entry.position].link = currentUser.profilePic;
                }
                setFields([
                    ...newFields
                ])    
            });
            setInitCheck(false);
        }
    }, [initCheck]);
    
    //CHANGE HANDLER
    const handleChange = (e: any, newValue?:{id:number, value: string}[], id?: string) => {
        const value = e.target.value;
        const key = e.target.id;
        if(id=== 'club' && newValue){
            setCurrent({
                ...currentUser,
                club: newValue
            })
        } else if(e.target.id === 'username' || e.target.id === 'email'){
            setCurrent({
                ...currentUser,
                [key]: value,
                anagrafica: {...currentUser.anagrafica, [key]: value}
            })
        } else {
            setCurrent({
                ...currentUser,
                [key]: value
            });
        }
    };

    //SAVE
    const onSave = (e:any) => {
        UserService.update(currentUser.id, currentUser)
            .then(res => {
                enqueueSnackbar('User successfully updated!',
                Utils.getSnackbarOptions('success'));   
            })
            .catch(err => {
                console.log('incoming error', err);
                Utils.responseError(enqueueSnackbar)
            });
        AnagraficaService.update(currentUser.anagrafica.id, currentUser.anagrafica)
            .then(res => res)
            .catch(err => console.log('Anagrafica service error ', err))
        setBackup(currentUser);
        setShowDialog(false);
    };

    //UNDO CHANGES
    const onReset = () => {
        const newFields = fields.map(entry => {
            return{
                ...entry,
                error: false,
                touched: (entry.required)
            }
        })
        setFields([...newFields])
        setCurrent(backupUser);
        setInitCheck(true);
    };

    //VALIDATION ON INPUT
    const handleValidation = (element: FieldsProps) => {
        const newFields = [...fields];
        const value = currentUser[element.id];
        const userInput: boolean = value ? (value === '' || value.length === 0) : true;
        const newInput = {...element, error: (element.required && userInput), touched: !userInput};
        newFields[element.position] = newInput;
        setFields(newFields);
    };

    //CHECK MISSING FIELDS && ENABLE SUBMIT
    useEffect(()=>{
        if(fields){
            const missingFields = !fields.filter(key => key.required).every(key => key.touched && !key.error);
            setDisableButton(missingFields);
            }
    }, [fields]);

    //TOGGLE DIALOG
    const toggleDialog = (e:any) => {
        const newValue = !showDialog;
        setShowDialog(newValue);
    };

    //TABS
    const handleTabs = (e: any, value: string) => {
        setTabValue(value);
    };

    const handleImagePicker = () => {
        const newValue = !showImagePicker;
        setShowImagePicker(newValue)
    };

    const selectImage = (entry:any) => {
        var newFields = [...fields];
        newFields[0].link = entry.url;
        setFields([...newFields]);
        setCurrent({
            ...currentUser,
            profilePic: entry.id
        })
        handleImagePicker();
    };

    const imagePicker = (pics: any[]) => {
        return(
            <Dialog
                open={showImagePicker}
                maxWidth="sm"
                fullWidth={true}
                key="imagePicker-dialog"
            >
                <DialogTitle key="imagepicker-dialog-title">
                    Choose a Picture
                </DialogTitle>
                <DialogContent key="imagepicker-dialog-content">
                    <Grid container spacing={1}>
                        {pics.map( entry => {
                            return (
                                <Grid item>
                                    <Avatar 
                                        key={`${entry.id}-avatar-key`} 
                                        className="user-detail__imagepicker" 
                                        src={`${entry.url}`} onClick={(e: any) => selectImage && selectImage(entry)} 
                                    />
                                </Grid>
                            )
                        })}
                    </Grid>
                </DialogContent>

                <DialogActions key="imagepicker-actions">
                    <Button
                        type="button"
                        key="imagepicker-backkey"
                        variant="contained"
                        size="medium"
                        startIcon={<ArrowBack />}
                        onClick={handleImagePicker}
                    >
                        Back
                </Button>
                </DialogActions>
            </Dialog>
        )
}

    return (
        <React.Fragment>
            {imagePicker(pics)}
           <TabContext value={tabValue} key="mui-tab-navigation">
               <Paper className="user-detail__navbar">
                    <TabList 
                        key="user-detail-tablist" 
                        centered={true} 
                        indicatorColor="primary" 
                        onChange={handleTabs} 
                        variant="standard"
                    >
                        <Tab label="User" value="1" key="first-tab"></Tab>
                        {/* <Tab label="Challenges" value="2" key="second-tab"></Tab> */}
                        <Tab label="Statistics" value="3" key="third-tab"></Tab>
                    </TabList>
               </Paper>

            <TabPanel
                value="1"
                key="user-form-tab"
            >
                <CustomForm
                    customClassName="user-detail"
                    formInterface={AvailableInterfaces.USER}
                    data={currentUser}
                    fields={fields}
                    disableButton={disableButton}
                    toggleDialog={toggleDialog}
                    showDialog={showDialog}
                    showImagePicker={showImagePicker}
                    title="Edit User"
                    onSave={onSave}
                    onReset={onReset}
                    handleChange={handleChange}
                    handleValidation={handleValidation}
                    handleImagePicker={handleImagePicker}
                    selectImage={selectImage}
                />
            </TabPanel>
            {/* <TabPanel
                value="2"
                key="challenges-tab"
            >
                <Paper>
                    {challenges.map(entry => {
                        return(
                            <List 
                                className="user-detail__list" 
                                key={`${entry.id}-detail-list`}
                            >
                                <ListItem key={`${entry.id}-challenges-item`}>
                                    <ListItemIcon>{entry.icon}</ListItemIcon>
                                    <ListItemText
                                        primary={currentUser[entry.id]}
                                        secondary={entry.label}
                                    />
                                </ListItem>
                            </List>
                        )
                    })}
                </Paper>
            </TabPanel> */}
            <TabPanel
                value="3"
                key="statistics-tab"
            >
                <Paper>
                    {stats.map(entry => {
                        return(
                            <List 
                                className="user-detail__list" 
                                key={`${entry.id}-detail-list`}
                            >
                                <ListItem key={`${entry.id}-stats-item`}>
                                    <ListItemIcon>{entry.icon}</ListItemIcon>
                                    <ListItemText
                                        primary={entry.id !== 'lastAnswer' ? currentUser[entry.id] : currentUser.lastAnswer?.toDateString()}
                                        secondary={entry.label}
                                    />
                                </ListItem>
                            </List> 
                        )
                    })}
                </Paper>
            </TabPanel>
           </TabContext>
        </React.Fragment>
    )
}

export default UserDetail;