import React, { useState, useEffect } from 'react';
import './Journalist-detail.css';
import {useHistory, useLocation} from 'react-router-dom';
import { useParams } from 'react-router-dom';
import JournalistService from "../../../services/Journalist-Service";
import AnagraficaService from "../../../services/Anagrafica-Service";
import IJournalist from "../../../models/journalist-model";
import JournalistQuestions from './journalist-questions';
import UploadService from '../../../services/Upload-Service';
import CustomForm, { FormType, DataType, FieldsProps } from '../../dashboard/subs/question-form/customForm';
import { useSnackbar } from 'notistack';
import { Utils } from '../../../Utils/utils';
import Tab from '@material-ui/core/Tab';
import TabContext from '@material-ui/lab/TabContext';
import TabList from '@material-ui/lab/TabList';
import TabPanel from '@material-ui/lab/TabPanel';
import Paper from '@material-ui/core/Paper';
import { List, ListItem, ListItemIcon, ListItemText } from '@material-ui/core';
import QuestionAnswerIcon from '@material-ui/icons/QuestionAnswer';
import DateRangeIcon from '@material-ui/icons/DateRange';
import GroupIcon from '@material-ui/icons/Group';
import RateReviewIcon from '@material-ui/icons/RateReview';
import { AvailableInterfaces } from '../../../models/available-interfaces';
import ThumbUpIcon from '@material-ui/icons/ThumbUp';

function JournalistDetail() {

    const [isLoading, setIsLoading] = useState(true);
    const [initCheck, setInitCheck] = useState(false);
    const [showDialog, setShowDialog] = useState(false);
    const { enqueueSnackbar } = useSnackbar();
    const [disableButton, setDisableButton] = useState(true);
    const [tabValue, setTabValue] = useState('1');
    const [file, setFile] = useState<File>();
    const [uploading, setUploading] = useState(false);
    const location = useLocation();
    const history = useHistory();
    let params: any = useParams();
    const [currentJournalist, setCurrent] = useState<IJournalist> ({
        id: 0,
        phone: '',
        submitted: 0,
        userEngaged: 0,
        userAnswered: 0,
        lastQuestion: new Date(),
        club: {id: 0, name: '', journalist: 0},
        journal: '',
        link: '',
        description: '',
        profilePic: '',
        userId: 0,
        username: '',
        email: '',
        questions: 0,
        user:{id:0, username: '',email: ''},
        approvedQuestions: 0
    });
    const [backupJournalist, setBackup] = useState<IJournalist> ({
        id: 0,
        phone: '',
        submitted: 0,
        userEngaged: 0,
        userAnswered: 0,
        lastQuestion: new Date(),
        club: {id: 0, name: '', journalist: 0},
        journal: '',
        link: '',
        description: '',
        profilePic: '',
        userId: 0,
        username: '',
        email: '',
        questions: 0,
        user:{id:0, username: '',email: ''},
        approvedQuestions: 0
    });
    const [ fields, setFields ] = useState<FieldsProps[]>([
        {
            required: false,
            formType: FormType.AVATAR,
            id:'profilePic',
            dataType: DataType.LINK,
            label: 'Profile Picture',
            error: false,
            touched: false,
            show: true,
            position: 0,
            customClass: 'journalist-detail__avatar',
            link: '',
            omitRecap: true
        },
        {
            required: true,
            formType: FormType.INPUT,
            id: 'username',
            dataType: DataType.STRING,
            label: 'Username',
            helperText: 'Please provide an username',
            error: false,
            position: 1,
            touched: false,
            show: true,
            customClass: 'journalist-detail__field',
        },
        {
            required: true,
            formType: FormType.INPUT,
            id: 'email',
            dataType: DataType.STRING,
            label: 'E-mail',
            helperText: 'Please enter a valid E-mail address',
            error: false,
            position: 2,
            touched: false,
            show: true,
            customClass: 'journalist-detail__field',
        },
        {
            required: true,
            formType: FormType.INPUT,
            id: 'phone',
            dataType: DataType.STRING,
            label: 'Phone Number',
            helperText: 'Please enter a phone number',
            error: false,
            position: 3,
            touched: false,
            show: true,
            customClass: 'journalist-detail__field',
        },
        {
            required: false,
            formType: FormType.INPUT,
            id: 'journal',
            dataType: DataType.STRING,
            label: 'Journal',
            error: false,
            position: 4,
            touched: false,
            show: true,
            customClass: 'journalist-detail__field',
        },
        {
            required: false,
            formType: FormType.INPUT,
            id: 'link',
            dataType: DataType.STRING,
            label: 'Website',
            error: false,
            position: 5,
            touched: false,
            show: true,
            customClass: 'journalist-detail__field',
        },
        {
            required: false,
            formType: FormType.INPUT,
            id: 'description',
            dataType: DataType.STRING,
            label: 'Description',
            error: false,
            position: 6,
            touched: false,
            show: true,
            customClass: 'journalist-detail__wide-field',
            multiline: true,
            rows: 4
        },
        {
            required: false,
            formType: FormType.CHECKBOX,
            id: 'club',
            dataType: DataType.STRING,
            label: 'Club',
            error: false,
            position: 7,
            touched: false,
            show: true,
            customClass: 'journalist-detail__wide-field',
            disabled: true,
            omitRecap: true
        },
    ]);
    const [stats, setStats] = useState<any[]>([
        {
            id: 'submitted',
            label: 'Amount of submitted questions',
            icon: <RateReviewIcon/>
        },
        {
            id: 'lastQuestion',
            label: 'Date of last submitted question',
            icon: <DateRangeIcon/>
        },
        {
            id: 'userAnswered',
            label: 'Average number of received answers',
            icon: <QuestionAnswerIcon/>
        },
        {
            id: 'userEngaged',
            label: 'Average number of users engaged',
            icon: <GroupIcon/>
        },
        {
            id: 'approvedQuestions',
            label: 'Number of approved questions',
            icon: <ThumbUpIcon />
        }
    ])
    //GET REQUEST
    useEffect(() => {
        if(isLoading) {
            JournalistService.getOne(params.id)
                .then(res => {
                    setCurrent(res);
                    setBackup({...res});
                })
                .then(() => setIsLoading(false))
        };
        setInitCheck(true);
    }, [isLoading]);
    //SET FIELDS TOUCHED, UPDATE CLUB LABEL, GET PROFILE PIC
    useEffect(() => {
        if(initCheck) {
            fields.map(entry => {
                var newFields = [...fields];
                if(currentJournalist[entry.id]){
                    newFields[entry.position].touched = true;
                }else{
                    newFields[entry.position].touched = false;
                };
                if(entry.id === 'club' && currentJournalist.club?.name){
                    newFields[entry.position].label = `Club : ${currentJournalist.club?.name}`
                };
                if(entry.id === 'profilePic' && currentJournalist.profilePic){
                    newFields[entry.position].link = currentJournalist.profilePic;
                };
                setFields([
                    ...newFields
                ]);
            })
            setInitCheck(false);
        }
    }, [initCheck]);
    //CHANGE HANDLER
    const handleChange = (e:any) => {
        if(e.target.files){
            //SET FILE && AVATAR PREVIEW
            const file: File = (e.target.files as FileList)[0]
            setFile(file);
            const objectUrl = URL.createObjectURL(file);
            var newFields = [...fields];
            newFields[0].link = objectUrl;
        } else {
            const newValue = e.target.value;
            const key = e.target.id;
            if(key === 'username' || key === 'email'){
                setCurrent({
                    ...currentJournalist,
                    [key]: newValue,
                    user: {...currentJournalist.user, [key]: newValue}
                });
            }else{
                setCurrent({
                    ...currentJournalist,
                    [key]: newValue
                })
            }
        }
    };
    //UPLOAD PROFILE PIC
    const onUpload = async (localfile: File) : Promise<number> => {   
        return await UploadService.add(localfile)
            .then(res =>  res[0].id)
            .catch(err => console.log('incoming err', err)) 
    }
    //PUT REQUEST
    const onSave = async() => { 
        if(file){     
           var id: number = await onUpload(file);
           JournalistService.update(currentJournalist.id, {...currentJournalist, picId: id})
                .then(res => {
                    enqueueSnackbar('Journalist Updated!', 
                    Utils.getSnackbarOptions('success'));
                }) 
                .catch(err => {
                    console.log('incoming error ', err);
                    Utils.responseError(enqueueSnackbar)
                });    
        } else {
            JournalistService.update(currentJournalist.id, currentJournalist)
            .then(res => {
                enqueueSnackbar('Journalist Updated!',
                    Utils.getSnackbarOptions('success'))
            }) 
            .catch(err => {
                console.log('incoming error ', err);
                Utils.responseError(enqueueSnackbar)
            });   
        }
       AnagraficaService.update(currentJournalist.user.id, currentJournalist.user)
            .then(res => console.log('anagrafica res', res))
            .catch(err => console.log('anagrafica error ', err))
        setFile(undefined);
        setShowDialog(false);
    };
    //RESET FORM
    const onReset = () => {
        const newFields = fields.map(entry => {
            return {
                ...entry,
                error: false,
                touched: (entry.id === 'phone')
            }
        })
        setFields([...newFields]);
        setCurrent(backupJournalist);
    };
    //VALIDATE ON INPUT
    const handleValidation = (element: FieldsProps) => {
        const newFields = [...fields]
        const value = currentJournalist[element.id];
        const userInput: boolean = value ? (value === '' || value.length === 0) : true;
        const newInput = { ...element, error: (element.required && userInput), touched: !userInput };
        newFields[element.position] = newInput;
        setFields(newFields)
    };
    //CHECK MISSING FIELDS && ENABLE SUBMIT BUTTON
    useEffect(()=>{
        if(fields){
            const missingFields = !fields.filter(key => key.required).every(key => key.touched && !key.error);
            setDisableButton(missingFields);
            }
    }, [fields]);
    //TOGGLE DIALOG
    const toggleDialog = (e:any) => {
        const newValue = !showDialog;
        setShowDialog(newValue);
    };
    //TABS NAVIGATION
    const handleTabs = (e: any, value: string) => {
        setTabValue(value);
    };
    return(
        <React.Fragment>
            <TabContext value={tabValue} key="mui-tab-navigations">
                <Paper className="journalist-detail__navbar">
                    <TabList centered={true} indicatorColor="primary" onChange={handleTabs} variant="standard">
                        <Tab label="Journalist" value="1" key="first-tab"></Tab>
                        <Tab label="Questions" value="2" key="second-tab"></Tab>
                        <Tab label="Statistics" value="3" key="third-tad"></Tab>
                    </TabList>
                </Paper>

            <TabPanel
                value="1"
                key="journalist-form-tab"
            >
                <CustomForm
                    customClassName="journalist-detail"
                    formInterface={AvailableInterfaces.JOURNALIST}
                    data={currentJournalist}
                    fields={fields}
                    disableButton={disableButton}
                    toggleDialog={toggleDialog}
                    showDialog={showDialog}
                    title="Edit Journalist"
                    onSave={onSave}
                    onReset={onReset}
                    handleChange={handleChange}
                    handleValidation={handleValidation}
                />
            </TabPanel>
            <TabPanel
                value="2"
                key="journalist-question-tab"
            >
                <JournalistQuestions
                    key={`${currentJournalist.id}-questions-key`}
                    id={currentJournalist.id.toString()}
                />
            </TabPanel>
            <TabPanel
                value="3"
                key="journalist-statistics-panel"
            >
                <Paper>
                    {stats.map(entry => { return(
                         <List className="journalist-detail__list">
                         <ListItem>
                             <ListItemIcon>{entry.icon}</ListItemIcon>
                             <ListItemText
                                primary={
                                     entry.id === 'lastQuestion' ?
                                        currentJournalist.lastQuestion?.toDateString() || 'n.d.' : 
                                            (currentJournalist[entry.id] !== null ? currentJournalist[entry.id] : 'n.d.')
                                }
                                 secondary={entry.label}
                             />
                         </ListItem>
                     </List>
                    )})}
                </Paper>
            </TabPanel>
            </TabContext>  
        </React.Fragment>
    )

}

export default JournalistDetail