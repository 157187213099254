import React, {useState, useEffect} from 'react';
import ISorting, {SortOrder} from "../../../models/sorting-model";
import TransactionService from "../../../services/Transaction-Service";
import MaterialTable from 'material-table';
import {Column} from 'material-table';

export interface PremiumTransactionProps {
    id:string
}

const PremiumTransactions = (props: PremiumTransactionProps) => {
    const {id = ''} = props;
    const [page,setPage] = useState(0);
    const [from,setFrom] = useState(0);
    const [pageSize, setPageSize] = useState(10);
    const [items, setItems] = useState(100);
    const [transactions, setTransactions] = useState<[]>([]);
    const [reload, setReload] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [order, setOrder] = useState<ISorting | undefined>({ orderBy: 'invoice_number', orderDirection: SortOrder.ASC})

    const columns: Column<object>[] = [
        {
            title: 'Question', 
            field: 'question.title',
            defaultSort: order?.orderBy === 'question.title' ? order.orderDirection : undefined,
            customSort: () => 0
        },
        {
            title: 'Payment', 
            field: 'Payment', 
            defaultSort: order?.orderBy === 'Payment' ? order.orderDirection : undefined,
            customSort: () => 0
        },
        {
            title: 'Invoice No', 
            field: 'invoice_number',
            defaultSort: order?.orderBy === 'invoice_number' ? order.orderDirection : undefined,
            customSort: () => 0
        },
        {
            title: 'Invoice Date',
            field: 'invoice_date',
            type: 'date',
            defaultSort: order?.orderBy === 'invoice_date' ? order.orderDirection : undefined,
            customSort: () => 0
        } 
    ];

    useEffect(() => {
        if(id || reload){
            setIsLoading(true);
            TransactionService.getAll(pageSize,from, order, {"company.id": id.toString()})
                .then(res => {
                    setItems(res.items)
                    setTransactions(res.payload)
                    }
                )
                .finally(()=>{
                    setIsLoading(false);
                })
            setReload(false);
        }
    },[id, reload, pageSize, from, order]);

    const getField = (orderBy: number): string => {
        switch(columns[orderBy]?.field) {
            case 'question' :
                return 'question'
            case 'Payment' :
                return 'Payment'
            case 'invoice_number' :
                return 'invoice_number'
            case 'invoice_date' :
                return 'invoice_date'
            default :
                return columns[orderBy]?.field || ''
        }
    }
    const onOrderChange = (orderBy: number, orderDirection: "asc" | "desc") => {
        const direction = orderDirection === "asc" ? SortOrder.ASC : SortOrder.DESC
        if(orderBy !== -1)
            setOrder({orderBy:getField(orderBy), orderDirection: direction})
        else 
            setOrder(undefined)
        setPage(0);
        setFrom(0);
        setReload(true);

    };
    const changePage = (e:any) => {
        setPage(e);
        setFrom(pageSize * page);
        setReload(true);
    };
    const changeRowsPerPage = (e:any) => {
        setPageSize(e);
        setPage(0);
        setFrom(0);
        setReload(true);
    };
    return (
        <MaterialTable
            title='Company Transactions'
            columns={columns}
            data={transactions}
            isLoading={isLoading}
            options={{
                search: false,
                pageSize: pageSize
            }}
            totalCount={items}
            page={page}
            onChangeRowsPerPage={(e:any) => changeRowsPerPage(e)}
            onChangePage={(e:any) => changePage(e)}
            onOrderChange={onOrderChange}
        />
    ) 
}
export default PremiumTransactions