import { Mapper } from "./Mapper"

export default interface IClub {
    id: number,
    value: string
}

export interface IServerClub {
    id: number,
    name: string
}

export abstract class IClubMapper implements Mapper<IClub, IServerClub> {

    static map(o: IServerClub): IClub {
        const object = {} as IClub

        object.id = o.id
        object.value = o.name

        return object
    }

    static mapReverse(o: IClub): IServerClub {
        const object = {} as IServerClub

        object.id = o.id
        object.name = o.value

        return object
    }

    static mapArray(models: IServerClub[]): IClub[] {
        return models.map(m => IClubMapper.map(m))
    }

    static mapReverseArray(models: IClub[]): IServerClub[] {
        return models.map(m => IClubMapper.mapReverse(m))
    }

}
