import IClub, { IClubMapper } from "../models/club-model";
import { SESSION_STORAGE_JWT_TOKEN } from "../Utils/constants";
import SessionService from "./Session-Service";
const BASE_URL = `${process.env.REACT_APP_BASE_URL}/club`;

class ClubService {

    async getClub(): Promise<IClub[]> {
        const requestOptions: RequestInit = {
            method: 'GET',
            headers: { 
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${SessionService.getItem(SESSION_STORAGE_JWT_TOKEN)}`
            },
        }

        const response = await fetch(BASE_URL, requestOptions)
            .then(res => res.json())

        return IClubMapper.mapArray(response.payload)
    }

}

export default new ClubService
