import IQuestion, { IQuestionMapper, IQuestionFilters } from "../models/question-model";
import ISorting from "../models/sorting-model";
import { SESSION_STORAGE_JWT_TOKEN } from "../Utils/constants";
import SessionService from "./Session-Service";
const BASE_URL = `${process.env.REACT_APP_BASE_URL}/question`;

class QuestionService {

    async getAll(limit?: number, from?: number, order?: ISorting, filters?: IQuestionFilters): Promise<any> {
        const requestOptions: RequestInit = {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${SessionService.getItem(SESSION_STORAGE_JWT_TOKEN)}`
            },
        }

        const urlParams = new URLSearchParams({
            ...limit && { _limit: limit.toString() },
            ...from && { _start: from.toString() },
            ...order && { _sort: `${order.orderBy}:${order.orderDirection}` },
            ...filters && { ...filters }
        })

        return await fetch(`${BASE_URL}?${urlParams}`, requestOptions)
            .then(res => res.json())
    }

    async getOne(id: number): Promise<IQuestion> {
        const requestOptions: RequestInit = {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${SessionService.getItem(SESSION_STORAGE_JWT_TOKEN)}`
            },
        }
        return await fetch(`${BASE_URL}/${id}`, requestOptions)
            .then(res => res.json())
            .then(res => res?.error ? res : IQuestionMapper.map(res))
    }

    async update(id: number, question: Partial<IQuestion>, isPremium?: boolean) {
        const requestOptions: RequestInit = {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${SessionService.getItem(SESSION_STORAGE_JWT_TOKEN)}`
            },
            body: JSON.stringify(IQuestionMapper.mapForUpdate(question))
        }

        return await fetch(`${BASE_URL}${isPremium ? '/premium' : ''}/${id}`, requestOptions)
            .then(res => res.json())
    }

    async add(question: IQuestion, isPremium?: boolean) {
        const requestOptions: RequestInit = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${SessionService.getItem(SESSION_STORAGE_JWT_TOKEN)}`
            },
            body: JSON.stringify(IQuestionMapper.mapRequest(question))
        }
        return await fetch(`${BASE_URL}${isPremium ? '/premium' : ''}`, requestOptions)
            .then(res => res.json())
    }

    async delete(questionId: number) {
        const requestOptions: RequestInit = {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${SessionService.getItem(SESSION_STORAGE_JWT_TOKEN)}`
            }
        }
        return await fetch(`${BASE_URL}/${questionId}`, requestOptions)
            .then(res => res.json())
    }

    async sendCorrectAnswer(answer: string, id: number) {
        const requestOptions: RequestInit = {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${SessionService.getItem(SESSION_STORAGE_JWT_TOKEN)}`
            },
            body: JSON.stringify({ 'correct_answer': answer})
        }
        return await fetch(`${BASE_URL}/correct/${id}`, requestOptions)
            .then(res => res.json())
    }
}

export default new QuestionService
