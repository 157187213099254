import { Mapper } from "./Mapper";
import moment from 'moment';
import IAnagrafica from './anagrafica-model';

export default interface IPremium {
    [key: string]: any,
    id: number,
    piva: string,
    office?: string,
    phone: number, 
    questionAmount?: number,
    lastQuestion?: Date,
    userEngaged?: number,
    userAnswered?: number,
    paid?: number,
    name?: string,
    reference?: string
    username?: string,
    email?: string
    user: IAnagrafica,
    approvedQuestions?: number
}

export interface IServerPremium {
    [key: string] : any,
    id: number,
    piva: string,
    registered_office: string,
    number_phone: string,
    question_number: number, 
    last_question: string,
    avg_user_engaged: number, 
    avg_user_answered: number, 
    total_paid: number, 
    transactions: number,
    name: string,
    reference: string,
    anagrafica: IAnagrafica,
    approved_questions: number
}

export abstract class IPremiumMapper implements Mapper<IPremium,IServerPremium> {

    static map(o: IServerPremium): IPremium {
        const object = {} as IPremium

        object.id = o.id
        object.piva = o.piva
        object.office = o.registered_office
        object.phone = parseInt(o.number_phone)
        object.questionAmount = o.question_number
        object.transactionAmount = o.transactions
        if (o.last_question)
            object.lastQuestion = new Date(o.last_question)
        object.userEngaged =  o.avg_user_engaged
        object.userAnswered = o.avg_user_answered
        object.paid = o.total_paid
        object.name = o.name
        object.reference = o.reference
        object.username = o.anagrafica.username
        object.email = o.anagrafica.email
        object.user = o.anagrafica
        object.approvedQuestions = o.approved_questions
        return object
    }

    static mapReverse(o: IPremium): IServerPremium {
        const object = {} as IServerPremium
       
        object.id = o.id
        object.piva = o.piva
        object. number_phone = o.phone.toString()
        if(o.office)
            object.registered_office = o.office
        if(o.name)
            object.name = o.name
        if(o.reference)
            object.reference = o.reference

        return object
    }

    static mapArray(models: IServerPremium[]): IPremium[] {
        return models.map(m => IPremiumMapper.map(m))
    }

    static mapReverseArray(models: IPremium[]): IServerPremium[] {
        return models.map(m => IPremiumMapper.mapReverse(m))
    }
}
