import React, { useState, useEffect } from 'react';
import ISorting, { SortOrder} from "../../../models/sorting-model";
import QuestionService from "../../../services/Question-Service";
import MaterialTable from 'material-table';
import {Column} from 'material-table';
import { useHistory, useLocation } from 'react-router';

export interface PremiumQuestionProps {
    id:string
}

const PremiumQuestions = (props: PremiumQuestionProps) => {
    const {id = ''} = props;
    const [page,setPage] = useState(0);
    const [from,setFrom] = useState(0);
    const [pageSize, setPageSize] = useState(10);
    const [items, setItems] = useState(100);
    const [questions, setQuestions] = useState<[]>([]);
    const [reload, setReload] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [order, setOrder] = useState<ISorting | undefined>({ orderBy: 'expiry', orderDirection: SortOrder.ASC})
    const history = useHistory();
    
    const columns: Column<object>[] = [
        {
            title: 'ID',
            field: 'id',
            defaultSort: order?.orderBy === 'id' ? order.orderDirection : undefined,
            customSort: () => 0
        },
        {
            title: 'Question', 
            field: 'title',
            defaultSort: order?.orderBy === 'title' ? order.orderDirection : undefined,
            customSort: () => 0
        },
        {
            title: 'Publication', 
            field: 'specs.publication', 
            type:"date",
            defaultSort: order?.orderBy === 'publication' ? order.orderDirection : undefined,
            customSort: () => 0
        },
        {
            title: 'Category',
            field: 'category',
            defaultSort: order?.orderBy === 'category' ? order.orderDirection : undefined,
            customSort: () => 0
        } 
    ];

    useEffect(() => {
       if(id || reload){
           setIsLoading(true);
           QuestionService.getAll(pageSize, from, order, { "company.id" : id.toString()})
            .then(res => {    
                    setItems(res.items)
                    setQuestions(res.payload)
                } 
            ).finally(()=>{
                setIsLoading(false)
            })
            setReload(false);
        }
    },[id, reload, pageSize, from, order]);
    const getField = (orderBy: number): string => {
        switch(columns[orderBy]?.field) {
            case 'specs.publication':
                return 'publication'
            case 'id' :
                return "id"
            case "category" :
                return "category"
            case "title" :
                return "title"
            default :
                return columns[orderBy]?.field || ''
        }
    }

    const onOrderChange = (orderBy: number, orderDirection: "asc" | "desc") => {
        const direction = orderDirection === "asc" ? SortOrder.ASC : SortOrder.DESC
        if(orderBy !== -1)
            setOrder({orderBy:getField(orderBy), orderDirection: direction})
        else 
            setOrder(undefined)
        setPage(0);
        setFrom(0);
        setReload(true);
    }

    const changePage = (e:any) => {
        setPage(e);
        setFrom(pageSize * page);
        setReload(true);
    };
    const changeRowsPerPage = (e:any) => {
        setPageSize(e);
        setPage(0);
        setFrom(0);
        setReload(true);
    }; 


    const onEdit = (rowData: any) => { history.push(`/dashboard/questions/${rowData.id}`) };

    const actions = [{
        icon: 'drive_file_rename_outline',
        tooltip: 'Edit',
        onClick: (e: any, rowData: any) => { onEdit(rowData) },
    }];

    return (
        <MaterialTable
            title="Company questions"
            columns={columns}
            data={questions}
            isLoading={isLoading}
            options={{
                search: false,
                pageSize: pageSize,
                actionsColumnIndex: -1
            }}
            actions={actions}
            totalCount={items}
            page={page}
            onChangeRowsPerPage={(e:any) => changeRowsPerPage(e)}
            onChangePage={(e:any) => changePage(e)} 
            onOrderChange={onOrderChange}
        />
    )
}

export default PremiumQuestions;