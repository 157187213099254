import IPremium, {IPremiumMapper} from '../models/premium-model';
import ISorting from '../models/sorting-model';
import { SESSION_STORAGE_JWT_TOKEN } from '../Utils/constants';
import SessionService from './Session-Service';
const BASE_URL = `${process.env.REACT_APP_BASE_URL}/company`;

class PremiumService {

    async getAll(limit?: number, from?: number, order?: ISorting, filters?: { [key: string]: string | Date | null | undefined }): Promise<any> {
        const requestOptions: RequestInit = {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${SessionService.getItem(SESSION_STORAGE_JWT_TOKEN)}`
            },
        }

        const urlParams = new URLSearchParams({
            ...limit && { _limit: limit.toString() },
            ...from && { _start: from.toString() },
            ...order && { _sort: `${order.orderBy}:${order.orderDirection}` },
            ...filters && { ...filters }
        })

        return await fetch(`${BASE_URL}?${urlParams}`, requestOptions)
            .then(res => res.json())
    }

    async getOne(id: number): Promise<IPremium>  {
        const requestOptions: RequestInit = {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${SessionService.getItem(SESSION_STORAGE_JWT_TOKEN)}`
            },
        }
        return await fetch(`${BASE_URL}/${id}`, requestOptions)
            .then(res => res.json())
            .then(res => IPremiumMapper.map(res.payload))
    }

    update(id: number, data: IPremium) { 
        const requestOptions: RequestInit = {
            method: 'PUT',
            headers: { 
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${SessionService.getItem(SESSION_STORAGE_JWT_TOKEN)}`
            },
            body: JSON.stringify(IPremiumMapper.mapReverse(data))
        }
        return fetch(`${BASE_URL}/${id}`, requestOptions)
            .then(res => res.json()) 
    }
    
    add(premium: IPremium) {
        const requestOptions: RequestInit = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${SessionService.getItem(SESSION_STORAGE_JWT_TOKEN)}`
            },
            body: JSON.stringify(premium)
        }
        return fetch(`${BASE_URL}`, requestOptions)
            .then(res => res.json())
    }

    async delete(premiumId: number){
        const requestOptions: RequestInit = {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${SessionService.getItem(SESSION_STORAGE_JWT_TOKEN)}`
            },
        }
        return fetch(`${process.env.REACT_APP_BASE_URL}/user/${premiumId}`, requestOptions)
            .then(res => res.json())
    }
}

export default new PremiumService