import React, { useState } from 'react';
import "./advancedSearch.css"
import { Button, Card, CardActions, CardContent, CardHeader, Collapse, createStyles, Grid, IconButton, makeStyles, Theme, Typography } from '@material-ui/core';
import Filter, { FilterProps, FilterType } from '../Filter/Filter';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import clsx from 'clsx';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        expand: {
            transform: 'rotate(0deg)',
            marginLeft: 'auto',
            transition: theme.transitions.create('transform', {
                duration: theme.transitions.duration.shortest,
            }),
        },
        expandOpen: {
            transform: 'rotate(180deg)',
        },
    }),
);

export interface AdvancedSearchProps {
    header: string
    filters: FilterProps[]
    onSubmit?: (filters: { [key: string]: string | Date | null | undefined }) => void
}

const AdvancedSearch = (props: AdvancedSearchProps) => {

    const {
        header,
        filters,
        onSubmit
    } = props;

    const classes = useStyles();
    const [expanded, setExpanded] = React.useState(false);
    const [activeFilters, setActiveFilters] = useState<{ [key: string]: string | Date | null | undefined  }>({})

    const handleFilterChange = (id: string, value: string | Date | null | undefined ) => {
        const obj = { ...activeFilters }
        if (value === '') delete obj[id]
        else obj[id] = value
        setActiveFilters(obj)
    }

    const handleClear = () => {
        setActiveFilters({})
        onSubmit?.({})
    }

    const handleExpandClick = () => {
        setExpanded(!expanded);
    };

    return <Card className="advanced-search" >
        <CardHeader
            title={
                <Grid container justify="space-around" alignItems="center">
                    <Typography variant="h6">{header}</Typography>
                    <IconButton
                        className={clsx(classes.expand, {
                            [classes.expandOpen]: expanded,
                        })}
                        onClick={handleExpandClick}
                    aria-expanded={expanded}
                    aria-label="show more"
                    >
                        <ExpandMoreIcon />
                    </IconButton>
                </Grid>}
        />
        <Collapse in={expanded} timeout="auto" unmountOnExit>
            <CardContent>
                <Grid 
                    justify="flex-start"
                    container
                >
                    {filters.map(f => 
                        <Filter 
                            {...f}
                            key={`filter-${f.id}`} 
                            onChange={handleFilterChange} 
                            value={activeFilters[f.id] || 
                                (f.type === FilterType.CALENDAR ? null : '')}
                        />
                    )}
                </Grid>
            </CardContent>
            <CardActions>
                <Button
                    type="button"
                    variant="contained"
                    size="medium"
                    onClick={handleClear}
                >
                    Reset
                </Button>
                <Button
                    type="button"
                    variant="contained"
                    color="primary"
                    size="medium"
                    onClick={() => onSubmit?.(activeFilters)}
                >
                    Apply
                </Button>
            </CardActions>
        </Collapse>
    </Card>
        
}

export default AdvancedSearch;
