import ICategory, { ICategoryMapper } from "../models/category-model";
import { SESSION_STORAGE_JWT_TOKEN } from "../Utils/constants";
import SessionService from "./Session-Service";
const BASE_URL = `${process.env.REACT_APP_BASE_URL}/categories`;

class CategoryService {

    async getAll(): Promise<ICategory[]> {
        const requestOptions: RequestInit = {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${SessionService.getItem(SESSION_STORAGE_JWT_TOKEN)}`
            },
        }

        return await fetch(`${BASE_URL}`, requestOptions)
            .then(res => res.json())
            .then(res => ICategoryMapper.mapArray(res))
    }

}

export default new CategoryService
