import React, { useEffect } from 'react';
import "./customForm.css"
import { Card, CardHeader, CardContent, CardActions, Button, FormHelperText, Slider, DialogContent, FormControlLabel, Checkbox, Typography, Box, CircularProgress } from '@material-ui/core';
import { Paper, TextField, InputLabel, MenuItem, FormGroup, Divider } from '@material-ui/core';
import { Dialog, DialogActions, DialogTitle, List, ListSubheader, ListItem, ListItemText } from '@material-ui/core';
import Avatar from '@material-ui/core/Avatar';
import Alert from '@material-ui/lab/Alert';
import ArrowBack from '@material-ui/icons/ArrowBack';
import SaveIcon from '@material-ui/icons/Save';
import UndoIcon from '@material-ui/icons/Undo';
import Done from '@material-ui/icons/Done';
import InfoIcon from '@material-ui/icons/Info';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Badge from '@material-ui/core/Badge';
import EditIcon from '@material-ui/icons/Edit';
import IconButton from '@material-ui/core/IconButton';
import classNames from 'classnames';
import locale from '../../../../languages/locale';
import moment from 'moment';

export enum FormType {
    INPUT = 'input',
    SELECT = 'select',
    SLIDER = 'slider',
    AUTOCOMPLETE = 'autocomplete',
    CHECKBOX = 'checkbox',
    FILEUPLOAD = 'fileupload',
    AVATAR = 'avatar',
    IMAGEPICKER = 'imagepicker',
    UPLOAD_PREVIEW = 'upload_preview'
}

export enum DataType {
    DATE = 'datetime-local',
    STRING = 'string',
    NUMBER = 'number',
    PASSWORD = 'password',
    BOOLEAN = 'boolean',
    FILE = 'file',
    LINK = 'link',
    OBJECT = 'object'
}

export interface FieldsProps {
    required: boolean,
    formType: FormType,
    id: string,
    dataType: DataType
    label: string,
    error: boolean,
    helperText?: string,
    selectEntries?: string[] | {id: number, value: string}[],
    position: number
    touched: boolean,
    show: boolean,
    customClass?: string,
    link?: string
    disabled?: boolean
    omitRecap?: boolean
    characterLimit?: number
    multiline?: boolean
    rows?: number
    max?: number
    characterCount?: string
}

export interface CustomFormProps {
    formInterface: string; //eg 'question', 'user' etc
    data: any;
    fields: FieldsProps[];
    title: string;
    disableButton: boolean;
    showDialog: boolean;
    alertElement?: boolean;
    alertMessage?: string;
    customClassName?: string;
    showImagePicker?: boolean;
    isLoading?: boolean;
    noActions?: boolean;
    summary?: JSX.Element;
    onReset: (imageInputRef?: any) => void;
    onSave: (e: any, imageInputRef?: any) => void;
    toggleDialog: (e: any) => void;
    handleChange: (e: any, newValue?: any[], elementKey?: string) => void;
    handleValidation: (e: any) => void;
    handleImagePicker?: (e:any) => void;
    selectImage?: (e:any) => void;
    sliderValue?: number;
    handleSliderCommitted?: (e:any, value: number | number[]) => void;
    handleSlider?: (e:any, value: number | number[]) => void;
}

//format dates for the form
const valueParser = (value: any, touched: boolean) => {
    switch (value instanceof Date) {
        case true: {
            if(touched) {
                return moment(value).format('yyyy-MM-DDTHH:mm').toString()
            } else {
                return ''
            }
        }
        default: {
            return value
        }
    }
}

//format data in recap
const formatter = (element: any, type: DataType, selectEntries?: any, omitRecap?: string) => {
    if (type === DataType.DATE) {
        return new Date(element).toLocaleString()
    } else if (type === DataType.OBJECT && selectEntries == undefined){
        return element.value
    } else if (Array.isArray(element)) {
        return element.map((e: any) => e.value).toString()
    } else if (selectEntries?.some((e: any) => e.id === element)) {
        const s = selectEntries.find((e: any) => e.id === element);
        return s.value
    } else if (type === DataType.BOOLEAN){
        return element ? locale.shared.yes : locale.shared.no
    } else {
        return element;
    }
}


const CustomForm = (props: CustomFormProps) => {

    const { 
        data, 
        fields = [],
        formInterface = '',
        alertElement= false,
        alertMessage='', 
        title = '',
        disableButton = true,
        showDialog = false,
        showImagePicker = false,
        customClassName,
        isLoading,
        noActions,
        summary,
        onSave,
        onReset,
        toggleDialog,
        handleChange,
        handleValidation,
        handleImagePicker,
        selectImage,
        handleSlider,
        sliderValue,
        handleSliderCommitted
    } = props;
    
    const imageInputRef = React.useRef();

    const mainForm = () => {
        return (
            fields.map((element: FieldsProps) => {
                if (element.show) {
                    switch (element.formType) {
                        case FormType.INPUT: return (
                            <React.Fragment key={`${element.id}-text-field`}>
                                <TextField
                                    className={element.customClass}
                                    id={element.id}
                                    name={element.id}
                                    label={element.label}
                                    required={element.required}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    variant="outlined"
                                    margin="normal"
                                    value={valueParser(data[element.id], element.touched)}
                                    type={element.dataType}
                                    error={element.error}
                                    onChange={(e: any) => handleChange(e)}
                                    onBlur={(e: any) => handleValidation(element)}
                                    helperText={element.error && element.helperText}
                                    disabled={element.disabled}
                                    multiline={element.multiline}
                                    rows={element.rows}
                                    inputProps={{
                                        maxLength: element.characterLimit
                                    }}
                                />
                                {element.characterCount && 
                                    <FormHelperText
                                        key={`${element.id}-character-counter`}
                                        className="custom-form__character-counter "
                                    >
                                        Remaining characters: {element.characterCount} / {element.characterLimit}
                                    </FormHelperText>
                                }
                            </React.Fragment>
                        )
                        case FormType.SELECT: return (
                            <TextField
                                className={element.customClass}
                                id={element.id}
                                name={element.id}
                                key={`${element.id}-select`}
                                label={element.label}
                                required={element.required}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                variant="outlined"
                                margin="normal"
                                value={element.selectEntries?.length ? data[element.id]?.id || data[element.id] || '' : ''}
                                error={element.error}
                                onChange={(e: any) => handleChange(e)}
                                onBlur={(e: any) => handleValidation(element)}
                                helperText={element.error && element.helperText}
                                disabled={element.disabled}
                                select
                            >
                                {element.selectEntries ? element.selectEntries.map((entry: string | {id: number, value: string}) => {
                                    return (
                                        <MenuItem 
                                            className='custom-form__menu-item'
                                            value={typeof entry === 'string' ? entry : entry.id} 
                                            key={`${typeof entry === 'string' ? entry : entry.id}-menuitem`}
                                        > 
                                                {typeof entry === 'string' ? entry : entry.value} 
                                        </MenuItem>
                                    )
                                }) : <div key={`${element.id}-empty-div-key`}></div>}
                            </TextField>
                        )
                        case FormType.AUTOCOMPLETE: return (
                            <Autocomplete
                                className={element.customClass}
                                id={element.id}
                                key={`${element.id}-autocomplete`}
                                value={valueParser(data[element.id], element.touched)}
                                options={element.selectEntries || []}
                                getOptionLabel={(option) => option.value}
                                getOptionSelected={(option, value) => option.id === value.id}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        id={`${element.id}-text-field-autocomplete`}
                                        key={`${element.id}-text-field-autocomplete`}
                                        name={element.label}
                                        label={element.label}
                                        type={element.dataType}
                                        variant="outlined"
                                        InputLabelProps={{ "shrink": true }}
                                        margin="normal"
                                        multiline={true}
                                        error={element.error}
                                        helperText={element.error && element.helperText}
                                    />
                                )}
                                onChange={(e, newValue) => handleChange(e, newValue, element.id)}
                                onBlur={(e: any) => handleValidation(element)}
                                disabled={element.disabled}
                                multiple
                                selectOnFocus
                                clearOnBlur
                                handleHomeEndKeys
                            />

                        )
                        case FormType.CHECKBOX: return (
                            <FormControlLabel
                                className={element.customClass}
                                key={`${element.id}-checkbox-label`}
                                control={
                                    <Checkbox 
                                        key={`${element.id}-checkbox`}
                                        name={element.id}
                                        checked={valueParser(data[element.id], element.touched)}
                                        onChange={(e: any) => handleChange(e)}
                                        onBlur={(e: any) => handleValidation(element)}
                                        color="primary"
                                        required={element.required}
                                        disabled={element.disabled}
                                    />
                                }
                                label={element.label}
                                labelPlacement="end"
                            />
                        )
                        case FormType.FILEUPLOAD: return (
                            <div className="custom-form__file-upload" key={`${element.id}-file-upload-outer-div`}>
                                <TextField
                                    className={element.customClass}
                                    id={element.id}
                                    name={element.id}
                                    key={`${element.id}-file-upload`}
                                    label={element.label}
                                    required={element.required}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    inputProps={{
                                        className: 'custom-form__upload-input',
                                        accept: 'image/*',
                                        onChange: (e: any) => handleChange(e),
                                        onBlur: (e: any) => handleValidation(element),
                                        ref: imageInputRef,
                                        title: ''
                                    }}
                                    variant="outlined"
                                    margin="normal"
                                    type={element.dataType}
                                    error={element.error}
                                    helperText={element.error && element.helperText}
                                    disabled={element.disabled}
                                />
                                <Typography className="custom-form__filename" key={`${element.id}-file-upload-typography`}>
                                    {data[element.id]}
                                </Typography>
                            </div>
                        )
                        case FormType.AVATAR:
                        case FormType.IMAGEPICKER:
                            return (
                            <div className="custom-form__avatar-container" key={`${element.id}-image-selector`}> 
                                <Badge
                                overlap="circle"
                                anchorOrigin={{
                                    vertical: 'bottom',
                                    horizontal: 'right',
                                  }}
                                badgeContent= {(
                                        <React.Fragment key={`${element.id}-fragment-parent-key`}>
                                            {element.formType === FormType.AVATAR && <input 
                                                key="avatar-hidden-input" 
                                                accept="image/*" 
                                                style={{display: "none"}} 
                                                id={element.id} type="file" 
                                                onChange={(e:any) => handleChange(e)}
                                            />}
                                            {element.formType === FormType.IMAGEPICKER && <button 
                                                key="imagepicker-hidden-input" 
                                                style={{display: "none"}} 
                                                id={element.id} 
                                                type="button" 
                                                onClick={handleImagePicker}/>}
                                            <label htmlFor={element.id}>
                                            <IconButton 
                                                component="span" 
                                                size="small" 
                                                className="custom-form__iconbutton" 
                                            >   
                                                    <EditIcon/>              
                                            </IconButton>
                                            </label>
                                        </React.Fragment>
                                )}
                            >
                                <Avatar src={element.link} className={element.customClass} />
                            </Badge>
                            </div>
                        )
                        case FormType.UPLOAD_PREVIEW: return (
                            element.link && <img src={element.link} className={element.customClass} key={`${element.id}-img-upload-preview`}/> 
                        )
                        default: return (
                            <FormGroup key={`${element.id}-slider`} className={element.customClass}>
                                <InputLabel required={element.required} shrink>{element.label}</InputLabel>
                                <Slider
                                    id={element.id}
                                    name={element.id}
                                    defaultValue={0}
                                    value={sliderValue}
                                    valueLabelDisplay="auto"
                                    onChange={handleSlider}
                                    onChangeCommitted={handleSliderCommitted}
                                    onBlur={(e: any) => handleValidation(element)}
                                    disabled={element.disabled}
                                    max={element.max || 0}
                                />
                                {element.error ? <FormHelperText error={element.error}>{element.helperText}</FormHelperText> : ''}
                                <FormHelperText
                                    key={`${element.id}-select-counter`}
                                    className="custom-form__character-counter "
                                >
                                    Currently selected: {data[element.id]} / {element.max}
                                </FormHelperText>
                            </FormGroup>
                        )
                    }
                }
                else
                    return <React.Fragment key={`${element.id}-else-condition-empty-div`}></React.Fragment>
            })
        )
    }

    const dialogTopContent = () => {
        return (
            <React.Fragment key={'dialog-top-content'}>
                <ListSubheader>{locale.formatString(locale.form.review, formInterface)}</ListSubheader>
                {fields.map(entry => {
                    if (entry.touched && !entry.omitRecap) {
                        return (
                            <ListItem key={`${entry.id}-listitem-key`}>
                                <ListItemText
                                    key={`${entry.id}-listitem-text-key`}
                                    primary={formatter(data[entry.id], entry.dataType, entry.selectEntries)}
                                    secondary={entry.label}
                                />
                            </ListItem>
                        )
                    }else if(entry.touched && !entry.omitRecap && Array.isArray(data[entry.id])){
                        return(
                            <ListItem key={`${entry.id}-listitem-key`}>
                            <ListItemText
                                key={`${entry.id}-listitem-text-key`}
                                primary={data[entry.id].map((select: {id: number, value: string}, index: number) => {
                                    if(index< data[entry.id].length -1 ) return ` ${select.value},`
                                    else return ` ${select.value}.`
                                    
                                })}
                                secondary={entry.label}
                            />
                            </ListItem>
                        )
                    } else if(entry.touched && !entry.omitRecap && data[entry.id]){
                        return(
                            <ListItem key ={`${entry.id}-listitem-key`}>
                                <ListItemText
                                    key={`${entry.id}-listitem-text-key`}
                                    primary={data[entry.id].value}
                                    secondary={entry.label}
                                />
                            </ListItem>
                        )
                    } else {
                        return <div key={`${entry.id}-listitem-empty-key`}></div>
                }})}     
                <Divider />
            </React.Fragment>
        )
    }

    const dialogMidContent = () => {
        if(disableButton){
            return (
                <React.Fragment key={'dialog-mid-content'}>
                    <ListSubheader>{locale.form.missing_fields}</ListSubheader>
                        {fields.map(entry => {
                            if (entry.required && !entry.touched) {
                                return (
                                    <ListItem key={`${entry.id}-listitem-error-key`}>{entry.label}</ListItem>
                                )
                            }
                            else
                                return <React.Fragment key={`${entry.id}-empty-return-key`}></React.Fragment>
                        })}
                        <Divider />
                </React.Fragment>
            )
        }
    }

    const dialogBottomContent = () => {
        if(alertElement){
            return (
                <React.Fragment key={'dialog-bottom-content'}>
                    <Alert 
                        variant="filled" 
                        icon={<InfoIcon />} 
                        color="success">
                            {alertMessage}
                    </Alert>
                </React.Fragment>
            )
        }
    }

    const capitalize = (value: string): string => {
        return value.charAt(0).toUpperCase() + value.slice(1);
    }
    
    const dialog = () => {
        return (
            <Dialog
                key={'customform-dialog'}
                open={showDialog}
                scroll='body'
                maxWidth="sm"
                fullWidth={true}
            >
                <DialogTitle>{capitalize(formInterface)} {locale.shared.review}</DialogTitle>
                <DialogContent>
                    <List key={'customform-dialog-list'}>
                        {dialogTopContent()}
                        {dialogMidContent()}
                        {dialogBottomContent()}
                    </List>
                </DialogContent>
                <DialogActions>
                    <Button
                        key={`customform-dialog-toggle`}
                        onClick={toggleDialog}>
                        <ArrowBack />{locale.shared.back}
                    </Button>
                    <Button
                        key={`customform-dialog-save`}
                        color="primary"
                        disabled={disableButton}
                        onClick={(e: any) => onSave(e, imageInputRef)}>
                        <SaveIcon />{locale.shared.submit}
                    </Button>
                </DialogActions>
            </Dialog>
        )
    }

    return (
        <Paper className={classNames(customClassName, 'custom-form')}>
            {dialog()}
            <Card key={'customform-card'}>
                <CardHeader className="custom-form__card-title" title={title} />
                <CardContent>
                    <FormGroup>
                        {mainForm()}
                    </FormGroup>
                    {summary}
                </CardContent>
                <CardActions className="custom-form__action-buttons">
                    <Button
                        key={'customform-onreset-button'}
                        type="button"
                        variant="outlined"
                        size="medium"
                        startIcon={<UndoIcon />}
                        onClick={() => onReset(imageInputRef)}
                        disabled={noActions}
                    >
                        {locale.shared.reset}
                    </Button>
                    <Button
                        key={'customform-toggledialog-button'}
                        type="button"
                        variant="contained"
                        color="primary"
                        size="medium"
                        startIcon={<Done />}
                        onClick={toggleDialog}
                        disabled={isLoading || noActions}
                    >
                        {locale.shared.save}
                    </Button>
                    {isLoading &&
                        <Box
                            display={'flex'}
                            justifyContent={'center'}
                            alignItems={'center'}
                            paddingLeft={'0.5rem'}
                        >
                            <CircularProgress size={'1.2rem'} />
                        </Box>
                    }
                </CardActions>
            </Card>    
        </Paper>
    )
}

export default CustomForm

