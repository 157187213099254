import React, { useEffect, useState } from 'react';
import "./usersCreation.css";
import { 
    Card,
    CardHeader, 
    CardContent, 
    FormGroup,
    TextField,
    CardActions,
    Button,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogContentText,
    DialogActions,
    Box,
    CircularProgress,
    Grid,
} from '@material-ui/core';
import Autocomplete, { createFilterOptions } from '@material-ui/lab/Autocomplete';
import SendIcon from '@material-ui/icons/Send';
import UndoIcon from '@material-ui/icons/Undo';
import ClubService from '../../../services/Club-Service';
import IClub from '../../../models/club-model';
import IInvitation from '../../../models/invitation-model';
import InvitationService from '../../../services/Invitation-Service';
import { Role } from '../../../models/user-model';
import { useSnackbar } from 'notistack';
import { Utils } from '../../../Utils/utils';
import InvitesOutcomeTable from '../../dashboard/components/InvitesOutcomeTable/InvitesOutcomeTable';

const emailListOption: string[] = []
const filter = createFilterOptions<string>();

function UsersCreation() {
    const { enqueueSnackbar } = useSnackbar();
    const [isLoading, setIsLoading] = useState(false)
    const [userConfirmation, setUserConfirmation] = useState(false);
    const [showDialog, setShowDialog] = useState(false);
    const [clubList, setClubList] = useState<IClub[]>([]);
    const [email, setEmail] = useState<string[]>([])
    const [selectedClub, setSelectedClub] = useState("")
    const [invitesOutcome, setInvitesOutcome] = useState<IInvitation[]>([])

    useEffect(() => {
        ClubService.getClub()
            .then(club => {
                if (club)
                    setClubList(club)
                else
                    Utils.responseError(enqueueSnackbar)
                
            })
            .catch(e => {
                console.log(e)
                Utils.responseError(enqueueSnackbar)
            })
    }, [])

    //POST REQUEST
    useEffect(() => {
        if (userConfirmation) {
            setIsLoading(true)
            setUserConfirmation(false);
            setShowDialog(false);
            setInvitesOutcome([]);
            const invitation: IInvitation[] = []
            email.map(e => 
                invitation.push({
                    email: e,
                    club: (+selectedClub || undefined),
                    role: Role.CONSUMER
                })
            )
            InvitationService.sendInvitation(invitation)
                .then(res => {
                    resetInvitation()
                    setInvitesOutcome(res);
                })
                .catch(e => {
                    console.log(e)
                    Utils.responseError(enqueueSnackbar)
                })
                .finally(() => setIsLoading(false))
        }
    }, [email, selectedClub, userConfirmation, isLoading]);

    const resetInvitation = () => {
        setEmail([])
        setSelectedClub("")
        setInvitesOutcome([])
    }

    const emailValidation = !email.every(e => Utils.isValidEmail(e))

    return (
        <form>
            <Dialog
                open={showDialog}
            >
                <DialogTitle> Confirm </DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Are you sure you want to send an invitation?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={() => setShowDialog(false)}
                    > back</Button>
                    <Button
                        color="primary"
                        onClick={() => setUserConfirmation(true)}
                        autoFocus
                    >
                        Confirm
                    </Button>
                </DialogActions>
            </Dialog>
            <Card>
                <CardHeader
                    title={`Invite users`}
                />
                <CardContent>
                    <FormGroup>
                        <Autocomplete
                            id="multiple-email-tags"
                            value={email}
                            onChange={(event, newValue) => {
                                setEmail(newValue);
                            }}
                            filterOptions={(options, params) => {
                                const filtered = filter(options, params);

                                if (params.inputValue !== '') {
                                    filtered.push(params.inputValue);
                                }

                                return filtered;
                            }}
                            options={emailListOption}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    id="email" 
                                    name="email"
                                    label="Email"
                                    type="email"
                                    variant="outlined"
                                    InputLabelProps={{ "shrink": true }}
                                    margin="normal"
                                    multiline={true}
                                    error={emailValidation}
                                    helperText={emailValidation && 'Incorrect entry'}
                                />
                            )}
                            multiple
                            selectOnFocus
                            clearOnBlur
                            handleHomeEndKeys
                            freeSolo
                        />
                        <TextField
                            id="club"
                            name="club"
                            select
                            label="Group"
                            SelectProps={{
                                native: true,
                            }}
                            variant="outlined"
                            InputLabelProps={{ "shrink": true }}
                            margin="normal"
                            multiline={true}
                            value={selectedClub}
                            onChange={(e: any) => {
                                setSelectedClub(e.target.value)}
                            }>
                            <option value="" />
                            {clubList.map(c => 
                                <option key={`group-${c.id}`} value={c.id}>{c.value}</option>)
                            }
                        </TextField>
                    </FormGroup>
                </CardContent>
                <CardActions>
                    <Button
                        type="button"
                        variant="outlined"
                        size="medium"
                        startIcon={<UndoIcon />}
                        onClick={resetInvitation}
                        disabled={isLoading}
                    >
                        Reset
                    </Button>
                    <Button
                        type="button"
                        variant="contained"
                        color="primary"
                        size="medium"
                        startIcon={<SendIcon />}
                        onClick={() => setShowDialog(true)}
                        disabled={emailValidation || !email.length || isLoading}
                    >
                        Send
                    </Button>
                    {isLoading &&
                        <Box
                            display={'flex'}
                            justifyContent={'center'}
                            alignItems={'center'}
                            paddingLeft={'0.5rem'}
                        >
                            <CircularProgress size={'1.2rem'} />
                        </Box>
                    }
                </CardActions>
            </Card>
            {invitesOutcome.length ?
                <Grid
                    className="users-creation__outcome"
                    container 
                    spacing={4}
                >
                    <Grid item xs={12}>
                        <InvitesOutcomeTable 
                            data={invitesOutcome}
                        />
                    </Grid>
                </Grid>
                :
                <></>
            }
        </form>
    );
}

export default UsersCreation;

