import { Card, CardContent, CardHeader, Grid, LinearProgress, MenuItem, OutlinedInput, Select, Theme, Typography } from "@material-ui/core";
import React, { useState } from "react";
import { Area, AreaChart, Cell, ComposedChart, Line, LineChart, Pie, PieChart, ResponsiveContainer, XAxis, YAxis } from "recharts";
import QuestionsPending from "./components/QuestionsPending/QuestionsPending";
import useStyles from "./styles";
import { useTheme } from "@material-ui/styles";
import Dot from "../../components/Dot/Dot";
import { BaseColor } from "../../components/BaseColor";
import BigStat from "./components/BigStat/BigStat";
import mock from "./mock";

const mainChartData = getMainChartData();
const PieChartData = [
    { name: "Group A", value: 400, color: BaseColor.PRIMARY },
    { name: "Group B", value: 300, color: BaseColor.SECONDARY },
    { name: "Group C", value: 300, color: BaseColor.WARNING },
    { name: "Group D", value: 200, color: BaseColor.SUCCESS },
];

function Home() {
    const classes = useStyles();
    const theme: Theme = useTheme();

    const [mainChartState, setMainChartState] = useState("monthly");

    return <>
        <Grid container spacing={4}>
            <Grid item xs={12}>
                <QuestionsPending />
            </Grid>
            { false && <>
                <Grid item lg={3} md={4} sm={6} xs={12}>
                    <Card className={classes.card}>
                        <CardHeader
                            title="Visits Today"
                            titleTypographyProps={{ color: "textSecondary", variant: "body2"}}
                            className={classes.cardHeader}
                        />
                        <CardContent className={classes.fullHeightBody}>
                            <div className={classes.visitsNumberContainer}>
                                <Grid container item alignItems={"center"}>
                                    <Grid item xs={6}>
                                        <Typography variant="h4">
                                            12, 678
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <LineChart
                                            width={100}
                                            height={30}
                                            data={[
                                            { value: 10 },
                                            { value: 15 },
                                            { value: 10 },
                                            { value: 17 },
                                            { value: 18 },
                                            ]}
                                        >
                                            <Line
                                            type="natural"
                                            dataKey="value"
                                            stroke={theme.palette.success.main}
                                            strokeWidth={2}
                                            dot={false}
                                            />
                                        </LineChart>
                                    </Grid>
                                </Grid>
                            </div>
                            <Grid
                                container
                                direction="row"
                                justify="space-between"
                                alignItems="center"
                            >
                                <Grid item xs={4}>
                                    <Typography color="textSecondary" variant="body2" noWrap>
                                    Registrations
                                    </Typography>
                                    <Typography variant="h6">860</Typography>
                                </Grid>
                                <Grid item xs={4}>
                                    <Typography color="textSecondary" variant="body2" noWrap>
                                    Sign Out
                                    </Typography>
                                    <Typography variant="h6">32</Typography>
                                </Grid>
                                <Grid item xs={4}>
                                    <Typography color="textSecondary" variant="body2" noWrap>
                                    Rate
                                    </Typography>
                                    <Typography variant="h6">3.25%</Typography>
                                </Grid>
                            </Grid>
                        </CardContent>
                    </Card>
                </Grid>
                <Grid item lg={3} md={4} sm={6} xs={12}>
                    <Card className={classes.card}>
                        <CardHeader
                            title="App performance"
                            titleTypographyProps={{ color: "textSecondary", variant: "body2" }}
                            className={classes.cardHeader}
                        />
                        <CardContent className={classes.fullHeightBody}>
                            <div className={classes.performanceLegendWrapper}>
                                <div className={classes.legendElement}>
                                    <Dot color={BaseColor.WARNING} />
                                    <Typography
                                        color="textSecondary"
                                        variant="overline"
                                        className={classes.legendElementText}
                                    >
                                        Integration
                                    </Typography>
                                </div>
                                <div className={classes.legendElement}>
                                    <Dot color={BaseColor.PRIMARY} />
                                    <Typography
                                        color="textSecondary"
                                        className={classes.legendElementText}
                                    >
                                        SDK
                                    </Typography>
                                </div>
                            </div>
                            <div className={classes.progressSection}>
                                <Typography
                                    variant="subtitle1"
                                    color="textSecondary"
                                >
                                    Integration
                                </Typography>
                                <LinearProgress
                                    variant="determinate"
                                    value={77}
                                    classes={{ barColorPrimary: classes.progressBarPrimary }}
                                    className={classes.progress}
                                />
                            </div>
                            <div>
                                <Typography
                                    variant="subtitle2"
                                    color="textSecondary"
                                >
                                    SDK
                                </Typography>
                                <LinearProgress
                                    variant="determinate"
                                    value={73}
                                    classes={{ barColorPrimary: classes.progressBarWarning }}
                                    className={classes.progress}
                                />
                            </div>
                        </CardContent>
                    </Card>
                </Grid>
                <Grid item lg={3} md={4} sm={6} xs={12}>
                    <Card className={classes.card}>
                        <CardHeader
                            title="Server overview"
                            titleTypographyProps={{ color: "textSecondary", variant: "body2" }}
                            className={classes.cardHeader}
                        />
                        <CardContent className={classes.fullHeightBody}>
                            <div className={classes.serverOverviewElement}>
                                <Typography
                                    color="textSecondary"
                                    className={classes.serverOverviewElementText}
                                    noWrap
                                >
                                    60% / 37°С / 3.3 Ghz
                                </Typography>
                                <div className={classes.serverOverviewElementChartWrapper}>
                                    <ResponsiveContainer height={50} width="99%">
                                        <AreaChart data={getRandomData(10, 0, 10)}>
                                            <Area
                                                type="natural"
                                                dataKey="value"
                                                stroke={theme.palette.secondary.main}
                                                fill={theme.palette.secondary.light}
                                                strokeWidth={2}
                                                fillOpacity="0.25"
                                            />
                                        </AreaChart>
                                    </ResponsiveContainer>
                                </div>
                            </div>
                            <div className={classes.serverOverviewElement}>
                                <Typography
                                    color="textSecondary"
                                    className={classes.serverOverviewElementText}
                                    noWrap
                                >
                                    54% / 31°С / 3.3 Ghz
                                </Typography>
                                <div className={classes.serverOverviewElementChartWrapper}>
                                    <ResponsiveContainer height={50} width="99%">
                                        <AreaChart data={getRandomData(10, 0, 10)}>
                                            <Area
                                                type="natural"
                                                dataKey="value"
                                                stroke={theme.palette.primary.main}
                                                fill={theme.palette.primary.light}
                                                strokeWidth={2}
                                                fillOpacity="0.25"
                                            />
                                        </AreaChart>
                                    </ResponsiveContainer>
                                </div>
                            </div>
                            <div className={classes.serverOverviewElement}>
                                <Typography
                                    color="textSecondary"
                                    className={classes.serverOverviewElementText}
                                    noWrap
                                >
                                    57% / 21°С / 3.3 Ghz
                                </Typography>
                                <div className={classes.serverOverviewElementChartWrapper}>
                                    <ResponsiveContainer height={50} width="99%">
                                        <AreaChart data={getRandomData(10, 0, 10)}>
                                            <Area
                                                type="natural"
                                                dataKey="value"
                                                stroke={theme.palette.warning.main}
                                                fill={theme.palette.warning.light}
                                                strokeWidth={2}
                                                fillOpacity="0.25"
                                            />
                                        </AreaChart>
                                    </ResponsiveContainer>
                                </div>
                            </div>
                        </CardContent>
                    </Card>
                </Grid>
                <Grid item lg={3} md={4} sm={6} xs={12}>
                    <Card className={classes.card}>
                        <CardHeader
                            title="Revenue breakdown"
                            titleTypographyProps={{ color: "textSecondary", variant: "body2" }}
                            className={classes.cardHeader}
                        />
                        <CardContent className={classes.fullHeightBody}>
                            <Grid container spacing={2}>
                                <Grid item xs={6}>
                                    <ResponsiveContainer width="100%" height={144}>
                                        <PieChart>
                                            <Pie
                                                data={PieChartData}
                                                innerRadius={30}
                                                outerRadius={40}
                                                dataKey="value"
                                            >
                                                {PieChartData.map((entry, index) => (
                                                    <Cell
                                                        key={`cell-${index}`}
                                                        fill={theme.palette[entry.color].main}
                                                    />
                                                ))}
                                            </Pie>
                                        </PieChart>
                                    </ResponsiveContainer>
                                </Grid>
                                <Grid item xs={6}>
                                    <div className={classes.pieChartLegendWrapper}>
                                        {PieChartData.map(({ name, value, color }, index) => (
                                            <div key={color} className={classes.legendItemContainer}>
                                                <Dot color={color} />
                                                <Typography variant="caption" noWrap>
                                                    &nbsp;{name}&nbsp;
                                                </Typography>
                                                <Typography color="textSecondary">
                                                    &nbsp;{value}
                                                </Typography>
                                            </div>
                                        ))}
                                    </div>
                                </Grid>
                            </Grid>
                        </CardContent>
                    </Card>
                </Grid>
                <Grid item xs={12}>
                    <Card className={classes.mainChartBody}>
                        <CardContent>
                            <div className={classes.mainChartHeader}>
                                <Typography
                                    variant="overline"
                                    color="textSecondary"
                                >
                                    Daily Line Chart
                                </Typography>
                                <div className={classes.mainChartHeaderLabels}>
                                    <div className={classes.mainChartHeaderLabel}>
                                        <Dot color={BaseColor.WARNING} />
                                        <Typography variant="overline" className={classes.mainChartLegentElement}>
                                            Tablet
                                        </Typography>
                                    </div>
                                    <div className={classes.mainChartHeaderLabel}>
                                        <Dot color={BaseColor.PRIMARY} />
                                        <Typography variant="overline" className={classes.mainChartLegentElement}>
                                            Mobile
                                        </Typography>
                                    </div>
                                    <div className={classes.mainChartHeaderLabel}>
                                        <Dot color={BaseColor.SECONDARY} />
                                        <Typography variant="overline" className={classes.mainChartLegentElement}>
                                            Desktop
                                        </Typography>
                                    </div>
                                </div>
                                <Select
                                    value={mainChartState}
                                    onChange={(e: any) => setMainChartState(e.target.value)}
                                    input={
                                        <OutlinedInput
                                            labelWidth={0}
                                            classes={{
                                                notchedOutline: classes.mainChartSelectRoot,
                                                input: classes.mainChartSelect,
                                            }}
                                        />
                                    }
                                    autoWidth
                                >
                                    <MenuItem value="daily">Daily</MenuItem>
                                    <MenuItem value="weekly">Weekly</MenuItem>
                                    <MenuItem value="monthly">Monthly</MenuItem>
                                </Select>
                            </div>
                            <ResponsiveContainer width="100%" minWidth={500} height={350}>
                                <ComposedChart
                                    margin={{ top: 0, right: -15, left: -15, bottom: 0 }}
                                    data={mainChartData}
                                >
                                    <YAxis
                                        ticks={[0, 2500, 5000, 7500]}
                                        tick={{ fill: theme.palette.text.hint + "80", fontSize: 14 }}
                                        stroke={theme.palette.text.hint + "80"}
                                        tickLine={false}
                                    />
                                    <XAxis
                                        tickFormatter={i => i + 1}
                                        tick={{ fill: theme.palette.text.hint + "80", fontSize: 14 }}
                                        stroke={theme.palette.text.hint + "80"}
                                        tickLine={false}
                                    />
                                    <Area
                                        type="natural"
                                        dataKey="desktop"
                                        fill={theme.palette.primary.light}
                                        strokeWidth={0}
                                        activeDot={false}
                                    />
                                    <Line
                                        type="natural"
                                        dataKey="mobile"
                                        stroke={theme.palette.primary.main}
                                        strokeWidth={2}
                                        dot={false}
                                        activeDot={false}
                                    />
                                    <Line
                                        type="linear"
                                        dataKey="tablet"
                                        stroke={theme.palette.warning.main}
                                        strokeWidth={2}
                                        dot={{
                                            stroke: theme.palette.warning.dark,
                                            strokeWidth: 2,
                                            fill: theme.palette.warning.main,
                                        }}
                                    />
                                </ComposedChart>
                            </ResponsiveContainer>
                        </CardContent>
                    </Card>
                </Grid>
                {mock.bigStat.map(stat => (
                    <Grid item md={4} sm={6} xs={12} key={stat.product}>
                        <BigStat
                            product={stat.product}
                            color={stat.color}
                            total={stat.total}
                            bounce={stat.bounce}
                            registrations={stat.registrations}
                        />
                    </Grid>
                ))}
            </>
            }
        </Grid>
    </>
}

export default Home;


// // TODO remove after server integration
function getRandomData(length: number, min: number, max: number, multiplier = 10, maxDiff = 10) {
    const array = new Array(length).fill(length);
    let lastValue: number;

    return array.map((item, index) => {
        let randomValue = Math.floor(Math.random() * multiplier + 1);

        while (
            randomValue <= min ||
            randomValue >= max ||
            (lastValue && randomValue - lastValue > maxDiff)
        ) {
            randomValue = Math.floor(Math.random() * multiplier + 1);
        }

        lastValue = randomValue;

        return { value: randomValue };
    });
}

function getMainChartData() {
    const resultArray = [];
    const tablet = getRandomData(31, 3500, 6500, 7500, 1000);
    const desktop = getRandomData(31, 1500, 7500, 7500, 1500);
    const mobile = getRandomData(31, 1500, 7500, 7500, 1500);

    for (let i = 0; i < tablet.length; i++) {
        resultArray.push({
            tablet: tablet[i].value,
            desktop: desktop[i].value,
            mobile: mobile[i].value,
        });
    }

    return resultArray;
}
