import { useSnackbar } from 'notistack';
import React, { useState, useEffect, useMemo, ReactNode } from 'react';
import './question-detail.css'
import { useParams } from 'react-router';
import ICategory from '../../../../models/category-model';
import IQuestion, { IQuestionMapper, QuestionKinds, QuestionStatus, QuestionTypes } from '../../../../models/question-model';
import CategoryService from '../../../../services/Category-Service';
import QuestionService from '../../../../services/Question-Service';
import SubcategoryService from '../../../../services/Subcategory-Service';
import CustomForm, { FormType, DataType, FieldsProps } from '../question-form/customForm';
import moment from 'moment';
import { Utils } from '../../../../Utils/utils';
import {
    QUESTION_DESCRIPTION_CHARACTER_LIMIT,
    QUESTION_DESCRIPTION_ROWS,
    QUESTION_TITLE_CHARACTER_LIMIT,
    QUESTION_TITLE_ROWS
} from '../../../../Utils/constants';
import ClubService from '../../../../services/Club-Service';
import IClub from '../../../../models/club-model';
import { Alert } from '@material-ui/lab';
import InfoIcon from '@material-ui/icons/Info';
import UserService from '../../../../services/User-Service';
import locale from '../../../../languages/locale';
import PriceService from '../../../../services/Price-Service';
import * as _ from 'lodash';
import { Box, CircularProgress, InputLabel, Slider, FormGroup, Button, Card, CardHeader, CardActions, CardContent } from '@material-ui/core';
import Done from '@material-ui/icons/Done';
import { AvailableInterfaces } from '../../../../models/available-interfaces';

function QuestionDetail() {
    let params: any = useParams()
    const timezone = `(UTC ${moment.parseZone(moment().format()).format('Z')})`
    const { enqueueSnackbar } = useSnackbar();
    const [isLoading, setIsLoading] = useState(true);
    const [fetch, setFetch] = useState(true);
    const [initCategory, setInitCategory] = useState(false);
    const [showLoader, setShowLoader] = useState<boolean>(true);
    const [initCheck, setInitCheck] = useState(false);
    const [serverPrice, setServerPrice] = useState<number>(0);
    const [correctAnswer, setCorrectAnswer] = useState<number>(0);
    const [canSendCorrect, setCanSendCorrect] = useState<boolean>(false);
    const [data, setData] = useState<IQuestion>({
        id: 0,
        title: '',
        description: '',
        publicationDate: new Date(),
        type: QuestionTypes.STANDARD,
        kind: QuestionKinds.QUALITATIVE,
        endingDate: new Date(),
        leftValue: '',
        rightValue: '',
        category: { id: 0, value: '' }
    });
    const [backupData, setBackupData] = useState<IQuestion>({
        id: 0,
        title: '',
        description: '',
        publicationDate: new Date(),
        type: QuestionTypes.STANDARD,
        kind: QuestionKinds.QUALITATIVE,
        endingDate: new Date(),
        leftValue: '',
        rightValue: '',
        category: { id: 0, value: '' }

    });
    const [editFields, setEditFields] = useState(false)
    const [fields, setFields] = useState<FieldsProps[]>([
        {
            required: false,
            formType: FormType.SELECT,
            id: 'status',
            dataType: DataType.STRING,
            label: locale.form.field.question_status.label,
            error: false,
            helperText: locale.form.field.question_status.helper,
            selectEntries: [QuestionStatus.PENDING, QuestionStatus.APPROVED, QuestionStatus.REFUSED],
            position: 0,
            touched: false,
            show: true,
            customClass: 'question-detail__field-full'
        },
        {
            required: true,
            formType: FormType.INPUT,
            id: 'title',
            dataType: DataType.STRING,
            label: locale.form.field.question_title.label,
            error: false,
            helperText: locale.form.field.question_title.helper,
            position: 1,
            touched: false,
            show: true,
            multiline: true,
            rows: QUESTION_TITLE_ROWS,
            characterLimit: QUESTION_TITLE_CHARACTER_LIMIT,
            customClass: 'question-detail__field-full',
            characterCount: QUESTION_TITLE_CHARACTER_LIMIT.toString()
        },
        {
            required: true,
            formType: FormType.INPUT,
            id: 'description',
            dataType: DataType.STRING,
            label: locale.form.field.question_desc.label,
            error: false,
            helperText: locale.form.field.question_desc.helper,
            position: 2,
            touched: false,
            show: true,
            multiline: true,
            rows: QUESTION_DESCRIPTION_ROWS,
            characterLimit: QUESTION_DESCRIPTION_CHARACTER_LIMIT,
            customClass: 'question-detail__field-full',
            characterCount: QUESTION_DESCRIPTION_CHARACTER_LIMIT.toString()
        },
        {
            required: true,
            formType: FormType.SELECT,
            id: 'type',
            dataType: DataType.STRING,
            label: locale.form.field.question_type.label,
            error: false,
            helperText: locale.form.field.question_type.helper,
            selectEntries: [QuestionTypes.STANDARD, QuestionTypes.PREMIUM],
            position: 3,
            touched: true,
            show: true,
            customClass: 'question-detail__half-field',
            disabled: true
        },
        {
            required: true,
            formType: FormType.SELECT,
            id: 'kind',
            dataType: DataType.STRING,
            label: locale.form.field.question_kind.label,
            error: false,
            helperText: locale.form.field.question_kind.helper,
            selectEntries: [QuestionKinds.QUANTITATIVE, QuestionKinds.QUALITATIVE],
            position: 4,
            touched: true,
            show: true,
            customClass: 'question-detail__half-field'
        },
        {
            required: true,
            formType: FormType.INPUT,
            id: 'publicationDate',
            dataType: DataType.DATE,
            label: `${locale.form.field.question_pubb.label} ${timezone}`,
            error: false,
            helperText: locale.form.field.question_pubb.helper,
            position: 5,
            touched: false,
            show: true,
            customClass: 'question-detail__date-field'
        },
        {
            required: true,
            formType: FormType.INPUT,
            id: 'endingDate',
            dataType: DataType.DATE,
            label: `${locale.form.field.question_end.label} ${timezone}`,
            error: false,
            helperText: locale.form.field.question_end.helper,
            position: 6,
            touched: false,
            show: true,
            customClass: 'question-detail__date-field'
        },
        {
            required: false,
            formType: FormType.INPUT,
            id: 'eventDate',
            dataType: DataType.DATE,
            label: `${locale.form.field.question_event.label} ${timezone}`,
            error: false,
            helperText: locale.form.field.question_event.helper,
            position: 7,
            touched: false,
            show: true,
            customClass: 'question-detail__date-field'
        },
        {
            required: true,
            formType: FormType.INPUT,
            id: 'leftValue',
            dataType: DataType.STRING,
            label: locale.form.field.question_min.label,
            error: false,
            helperText: locale.form.field.question_min.helper,
            position: 8,
            touched: false,
            show: true,
            customClass: 'question-detail__value-field'
        },
        {
            required: false,
            formType: FormType.INPUT,
            id: 'midValue',
            dataType: DataType.STRING,
            label: locale.form.field.question_mid.label,
            error: false,
            helperText: locale.form.field.question_mid.helper,
            position: 9,
            touched: false,
            show: true,
            customClass: 'question-detail__value-field'
        },
        {
            required: true,
            formType: FormType.INPUT,
            id: 'rightValue',
            dataType: DataType.STRING,
            label: locale.form.field.question_max.label,
            error: false,
            helperText: locale.form.field.question_max.helper,
            position: 10,
            touched: false,
            show: true,
            customClass: 'question-detail__value-field'
        },
        {
            required: false,
            formType: FormType.SELECT,
            id: 'module',
            dataType: DataType.STRING,
            label: locale.form.field.question_unit.label,
            error: false,
            helperText: locale.form.field.question_unit.helper,
            selectEntries: ['', '€', '$', 'Kg', '%'],
            position: 11,
            touched: false,
            show: false,
            customClass: 'question-detail__value-field'
        },
        {
            required: true,
            formType: FormType.SELECT,
            id: 'category',
            dataType: DataType.OBJECT,
            label: locale.form.field.question_cat.label,
            error: false,
            helperText: locale.form.field.question_cat.helper,
            position: 12,
            touched: false,
            show: true,
            customClass: 'question-detail__half-field',
            omitRecap: true
        },
        {
            required: false,
            formType: FormType.SELECT,
            id: 'category2',
            dataType: DataType.OBJECT,
            label: 'Question second category',
            error: false,
            helperText: 'The choose two different categories',
            position: 13,
            touched: false,
            show: true,
            customClass: 'question-detail__half-field',
            omitRecap: true
        },
        {
            required: false,
            formType: FormType.AUTOCOMPLETE,
            id: 'subCategories',
            dataType: DataType.STRING,
            label: locale.form.field.question_subcat.label,
            error: false,
            position: 14,
            touched: false,
            show: true,
            customClass: 'question-detail__half-field',
            selectEntries: [],
            omitRecap: true
        },
        {
            required: false,
            formType: FormType.AUTOCOMPLETE,
            id: 'subCategories2',
            dataType: DataType.STRING,
            label: locale.form.field.question_subcat_2.label,
            error: false,
            position: 15,
            touched: false,
            show: true,
            customClass: 'question-detail__half-field',
            selectEntries: [],
            omitRecap: true
        },
        {
            required: false,
            formType: FormType.AUTOCOMPLETE,
            id: 'usersClub',
            dataType: DataType.STRING,
            label: 'Filter users by club',
            error: false,
            position: 16,
            touched: false,
            show: false,
            customClass: 'question-detail__field-full',
            omitRecap: true
        },
        {
            required: false,
            formType: FormType.AUTOCOMPLETE,
            id: 'usersCategory',
            dataType: DataType.STRING,
            label: 'Filter users by category',
            error: false,
            position: 17,
            touched: false,
            show: false,
            customClass: 'question-detail__field-full'
        },
        {
            required: false,
            formType: FormType.CHECKBOX,
            id: 'showAuthor',
            dataType: DataType.BOOLEAN,
            label: 'Show Author',
            error: false,
            position: 18,
            touched: false,
            show: false,
            customClass: 'question-detail__field-full question-detail__show-author',
        },
        {
            required: false,
            formType: FormType.SLIDER,
            id: 'totalUsers',
            dataType: DataType.NUMBER,
            label: locale.form.field.question_tot_users.label,
            error: false,
            position: 19,
            helperText: locale.form.field.question_tot_users.helper,
            touched: false,
            show: false,
            customClass: 'question-detail__slider',
            omitRecap: false
        },
    ]);
    const [disableButton, setDisableButton] = useState(true)
    const [showDialog, setShowDialog] = useState(false)
    const alertHeadString = `Your question's final price is only: `
    const [alertMessage, setAlertMessage] = useState(``)
    const [alertElement, setAlertElement] = useState(false)
    const [categories, setCategories] = useState<ICategory[]>([])
    const [clubList, setClubList] = useState<IClub[]>([]);
    const [isPremium, setIsPremium] = useState<boolean>(false);
    const [userPrice, setUserPrice] = useState<number>(0)
    const [maxUsers, setMaxUsers] = useState<number>(0)
    const [premiumConfig, setPremiumConfig] = useState<boolean>(false)
    const discount = 0; //placeholder
    const [sliderValue, setSliderValue] = useState<number>(0);
    const [sliderMin, setSliderMin] = useState<number>(0);
    const [sliderMax, setSliderMax] = useState<number>(100);
    const [sliderStep, setSliderStep] = useState<number>(50);
    const [percValue, setPercValue] = useState<string>('');
    const evaluateCanSendCorrect = (question: IQuestion) => {
        if (question.type === QuestionTypes.STANDARD
            && !question.correctAnswer?.trim()
            && moment(question.eventDate).isBefore(new Date())
            && question.status === QuestionStatus.APPROVED
        ) {
            setCanSendCorrect(true);
        };
    };

    enum QualitativeSlider {
        LEFT = '0',
        CENTER = '50',
        RIGHT = '100'
    };

    const elaborateSliderValues = (question: IQuestion) => {
        if (question.kind === QuestionKinds.QUALITATIVE) {
            if (!question.midValue) setSliderStep(parseInt(QualitativeSlider.RIGHT));
        } else {
            setSliderStep(1)
            setSliderMin(parseInt(QualitativeSlider.LEFT));
            setSliderMax(parseInt(QualitativeSlider.RIGHT));
        }
    };

    const formatSliderLabel = (value: number): ReactNode => {
        if (data.kind === QuestionKinds.QUALITATIVE) {
            switch (value.toString()) {
                case QualitativeSlider.LEFT: {
                    return data.leftValue
                }
                case QualitativeSlider.RIGHT: {
                    return data.rightValue
                }
                default: {
                    return data.midValue
                }
            }
        } else {
            const right = parseInt(data.rightValue);
            const left = parseInt(data.leftValue);
            const diff = right - left;
            const partial = value * diff / 100;
            const result = left + partial;
            return result.toFixed(2).endsWith('.00') ? result.toString() : result.toFixed(2);
        }
    };

    const findPercValue = (input: string): string => {
        const correct = parseInt(data.correctAnswer!);
        const left = parseInt(data.leftValue);
        const right = parseInt(data.rightValue);
        const val = Math.floor((correct / 100) * (right - left)).toString();
        return val
    }

    useEffect(() => {
        if (fetch) {
            QuestionService.getOne(params.id)
                .then(res => {
                    if (res?.error) {
                        Utils.responseError(enqueueSnackbar)
                        setIsLoading(false)
                    }
                    else {
                        setData({ ...res });
                        elaborateSliderValues(res);
                        setBackupData({ ...res });

                        setInitCategory(true);
                        if (res.price && res.totalUsers) {
                            setUserPrice(Math.round(res.price / res.totalUsers * 100) / 100);
                            setSliderValue(res.totalUsers);
                        }
                        setFetch(false);
                        evaluateCanSendCorrect(res);
                        if (res.correctAnswer) setPercValue(findPercValue(res.correctAnswer))
                    }
                })
                .catch(e => {
                    console.log(e)
                    Utils.responseError(enqueueSnackbar)
                    setIsLoading(false)
                })
                .finally(() => {
                    setShowLoader(false);
                })
        }
    }, [fetch]);

    //get categories
    useEffect(() => {
        if (initCategory) {
            CategoryService.getAll()
                .then(res => {
                    setCategories(res)
                    const f = [...fields]
                    const c = res.map(c => {
                        return { id: c.id, value: c.value }
                    })
                    f[f.findIndex(field => field.id === 'category')].selectEntries = c
                    f[f.findIndex(field => field.id === 'category2')].selectEntries = c.concat([{ id: 0, value: '' }]).filter(entry => entry.id !== data.category.id)
                    f[f.findIndex(field => field.id === 'usersCategory')].selectEntries = c
                    setFields(f);
                    setInitCategory(false)
                    setEditFields(true);
                })
                .catch(e => {
                    console.log(e)
                    Utils.responseError(enqueueSnackbar)
                    setIsLoading(false)
                })

        }
    }, [initCategory]);
    
    //UPDATE SUBCATEGORIES BASED ON QUESTION CATEGORIES
    const updateSubcategories = (categoryone: boolean, category?: number) => {
        SubcategoryService.getSubcategories(category)
            .then(res => {
                const response = res;
                const newFields = [...fields];
                var toUpdate = categoryone ? newFields.find(entry => entry.id === 'subCategories') : newFields.find(entry => entry.id === 'subCategories2');
                newFields[toUpdate!.position].selectEntries = response.map(entry => { return { id: entry.id, value: entry.value } })
                setFields([...newFields])
            })
            .catch(err => console.log('Sub error : ', err))
    }

    //set show and touched state of fields
    useEffect(() => {
        if (editFields) {
            //switch required and visibility of fields
            const newData = { ...data }
            const newFields: FieldsProps[] = fields.map(key => {
                switch (key.id) {
                    case 'module': {
                        const updatedKey = {
                            ...key,
                            show: (data.kind === QuestionKinds.QUANTITATIVE)
                        }
                        if (data.kind === QuestionKinds.QUALITATIVE) {
                            newData.module = undefined;
                            setData(newData)
                            updatedKey.touched = false
                        }
                        return updatedKey
                    }
                    case 'midValue': {
                        const updatedKey = {
                            ...key,
                            show: (data.kind === QuestionKinds.QUALITATIVE)
                        }
                        if (data.kind === QuestionKinds.QUANTITATIVE) {
                            newData.midValue = undefined
                            setData(newData)
                            updatedKey.touched = false
                        }
                        return updatedKey
                    }
                    case 'totalUsers': {
                        const updatedKey = {
                            ...key,
                            required: (data.type === QuestionTypes.PREMIUM),
                            show: (data.type === QuestionTypes.PREMIUM),
                            touched: (data.type === QuestionTypes.PREMIUM && data.totalUsers! > 0)
                        }
                        return updatedKey
                    }
                    case 'eventDate': {
                        const updatedKey = {
                            ...key,
                            required: (data.type === QuestionTypes.STANDARD),
                            show: (data.type === QuestionTypes.STANDARD)
                        }
                        if (data.type === QuestionTypes.PREMIUM) {
                            newData.eventDate = undefined;
                            setData(newData);
                            updatedKey.touched = false;
                        }
                        return updatedKey
                    }
                    case 'usersClub': {
                        const updatedKey = {
                            ...key,
                            show: (data.type === QuestionTypes.PREMIUM),
                        }
                        if (data.type === QuestionTypes.STANDARD) {
                            newData.usersClub = undefined;
                            setData(newData);
                            updatedKey.touched = false;
                        }
                        return updatedKey
                    }
                    case 'usersCategory': {
                        const updatedKey = {
                            ...key,
                            show: (data.type === QuestionTypes.PREMIUM),
                        }
                        if (data.type === QuestionTypes.STANDARD) {
                            newData.usersCategory = undefined;
                            setData(newData);
                            updatedKey.touched = false;
                        }
                        return updatedKey
                    }
                    case 'type': {
                        if (data.type === QuestionTypes.PREMIUM)
                            setIsPremium(true)
                        else
                            setIsPremium(false)
                        return key
                    }
                    case 'publicationDate':
                    case 'endingDate': {
                        const updatedKey = {
                            ...key,
                            customClass: (data.type === QuestionTypes.STANDARD ? 'question-detail__date-field' : 'question-detail__premium-date-field')
                        }
                        return updatedKey
                    }
                    case 'showAuthor': {
                        const updatedKey = {
                            ...key,
                            show: (data.type === QuestionTypes.PREMIUM),
                            touched: (data.type === QuestionTypes.PREMIUM)
                        }
                        return updatedKey
                    }
                    default: {
                        return key
                    }
                };
            });
            setFields([...newFields])
            setEditFields(false)
            setInitCheck(true)
        }
    }, [editFields]);

    useEffect(() => {
        if (initCheck) {
            fields.forEach((entry: FieldsProps) => {
                var newFields = [...fields]
                if (
                    (data[entry.id] !== undefined && data[entry.id] !== null && data[entry.id] !== '') &&
                    (entry.id !== 'totalUsers' || data[entry.id]! > 0) &&
                    (entry.id !== 'subCategories' || data[entry.id]?.length! > 0) &&
                    (entry.id !== 'subCategories2' || data[entry.id]?.length! > 0)
                ) {
                    newFields[entry.position].touched = true
                } else if (newFields[entry.position].required) {
                    newFields[entry.position].touched = false
                }
                if (moment(data.publicationDate).isBefore(new Date()) && data.status === QuestionStatus.APPROVED)
                    newFields[entry.position].disabled = true;
                setFields([
                    ...newFields
                ])

            })
            setIsLoading(false)
            setInitCheck(false)
        }
    }, [initCheck]);

    //open recap dialog
    const toggleDialog = (e: any) => {
        const newValue = !showDialog;
        setShowDialog(newValue)
    }

    const cleanQuestionData = (questionData: IQuestion) => {
        const cleanQuestion: { [key: string]: any } = {};
        Object.keys(questionData).forEach(key => {
            switch (key) {
                case 'usersClub': {
                    if (!_.isEqual(questionData.usersClub, backupData.usersClub)) {
                        cleanQuestion.usersClub = questionData.usersClub;
                    }
                    break
                }
                case 'usersCategory': {
                    if (!_.isEqual(questionData.usersCategory, backupData.usersCategory)) {
                        cleanQuestion.usersCategory = questionData.usersCategory;
                    }
                    break
                }
                case 'subCategories': {
                    if (!_.isEqual(questionData.subCategories, backupData.subCategories)) {
                        cleanQuestion.subCategories = questionData.subCategories;
                    }
                    break
                }
                case 'subCategories2': {
                    if (!_.isEqual(questionData.subCategories2, backupData.subCategories2)) {
                        cleanQuestion.subCategories2 = questionData.subCategories2;
                    }
                    break
                }
                default: {
                    if (backupData[key] === questionData[key]) {
                        //opposite check doesn't work
                    } else {
                        cleanQuestion[key] = questionData[key];
                    }
                    break
                }
            }
        })
        return cleanQuestion
    }

    //submit question
    const onSave = (e: any) => {
        const requestData = cleanQuestionData(data);
        setIsLoading(true)
        setShowDialog(false)
        QuestionService.update(params.id, requestData, isPremium)
            .then(res => {
                if (res?.error) {
                    Utils.responseError(enqueueSnackbar)
                } else {
                    enqueueSnackbar('Question successfully sent!',
                        Utils.getSnackbarOptions('success'))
                }
            })
            .catch(err => {
                console.log(err)
                Utils.responseError(enqueueSnackbar)
            })
            .finally(() => {
                setShowLoader(true);
                setIsLoading(false);
                setFetch(true);
            })
    };
    
    //GET CLUBS
    useEffect(() => {
        if (isPremium) {
            setIsLoading(true)
            ClubService.getClub()
                .then(club => {
                    if (club) {
                        setClubList(club)
                        const f = [...fields]
                        f[f.findIndex(field => field.id === 'usersClub')].selectEntries = club.map(c => {
                            return { id: c.id, value: c.value }
                        })
                        setFields(f)
                        setPremiumConfig(true)
                    } else
                        Utils.responseError(enqueueSnackbar)
                })
                .catch(e => {
                    Utils.responseError(enqueueSnackbar)
                })
                .finally(() => setIsLoading(false))
        }
    }, [isPremium])

    //GET MAX USER NUMBER
    useEffect(() => {
        if (premiumConfig) {
            const usersClub = data['usersClub']?.length ? data['usersClub'].map(c => c.id.toString()) : undefined
            const usersCategory = data['usersCategory']?.length ? data['usersCategory'].map(cat => cat.id.toString()) : undefined

            setIsLoading(true)
            UserService.getUsersNumber(usersClub, usersCategory)
                .then(res => {
                    if (res.payload >= 0) {
                        setMaxUsers(res.payload)
                        const f = [...fields]
                        f[f.findIndex(field => field.id === 'totalUsers')].max = res.payload
                        if (data.totalUsers && data.totalUsers > res.payload) {
                            setData({ ...data, price: 0, totalUsers: 0 });
                            setEditFields(true);
                        }
                        setPremiumConfig(false)
                    }
                    else
                        Utils.responseError(enqueueSnackbar)
                })
                .catch(e => {
                    console.log(e)
                    Utils.responseError(enqueueSnackbar)
                })
                .finally(() => {
                    setIsLoading(false)
                    setPremiumConfig(false)
                })
        }
    }, [premiumConfig])

    //reset question data and fields props
    const onReset = () => {
        const newFields = fields.map(entry => {
            return {
                ...entry,
                error: false,
                touched: (entry.id === 'type' || entry.id === 'kind'),
                show: entry.id === 'totalUsers' ?
                    false : entry.show,
                selectEntries: (entry.id === 'subCategories' || entry.id === 'subCategories2') ?
                    undefined : entry.selectEntries
            }
        })
        //RESET CHAR COUNTERS
        const newTitle = newFields.find(field => field.id === 'title');
        if (newTitle) newTitle.characterCount = QUESTION_TITLE_CHARACTER_LIMIT.toString();
        const newDesc = newFields.find(field => field.id === 'description');
        if (newDesc) newDesc.characterCount = QUESTION_DESCRIPTION_CHARACTER_LIMIT.toString();
        setFields([...newFields]);
        setData(backupData);
        setIsLoading(true);
        setInitCategory(true);
    };

    const getPrice = (users: number) => {
        const chosenUsers = users;
        PriceService.getPrice(users)
            .then(res => {
                if (res.payload) {
                    setServerPrice(res.payload.price);
                    setUserPrice(Math.round(res.payload.price / chosenUsers * 100) / 100);
                    setData({
                        ...data,
                        price: res.payload.price,
                        priceId: res.payload.id,
                        totalUsers: users
                    });
                }
            })
            .catch(err => {
                enqueueSnackbar('Something went wrong while elaborating the price, please try again')
            })
    };

    //all-round change handler
    const handleChange = (e: any, newValue?: any[], elementKey?: string) => {
        const inputValue = e.target.value || newValue || '';
        const key = e.target.name || elementKey;
        if (key === 'kind' || key === 'type') setEditFields(true);
        if (key === 'category' || key === 'category2') {
            //DIVIDE HERE
            if (key === 'category') {
                const newFields = [...fields];
                const toUpdate = newFields.find(entry => entry.id === 'category2');
                const filterCats = categories.filter(entry => entry.id !== inputValue).concat([{ id: 0, value: '' }])
                newFields[toUpdate!.position].selectEntries = filterCats;
                setFields([
                    ...newFields
                ]);
                if (inputValue === data.category2?.id) data.category2 = undefined
            }
            let selectedCategory;

            const newData = { ...data };
            const categoryIndex = categories.find(c => c.id === inputValue);

            newData[key] = { id: categoryIndex?.id, value: categoryIndex?.value };
            if (key === 'category') {
                newData.subCategories2 = [];
                newData.subCategories = [];
            } else newData.subCategories2 = [];
            setData({ ...newData });

        } else if (key === 'subCategories' || key === 'subCategories2') {
            let filterSubs = inputValue.filter((v: { id: number, value: string }, i: number, a: { id: number, value: string }[]) => a.findIndex(t => (t.id === v.id)) === i)
            setData({
                ...data,
                [key]: filterSubs
            });
        } else if (key === 'usersCategory') {
            let filterCats = inputValue.filter((v: { id: number, value: string }, i: number, a: { id: number, value: string }[]) => a.findIndex(t => (t.id === v.id)) === i)
            setData({
                ...data,
                [key]: filterCats
            });
            setPremiumConfig(true);
        } else if (key === 'usersClub') {
            let filterClub = inputValue.filter((v: { id: number, value: string }, i: number, a: { id: number, value: string }[]) => a.findIndex(t => (t.id === v.id)) === i)
            setData({
                ...data,
                [key]: filterClub
            });
            setPremiumConfig(true);
        } else if (key === 'showAuthor') {
            setData({
                ...data,
                [key]: e.target.checked
            });
        } else {
            setData({
                ...data,
                [key]: inputValue
            });
            //update character counter on title & description
            if (key === 'title') {
                const newFields = [...fields];
                const toUpdate = newFields.find(entry => { if (entry.id === 'title') return entry });
                toUpdate!.characterCount = (QUESTION_TITLE_CHARACTER_LIMIT - inputValue.length).toString();
                setFields([
                    ...newFields
                ]);
            };
            if (key === 'description') {
                const newFields = [...fields];
                const toUpdate = newFields.find(entry => { if (entry.id === 'description') return entry });
                toUpdate!.characterCount = (QUESTION_DESCRIPTION_CHARACTER_LIMIT - inputValue.length).toString();
                setFields([
                    ...newFields
                ]);
            };
        }

    };

    const handleSlider = (e: any, value: number | number[]) => {
        if (typeof value === 'number') {
            setSliderValue(value);
        }
    };

    const handleSliderCommitted = (e: any, value: number | number[]) => {
        if (typeof value === 'number') {
            getPrice(value)
        }
    }

    //set FieldsProps error
    const handleValidation = (element: FieldsProps) => {
        switch (element.id) {
            case 'publicationDate': {
                const newFields = [...fields]
                const now = new Date();
                const input = new Date(data[element.id]);
                const ending = new Date(data.endingDate);
                const endingIndex = fields.findIndex(field => field.id === 'endingDate');
                const endingTouched = fields[endingIndex].touched;
                const newInput: FieldsProps = {
                    ...element,
                    error: input < now || (input > ending && endingTouched),
                    touched: true,
                    helperText: input > ending && endingTouched ?
                        'Publication date must be set before ending date' :
                        'Publication date must be set past now'
                };
                if (endingTouched) {
                    if (input < ending)
                        newFields[endingIndex].error = false;
                    else
                        newFields[endingIndex].error = true;
                }
                newFields[element.position] = newInput;
                setFields(newFields)
                break
            }
            case 'endingDate': {
                const newFields = [...fields]
                const publication = new Date(data.publicationDate);
                const ending = new Date(data[element.id]);
                const event = new Date(data.eventDate!);
                const eventIndex = fields.findIndex(field => field.id === 'eventDate');
                const eventTouched = fields[eventIndex].touched;
                const newInput = { ...element, error: publication > ending, touched: true }
                if (eventTouched) {
                    if (event >= ending)
                        newFields[eventIndex].error = false;
                    else
                        newFields[eventIndex].error = true;
                }
                newFields[element.position] = newInput;
                setFields(newFields)
                break
            }
            case 'eventDate': {
                const newFields = [...fields]
                const ending = data.endingDate;
                const event = data[element.id];
                const newInput = { ...element, error: !(event && event >= ending), touched: true }
                newFields[element.position] = newInput;
                setFields(newFields)
                break
            }
            case 'totalUsers': {
                const newFields = [...fields]
                const userInput: boolean = data[element.id]! < 1;
                const newInput = { ...element, error: userInput, touched: !userInput };
                newFields[element.position] = newInput;
                setFields(newFields)
                break
            }
            case 'category':
            case 'category2': {
                const newFields = [...fields];
                var updated = newFields[element.position];
                updated = { ...updated, touched: true };
                newFields[updated.position] = updated;
                setFields([
                    ...newFields
                ])
                break
            }
            case "leftValue":
            case "rightValue": {
                const newFields = [...fields];
                const value = data[element.id];
                const userInput: boolean = !!value && value !== '';
                if (data.kind === QuestionKinds.QUANTITATIVE) {
                    const test: boolean = isNaN(parseInt(data[element.id]!));
                    const newInput = {
                        ...element,
                        error: test ? true : !userInput,
                        touched: userInput,
                        helperText: "Please provide a numeric value"
                    };
                    newFields[element.position] = newInput;

                } else {
                    const newInput = {
                        ...element,
                        error: !(element.required && userInput),
                        touched: userInput,
                        helperText: "Please provide a value"
                    };
                    newFields[element.position] = newInput;

                }
                setFields(newFields)
                break
            }
            default: {
                const newFields = [...fields]
                const value = data[element.id];
                const userInput = value && value !== undefined;
                const newInput = {
                    ...element,
                    error: element.required ? !userInput : false,
                    touched: userInput
                };
                newFields[element.position] = newInput;
                setFields(newFields)
                break
            }
        }
    };

    //check if there are any missing fields
    useEffect(() => {
        const missingFields = !fields.filter(key => key.required).every(key => key.touched && !key.error);
        setDisableButton(missingFields)
        if (data.price) {
            setAlertMessage(locale.formatString(locale.form.total_price, data.price?.toFixed(2)).toString());
        }
        setAlertElement(!missingFields && data.type === QuestionTypes.PREMIUM)

    }, [fields]);

    //get and filter subcategories when data categories change
    useEffect(() => {
        updateSubcategories(true, data.category.id)

    }, [data.category]);

    useEffect(() => {
        updateSubcategories(false, data.category2?.id)

    }, [data.category2]);

    const summary = () => data.type === QuestionTypes.PREMIUM ?
        <div className='question-detail__summary'>
            {data.totalUsers! > 0 &&
                <Alert
                    variant='filled'
                    icon={<InfoIcon />}
                    color='success'
                >
                    For the next 20 minutes the maximum price of the question is: {data.price?.toFixed(2)} € ({userPrice.toFixed(2)} € per user)
                </Alert>
            }
        </div>
        : <></>

    const handleCorrectAnswer = (e: any, value: number | number[]) => {
        if (typeof (value) === 'number') setCorrectAnswer(value);
    };

    const sendCorrectAnswer = () => {
        setIsLoading(true);
        const correct = correctAnswer.toString();
        setData({
            ...data,
            correctAnswer: correct
        });
        setCanSendCorrect(false);
        setShowLoader(true);
        QuestionService.sendCorrectAnswer(correct, params.id)
            .then(res => {
                if (res.error) Utils.responseError(enqueueSnackbar);
                else {
                    enqueueSnackbar('Answer successfully sent!', Utils.getSnackbarOptions('success'));

                }
            })
            .catch(err => console.log(err))
            .finally(() => {
                setFetch(true)
            })
    };

    return (
        <React.Fragment>
            {showLoader &&
                <Box
                    display={'flex'}
                    justifyContent={'center'}
                    alignItems={'center'}
                    paddingLeft={'0.5rem'}
                >
                    <CircularProgress size={'1.2rem'} />
                </Box>
            }
            {(!showLoader && canSendCorrect) &&
                <Card className='question-detail__answer-card'>
                    <CardHeader title={locale.question_detail.correct_answer} />
                    <CardContent>
                        <FormGroup key='correct-answer-slider'>
                            <Slider
                                id='correctAnswer'
                                name='correctAnswer'
                                value={correctAnswer}
                                valueLabelDisplay='auto'
                                onChange={(e: any, value: number | number[]) => handleCorrectAnswer(e, value)}
                                min={sliderMin}
                                max={sliderMax}
                                step={sliderStep}
                                valueLabelFormat={(value: number, index: number) => formatSliderLabel(value)}
                            />
                            <InputLabel>{locale.question_detail.selected}: {formatSliderLabel(correctAnswer)} </InputLabel>
                        </FormGroup>
                    </CardContent>
                    <CardActions className='question-detail__action-buttons'>
                        <Button
                            type='button'
                            variant='contained'
                            color='primary'
                            size='medium'
                            startIcon={<Done />}
                            onClick={() => sendCorrectAnswer()}
                        >
                            {locale.question_detail.send_answer}
                        </Button>
                    </CardActions>
                </Card>
            }
            {(!canSendCorrect && data.correctAnswer) &&
                <Card className='question-detail__answer-card'>
                    <CardHeader title={`${locale.question_detail.correct_answer} : ` + formatSliderLabel(parseInt(data.correctAnswer))} />
                </Card>
            }
            {!showLoader &&
                <CustomForm
                    customClassName='question-detail'
                    formInterface={AvailableInterfaces.QUESTION}
                    data={data}
                    fields={fields}
                    disableButton={disableButton}
                    toggleDialog={toggleDialog}
                    showDialog={showDialog}
                    alertElement={alertElement}
                    alertMessage={alertMessage}
                    title={locale.question_detail.edit}
                    isLoading={isLoading}
                    onSave={onSave}
                    onReset={onReset}
                    handleChange={handleChange}
                    handleValidation={handleValidation}
                    noActions={fields.every(f => f.disabled)}
                    summary={summary()}
                    sliderValue={sliderValue}
                    handleSlider={handleSlider}
                    handleSliderCommitted={handleSliderCommitted}
                />
            }
        </React.Fragment>

    )
}


export default QuestionDetail
