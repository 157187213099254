import { OptionsObject, SnackbarKey, SnackbarMessage, VariantType } from "notistack";
import locale from "../languages/locale";
import { SNACKBAR_DEFAULT_DURATION } from "./constants";

export abstract class Utils { 

    static getSnackbarOptions(variant: VariantType, duration?: number): OptionsObject {
        return { 
            anchorOrigin: { horizontal: 'right', vertical: 'top' }, 
            variant,
            autoHideDuration: duration || SNACKBAR_DEFAULT_DURATION
        }
    }

    static responseError(enqueueSnackbar: (message: SnackbarMessage, options?: OptionsObject | undefined) => SnackbarKey): void {
        enqueueSnackbar(locale.errors.generic_try_again,
            this.getSnackbarOptions('error'))
    }

    static isValidEmail(email: string): boolean {
        return (/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/).test(email)
    }

    static getQuestionStatusColor(approved?: boolean) {
        switch (approved) {
            case true:
                return 'success'
            case false:
                return 'secondary'
            default:
                return 'warning'
        }
    }

    static getQuestionStatusLabel(approved?: boolean) {
        switch (approved) {
            case true:
                return locale.shared.approved
            case false:
                return locale.shared.refused
            default:
                return locale.shared.pending
        }
    }

}
