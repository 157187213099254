import React, {useEffect, useState} from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import TableView from '../dashboard/components/TableView';
import UserService from '../../services/User-Service';
import SentInvitesService from '../../services/Invites-Service';
import IUser from '../../models/user-model';
import { PAGINATION_DEFAULT_SIZE } from '../../Utils/constants';
import { Utils } from '../../Utils/utils';
import { useSnackbar } from 'notistack';
import ISorting, { SortOrder } from '../../models/sorting-model';
import Filter, { FilterProps, FilterType } from '../dashboard/components/Filter/Filter';
import { Column } from 'material-table';
import AdvancedSearch from '../dashboard/components/AdvancedSearch/AdvancedSearch';
import locale from '../../languages/locale';
import { FiltersEntries, FiltersOptions, FiltersValues } from '../../models/anagrafica-model';
import ConfirmationModal from '../dashboard/components/ConfirmationModal/ConfirmationModal';
import { AvailableInterfaces } from '../../models/available-interfaces';

function Users() {
    const { enqueueSnackbar } = useSnackbar();
    const [data, setData] = useState<IUser[]>([]);
    const [isLoading, setIsLoading] = useState(true);
    const location = useLocation();
    const history = useHistory();
    const [pageSize, setPageSize] = useState<number>(PAGINATION_DEFAULT_SIZE)
    const [items, setItems] = useState<number>(0)
    const [page, setPage] = useState<number>(0)
    const [from, setFrom] = useState<number>(0)
    const [order, setOrder] = useState<ISorting | undefined>()
    const [filters, setFilters] = useState<{ [key: string]: string | Date | null | undefined }>({})
    const [showModal, setShowModal] = useState<boolean>(false);
    const [deleteId, setDeleteId] = useState<number>(0);
    const [deleteEmail, setDeleteEmail] = useState<string>('');
    const [isInvite, setIsInvite] = useState<boolean>(false);
    const tableKeyField: string = 'consumer_id'

    const onEdit = (rowData: any) => { history.push(`${location.pathname}/${rowData[tableKeyField]}`) };

    const onAdd = () => { history.push(`${location.pathname}/creation`) };

    const onDelete = () => {
        if(isInvite){
            SentInvitesService.deleteInvitation(deleteId, deleteEmail)
                .then(res => {
                    if(res.error) Utils.responseError(enqueueSnackbar);
                    else {
                        enqueueSnackbar('User invite successfully deleted', Utils.getSnackbarOptions('success'));
                        setIsLoading(true);
                        setShowModal(!showModal);
                        setDeleteEmail('');
                        setIsInvite(false);
                    }
                })
        } else {
            UserService.delete(deleteId)
                .then(res => {
                    if(res.error) Utils.responseError(enqueueSnackbar);
                    else {
                        enqueueSnackbar('User successfully deleted!', Utils.getSnackbarOptions('success'));
                        setIsLoading(true);
                        setShowModal(!showModal);
                    }
                })
        }
        setDeleteId(0);
    };
    const toggleModal = (rowData: any) => {
        if(!showModal) {
            if(rowData.invited_user){
                setDeleteId(rowData.invited_user);
                setDeleteEmail(rowData.email);
                setIsInvite(true);
            } else {
                setDeleteId(rowData.user_id);
                setDeleteEmail('');
                setIsInvite(false);
            };
        };
        setShowModal(!showModal);
    };

    const filtersField: FilterProps[] = [
        { id: FiltersEntries.EMAIL, type: FilterType.TEXT, header: locale.table.email },
        { id: FiltersEntries.USERNAME, type: FilterType.TEXT, header: locale.table.username },
        { id: FiltersEntries.ANAGRAFICA, type: FilterType.SELECT,
            selectEntries: [
                {
                    value: FiltersValues.FALSE, label: FiltersOptions.IGNORE_INVITED
                },
                {
                    value: FiltersValues.EMPTY, label: FiltersOptions.INCLUDE_INVITED
                }
            ],
            header: locale.table.anagrafica
        },
        {
            id: FiltersEntries.BLOCKED,
            type: FilterType.SELECT,
            selectEntries: [
                { value: FiltersValues.FALSE, label: FiltersOptions.IGNORE },
                { value: FiltersValues.EMPTY, label: FiltersOptions.INCLUDE}
            ],
            header: locale.table.deleted }

    ]

    const columns: Column<object>[] = [
        {
            title: locale.table.id,
            field: tableKeyField,
            defaultSort: order?.orderBy === 'id' ? order.orderDirection : undefined,
            customSort: () => 0
        },
        {
            title: locale.table.email,
            field: 'email',
            defaultSort: order?.orderBy === 'anagrafica.email' ? order.orderDirection : undefined,
            customSort: () => 0
        },
        {
            title: locale.table.username,
            field: 'username',
            defaultSort: order?.orderBy === 'anagrafica.username' ? order.orderDirection : undefined,
            customSort: () => 0
        },
        {
            title: locale.table.answers,
            field: 'answers',
            sorting: false
        },
    ]

    const getField = (orderBy: number): string => {
        switch (columns[orderBy]?.field) {
            case tableKeyField:
                return 'id'
            case 'email':
                return 'anagrafica.email'
            case 'username':
                return 'anagrafica.username'
            default:
                return columns[orderBy]?.field || ''
        }
    }

    useEffect(() => {
        if (isLoading) {
            UserService.getAll(pageSize, from, order, filters)
                .then(res => {
                    if (res.payload && res.items >= 0) {
                        setData(res.payload)
                        setItems(res.items)
                    }
                    else {
                        setData([])
                        Utils.responseError(enqueueSnackbar)
                    }
                })
                .catch(err => {
                    console.log(err)
                    Utils.responseError(enqueueSnackbar)
                })
                .finally(() => setIsLoading(false))
        }
    }, [filters, from, isLoading, order, pageSize]);

    const onStartPage = (size: number) => {
        setPage(0)
        setFrom(0)
        setPageSize(size)
        setIsLoading(true)
    }

    const onChangePage = (currentPage: number, currentPageSize: number) => {
        setPage(currentPage)
        if (currentPage > 0) {
            setFrom(pageSize * currentPage)
            setIsLoading(true)
        }
        else
            onStartPage(currentPageSize)
    }

    const onOrderChange = (orderBy: number, orderDirection: 'asc' | 'desc') => {
        const direction = orderDirection === 'asc' ? SortOrder.ASC : SortOrder.DESC
        if (orderBy !== -1)
            setOrder({ orderBy: getField(orderBy), orderDirection: direction })
        else
            setOrder(undefined)
        setPage(0)
        setFrom(0)
        setIsLoading(true)
    }

    const onApplyFilters = (filters: { [key: string]: string | Date | null | undefined }) => {
        if (filters[FiltersEntries.ANAGRAFICA] && filters[FiltersEntries.ANAGRAFICA] === FiltersValues.FALSE) {
            setFilters(filters)
        } else if (filters[FiltersEntries.ANAGRAFICA]) {
            delete filters[FiltersEntries.ANAGRAFICA]
        };
        if(filters[FiltersEntries.BLOCKED] && filters[FiltersEntries.BLOCKED] === FiltersValues.FALSE ){
            setFilters(filters)
        } else if(filters[FiltersEntries.BLOCKED]) {
            delete filters[FiltersEntries.BLOCKED]
        };
        setFilters(filters);
        setIsLoading(true);
    }


    return <>
        <ConfirmationModal
            open={showModal}
            itemInterface={AvailableInterfaces.USER}
            onConfirm={onDelete}
            onToggle={toggleModal}
        />
        <AdvancedSearch
            header={locale.shared.search}
            filters={filtersField}
            onSubmit={onApplyFilters}
        />

        <TableView
            tableKeyField={tableKeyField}
            title={locale.shared.users}
            columns={columns}
            data={data}
            items={items}
            page={page}
            currentPageSize={pageSize}
            loading={isLoading}
            onEdit={onEdit}
            onAdd={onAdd}
            onDelete={toggleModal}
            onChangeRowsPerPage={onStartPage}
            onChangePage={onChangePage}
            onOrderChange={onOrderChange}
        />
    </>
}

export default Users;
