import { Mapper } from "./Mapper"
import moment from 'moment';
import ICategory, { ICategoryMapper, IServerCategory, IServerSubCategory, ISubCategory, ISubCategoryMapper } from "./category-model";
import ITransaction, { IServerTransaction, ITransactionMapper } from './transaction-model';
import IClub, { IClubMapper, IServerClub } from "./club-model";

export default interface IQuestion {
    [key: string]: any,
    id: number,
    title: string,
    description: string,
    type: QuestionTypes,
    kind: QuestionKinds,
    leftValue: string,
    rightValue: string,
    midValue?: string, //qualitative only
    publicationDate: Date, // >Now
    endingDate: Date, // >publicationDate
    module?: string, //quantitative [$,€, kg, %]
    category: ICategory,
    category2?: ICategory,
    subCategories?: ISubCategory[],
    subCategories2?: ISubCategory[],
    eventDate?: Date, // standard(>= endingDate)
    totalUsers?: number  //premium
    price?: number  //premium
    status?: QuestionStatus
    transactions?: ITransaction[]
    usersClub?: IClub[],
    usersCategory?: ICategory[],
    priceId?: number
    approved?: boolean | null;
    correctAnswer?: string;
    showAuthor?: boolean;
    //TODO Verify and complete
}

export interface IServerQuestion {
    id: number
    title: string,
    question_text: string,
    category: IServerCategory ,
    category_2: IServerCategory ,
    sub_categories: IServerSubCategory[]
    is_premium: boolean
    approval: string | null,
    approved: boolean | null,
    publication: string,
    expiry: string,
    expected: string,
    effective: string,
    correct_answer_date: string,
    qualitative: boolean,
    far_left: string,
    far_right: string,
    center: string,
    price_paid: number
    total_users: number
    module: string
    transactions: IServerTransaction[]
    users_club?: IServerClub[]
    users_category?: IServerCategory[]
    correct_answer?: string;
    show_name?: boolean
    //TODO Verify and complete
}

export interface IServerRequestQuestion {
    title?: string,
    question_text?: string,
    category?: number,
    category_2?: number,
    sub_categories?: number[],
    is_premium?: boolean
    publication?: string,
    expiry?: string,
    expected?: string,
    qualitative?: boolean,
    far_left?: string,
    far_right?: string,
    center?: string,
    total_users?: number,
    module?: string,
    users_club?: number[]
    users_category?: number[],
    price_paid?: number,
    price_id?: number
    approved?: boolean | null;
    approval?: string | null;
    show_name?: boolean
    //TODO Verify and complete
}

export enum QuestionKinds {
    QUANTITATIVE = 'quantitative',
    QUALITATIVE = 'qualitative'
}

export enum QuestionTypes {
    PREMIUM = 'premium',
    STANDARD = 'standard'
}

export enum QuestionStatus {
    APPROVED = 'approved',
    REFUSED = 'refused',
    PENDING = 'pending'
}

export interface IQuestionFilters {
    [key: string]: string
}

export abstract class IQuestionMapper implements Mapper<IQuestion, IServerQuestion> {
    static map(o: IServerQuestion): IQuestion {
        const object = {} as IQuestion
        //MANDATORY FIELDS
        object.id = o.id;
        object.title = o.title;
        object.description = o.question_text;
        object.type = o.is_premium ? QuestionTypes.PREMIUM : QuestionTypes.STANDARD;
        object.publicationDate = new Date(o.publication);
        object.endingDate = new Date(o.expiry);
        object.kind = o.qualitative ? QuestionKinds.QUALITATIVE : QuestionKinds.QUANTITATIVE;
        object.leftValue = o.far_left;
        object.rightValue = o.far_right;
        object.category = ICategoryMapper.map(o.category);
        object.status = o.approved === null ? QuestionStatus.PENDING : (o.approved ? QuestionStatus.APPROVED : QuestionStatus.REFUSED);
        //OPTIONAL FIELDS
        if(o.category_2 && !!o.category_2.id) {
            object.category2 = ICategoryMapper.map(o.category_2)
        };
        
        if (o.sub_categories) { 
            object.subCategories2 = [];
            const subs = o.sub_categories.filter(entry => entry.category === o.category.id);
            object.subCategories = ISubCategoryMapper.mapArray(subs);
            if(o.category_2) {
                const subsTwo = o.sub_categories.filter(entry => entry.category === o.category_2.id);
                object.subCategories2 = ISubCategoryMapper.mapArray(subsTwo)
            }   
        };
        if(o.correct_answer) {
            object.correctAnswer = o.correct_answer;
        }
        //STANDARD ONLY
        if(o.expected && !o.is_premium){
            object.eventDate = new Date(o.expected)
        };
        //QUALITATIVE ONLY
        if (o.center) {
            object.midValue = o.center
        };
        //QUANTITATIVE ONLY
        if (!o.qualitative){
            object.module = o.module
        };

        object.status = o.approved === null ? QuestionStatus.PENDING : (o.approved ? QuestionStatus.APPROVED : QuestionStatus.REFUSED)

        //PREMIUM ONLY
        if(o.is_premium) {
            if(o.transactions) object.transactions = ITransactionMapper.mapArray(o.transactions);
            if(o.price_paid) object.price = o.price_paid;
            if(o.total_users) object.totalUsers = o.total_users;
            if(o.users_club && o.users_club.length > 0) object.usersClub = IClubMapper.mapArray(o.users_club);
            if(o.users_category && o.users_category.length > 0) object.usersCategory = ICategoryMapper.mapArray(o.users_category);
            object.showAuthor = o.show_name;
        };
        return object
    }

    static mapReverse(o: IQuestion): IServerQuestion {
        const object = {} as IServerQuestion

        object.id = o.id
        object.title = o.title
        object.question_text = o.description
        object.is_premium = o.type === QuestionTypes.PREMIUM
        object.publication = moment(o.publicationDate).toISOString()
        object.expiry = moment(o.endingDate).toISOString()
        object.qualitative = o.kind === QuestionKinds.QUALITATIVE
        object.far_left = o.leftValue
        object.far_right = o.rightValue
        object.category = ICategoryMapper.mapReverse(o.category)
        //OPTIONAL
        if(o.status) {
            const isApproved = o.status === QuestionStatus.PENDING ? null : (o.status === QuestionStatus.APPROVED ? true : false)
            object.approved = isApproved
            object.approval = isApproved !== null ? moment(new Date()).toISOString() : null
        }
        if(o.category2)
            object.category_2 = ICategoryMapper.mapReverse(o.category2)

        if (o.subCategories)
            object.sub_categories = ISubCategoryMapper.mapReverseArray(o.subCategories)
        
        //STANDARD ONLY
        if (o.type === QuestionTypes.STANDARD){
            object.expected = moment(o.eventDate).toISOString()
        }
        //QUALITATIVE ONLY
        if (o.midValue && o.kind === QuestionKinds.QUALITATIVE){
            object.center = o.midValue
        }
        // QUANTITATIVE ONLY
        if (o.module && o.kind === QuestionKinds.QUANTITATIVE){
            object.module = o.module
        }
        // PREMIUM ONLY
        if(o.type === QuestionTypes.PREMIUM){
            if (o.price) object.price_paid = o.price
            if (o.totalUsers) object.total_users = o.totalUsers
            if (o.usersClub) object.users_club = IClubMapper.mapReverseArray(o.usersClub)
            if (o.usersCategory) object.users_category = ICategoryMapper.mapReverseArray(o.usersCategory)
        }

        return object
    }

    static mapRequest(o: IQuestion) : IServerRequestQuestion {
        const object = {} as IServerRequestQuestion
            
            object.title = o.title
            object.question_text = o.description
            object.category = o.category.id
            object.question_text = o.description
            object.is_premium = o.type === QuestionTypes.PREMIUM
            object.publication = moment(o.publicationDate).toISOString()
            object.expiry = moment(o.endingDate).toISOString()
            object.qualitative = o.kind === QuestionKinds.QUALITATIVE
            object.far_left = o.leftValue
            object.far_right = o.rightValue
            //OPTIONAL
            if(o.status) {
                const isApproved = o.status === QuestionStatus.PENDING ? null : (o.status === QuestionStatus.APPROVED ? true : false)
                object.approved = isApproved
                object.approval = isApproved !== null ? moment(new Date()).toISOString() : null
            }
            if(o.category2)  object.category_2 = o.category2.id
            if(o.subCategories || o.subCategories2){
                let subs: number[]= [];
                
                if(o.subCategories){
                    subs = subs.concat(o.subCategories.map((entry: ISubCategory) => entry.id));
                };

                if(o.subCategories2){
                    subs = subs.concat(o.subCategories2.map((entry: ISubCategory) => entry.id));
                };

                if(subs.length > 0) object.sub_categories = subs;
            }
            //STANDARD
            if(o.eventDate && o.type === QuestionTypes.STANDARD)
                object.expected = moment(o.eventDate).toISOString()
            
            //QUALITATIVE
            if(o.midValue && o.kind === QuestionKinds.QUALITATIVE)
                object.center = o.midValue
            //QUANTITATIVE
            if(o.module)
                object.module = o.module
            //PREMIUM
            if(o.priceId)
                object.price_id = o.priceId
            if(o.price)
                object.price_paid = o.price
            if(o.totalUsers)
                object.total_users = o.totalUsers
            
            if(o.usersClub)
                object.users_club = o.usersClub.map(entry => {return entry.id})
            if(o.usersCategory)
                object.users_category = o.usersCategory.map(entry => {return entry.id})
            
            object.show_name = o.showAuthor

        return object
    }

    static mapForUpdate(o: any): IServerRequestQuestion {
        const object = {} as IServerRequestQuestion
            if(o.title) object.title = o.title;
            if(o.description) object.question_text = o.description;
            if(o.category) object.category = o.category.id;
            if(o.category2) object.category_2 = o.category2.id;
            if(o.subCategories || o.subCategories2){
                let subs: number[]= [];
                
                if(o.subCategories){
                    subs = subs.concat(o.subCategories.map((entry: ISubCategory) => entry.id));
                };

                if(o.subCategories2){
                    subs = subs.concat(o.subCategories2.map((entry: ISubCategory) => entry.id));
                };

                if(subs.length > 0) object.sub_categories = subs;
            }
            if(o.type) object.is_premium = o.type === QuestionTypes.PREMIUM
            if(o.publicationDate) object.publication = moment(o.publicationDate).toISOString();
            if(o.endingDate) object.expiry = moment(o.endingDate).toISOString();
            if(o.eventDate) object.expected = moment(o.eventDate).toISOString();
            if(o.kind) object.qualitative = o.kind === QuestionKinds.QUALITATIVE
            if(o.module) object.module = o.module
            if(o.totalUsers) object.total_users = o.totalUsers
            if(o.usersClub) object.users_club = o.usersClub.map((entry: any) => {return entry.id})
            if(o.usersCategory) object.users_category = o.usersCategory.map((entry: any) => {return entry.id})
            if(o.status) {
                switch(o.status){
                    case QuestionStatus.APPROVED: {
                        object.approved = true
                        break
                    }
                    case QuestionStatus.PENDING: {
                        object.approved = null
                        break
                    }
                    default: {
                        object.approved = false
                    }
                }
                object.approval = moment(new Date()).toISOString()
            }
            if(o.priceId) object.price_id = o.priceId;
            if(o.leftValue) object.far_left = o.leftValue;
            if(o.rightValue) object.far_right = o.rightValue;
            if(o.module) object.module = o.module;
            if(o.midValue) object.center = o.midValue;
            object.show_name = o.showAuthor;
        return object
    }

    static mapArray(models: IServerQuestion[]): IQuestion[] {
        return models.map(m => IQuestionMapper.map(m))
    }

    static mapReverseArray(models: IQuestion[]): IServerQuestion[] {
        return models.map(m => IQuestionMapper.mapReverse(m))
    }

}

