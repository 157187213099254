import IAnagrafica from "../models/anagrafica-model";
import { SESSION_STORAGE_JWT_TOKEN } from "../Utils/constants";
import SessionService from "./Session-Service";

const BASE_URL = `${process.env.REACT_APP_BASE_URL}/users`;

class AnagraficaService {
    
    async getOne(id: number): Promise<IAnagrafica> {
        const requestOptions: RequestInit = {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${SessionService.getItem(SESSION_STORAGE_JWT_TOKEN)}`
            },
        }
        return await fetch(`${BASE_URL}/${id}`, requestOptions)
            .then(res => res.json())
    }; 

    update(id: number, data: {}) {
        const requestOptions: RequestInit = {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${SessionService.getItem(SESSION_STORAGE_JWT_TOKEN)}`
            },
            body: JSON.stringify(data)
        }
        return fetch(`${BASE_URL}/${id}`, requestOptions)
            .then(res => res.json())
    };
}

export default new AnagraficaService
