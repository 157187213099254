import ICategory, {ISubCategory, ISubCategoryMapper} from "../models/category-model";
import { SESSION_STORAGE_JWT_TOKEN } from "../Utils/constants";
import SessionService from "./Session-Service";
const BASE_URL = `${process.env.REACT_APP_BASE_URL}/sub-categories`;

class SubcategoryService {

    async getSubcategories(category?: number, secondCategory?: number): Promise <ISubCategory[]> {
        const options: RequestInit = {
            method: "GET",
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${SessionService.getItem(SESSION_STORAGE_JWT_TOKEN)}`
            },
        }
        const urlParams = new URLSearchParams({
            ...category && { "[category]": category.toString() },
            ...secondCategory && { category: secondCategory.toString() }
        })
        return await fetch(`${BASE_URL}?${urlParams}`, options)
            .then(res => res.json())
            .then(res => ISubCategoryMapper.mapArray(res.payload))        
    }
}

export default new SubcategoryService
