import { Mapper } from "./Mapper";
import { Role } from "./user-model";

export default interface IInvitation {
    [key: string]: any
    email: string,
    club?: number
    role?: Role
    newspaper?: string,
    description?: string,
    link?: string,
    clubMember?: boolean,
    phone?: string,
    fileName?: string
    picId?: number
    invited?: boolean
}


export interface IServerInvitation {
    email: string,
    club?: number
    role?: Role
    journal_name?: string,
    description?: string,
    link?: string,
    ifClub?: boolean,
    number_phone?: string,
    profile_pic?: number,
    invited?: boolean
}

export abstract class IInvitationMapper implements Mapper<IInvitation, IServerInvitation> {

    static map(o: IServerInvitation): IInvitation {
        const object = {} as IInvitation
        
        object.email = o.email
        if (o.club)
            object.club = o.club
        if (o.role)
            object.role = o.role
        if (o.journal_name)
            object.newspaper = o.journal_name
        if (o.description)
            object.description = o.description
        if (o.link)
            object.link = o.link
        if (o.ifClub !== undefined)
            object.clubMember = o.ifClub
        if (o.number_phone)
            object.phone = o.number_phone
        if (o.profile_pic)
            object.picId = o.profile_pic
        object.invited = o.invited

        //TODO Verify and complete

        return object
    }

    static mapReverse(o: IInvitation): IServerInvitation {
        const object = {} as IServerInvitation

        object.email = o.email
        object.club = o.club
        object.role = o.role
        object.journal_name = o.newspaper
        object.description = o.description
        object.link = o.link
        object.ifClub = o.clubMember
        object.number_phone = o.phone
        object.profile_pic = o.picId

        //TODO Verify and complete

        return object
    }

    static mapArray(models: IServerInvitation[]): IInvitation[] {
        return models.map(m => IInvitationMapper.map(m))
    }

    static mapReverseArray(models: IInvitation[]): IServerInvitation[] {
        return models.map(m => IInvitationMapper.mapReverse(m))
    }

}
