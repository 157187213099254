import ITransaction from '../models/transaction-model';
import ISorting from '../models/sorting-model';
import { SESSION_STORAGE_JWT_TOKEN } from '../Utils/constants';
import SessionService from './Session-Service';
const BASE_URL = `${process.env.REACT_APP_BASE_URL}/transaction`;

class TransactionService {
    getAll(limit?: number, from?: number, order?: ISorting, filters?: { [key: string]: string | Date | null | undefined }): Promise<any> {
        const requestOptions: RequestInit = {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${SessionService.getItem(SESSION_STORAGE_JWT_TOKEN)}`
            },
        };
        const urlParams = new URLSearchParams({
            ...limit && { _limit: limit.toString() },
            ...from && { _start: from.toString() },
            ...order && { _sort: `${order.orderBy}:${order.orderDirection}` },
            ...filters && { ...filters }
        });
        return fetch(`${BASE_URL}s?${urlParams}`, requestOptions)
            .then(res => res.json())
    }
    //get stripe sessionid
    getSessionId(questionId: string) {
        const requestOptions: RequestInit = {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${SessionService.getItem(SESSION_STORAGE_JWT_TOKEN)}`
            },
        };
        return fetch(`${BASE_URL}/${questionId}`, requestOptions)
            .then(res => res.json())
    }
    //post checkout recording transaction
    postTransaction(checkoutSessionId: string) {
        const requestOptions: RequestInit = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${SessionService.getItem(SESSION_STORAGE_JWT_TOKEN)}`
            },
        };
        return fetch(`${BASE_URL}/${checkoutSessionId}`, requestOptions)
            .then(res => res.json())
    }

    async retrieveSession(sessionId: string): Promise<any> {
        const requestOptions: RequestInit = {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${SessionService.getItem(SESSION_STORAGE_JWT_TOKEN)}`
            },
        }

        return await fetch(`${BASE_URL}/session/${sessionId}`, requestOptions)
            .then(res => res.json())
    }
}

export default new TransactionService