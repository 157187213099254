import React from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, FormHelperText} from '@material-ui/core';
import ArrowBack from '@material-ui/icons/ArrowBack';
import { DeleteForever } from '@material-ui/icons';
import locale from '../../../../languages/locale';

export interface ConfirmationModalProps {
    open: boolean;
    itemInterface: string;
    itemId?: number;
    onConfirm: () => void;
    onToggle: (e: any) => void;
}
const ConfirmationModal = (props: ConfirmationModalProps) => {

    const { open,
            itemInterface,
            itemId,
            onConfirm,
            onToggle,
        } = props;

    return (
        <React.Fragment>
            <Dialog open={open}>
                <DialogTitle> {locale.confirmation_modal.title}</DialogTitle>
                <DialogContent>
                    {locale.confirmation_modal.body} {itemInterface} {locale.confirmation_modal.body_followup}
                    <FormHelperText> {locale.confirmation_modal.body_warning}</FormHelperText>
                </DialogContent>
                <DialogActions>
                    <Button
                        type='button'
                        variant='outlined'
                        startIcon={<ArrowBack />}
                        onClick={onToggle}
                    >
                        {locale.confirmation_modal.button_cancel}
                    </Button>
                    <Button
                        type='button'
                        variant='contained'
                        startIcon={<DeleteForever />}
                        color='primary'
                        onClick={onConfirm}
                    >
                       {locale.confirmation_modal.button_confirm}
                    </Button>
                </DialogActions>
            </Dialog>
        </React.Fragment>
    )
}
export default ConfirmationModal;