import React, {useEffect, useState} from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import TableView from "../components/TableView";
import {Chip} from "@material-ui/core";
import QuestionService from "../../../services/Question-Service";
import IQuestion, { IQuestionFilters, QuestionStatus } from "../../../models/question-model";
import { PAGINATION_DEFAULT_SIZE } from '../../../Utils/constants';
import AdvancedSearch from '../components/AdvancedSearch/AdvancedSearch';
import { FilterProps, FilterType } from '../components/Filter/Filter';
import { Column } from 'material-table';
import ISorting, { SortOrder } from '../../../models/sorting-model';
import { useSnackbar } from 'notistack';
import { Utils } from '../../../Utils/utils';
import moment from 'moment';
import useStyles from "../../dashboard/subs/home/styles";
import locale from '../../../languages/locale';
import { FiltersEntries, FiltersOptions } from '../../../models/anagrafica-model';
import ConfirmationModal from '../components/ConfirmationModal/ConfirmationModal';
import { AvailableInterfaces } from '../../../models/available-interfaces';
import StarRate from '@material-ui/icons/StarRate';

function Questions() {
    const { enqueueSnackbar } = useSnackbar();
    const classes = useStyles();
    const [data, setData] = useState<IQuestion[]>([]);
    const [isLoading, setIsLoading] = useState(true);
    const location = useLocation();
    const history = useHistory();
    const [pageSize, setPageSize] = useState<number>(PAGINATION_DEFAULT_SIZE)
    const [items, setItems] = useState<number>(0)
    const [page, setPage] = useState<number>(0)
    const [from, setFrom] = useState<number>(0)
    const [order, setOrder] = useState<ISorting | undefined>({ orderBy: 'expiry', orderDirection: SortOrder.ASC})
    const [filters, setFilters] = useState<IQuestionFilters>({})
    const [showModal, setShowModal] = useState<boolean>(false);
    const [rowData, setRowData] = useState<any>({});
    const tableKeyField: string = 'id'

    const onEdit = (rowData: any) => { history.push(`${location.pathname}/${rowData[tableKeyField]}`) };
    const onAdd = (e: any) => { history.push(`${location.pathname}/new`)};
    const onDelete = () => {
        //doesn't delete questions that are premium, published or with answers
        if(rowData.answers !== 0
            || moment(rowData.specs.publication).isBefore()
            || rowData.is_premium){
                enqueueSnackbar('You cannot delete published or premium questions', Utils.getSnackbarOptions('error'));
        } else {
            QuestionService.delete(rowData.id)
            .then(res => {
                if(res.error) Utils.responseError(enqueueSnackbar)
                else {
                    enqueueSnackbar('Question successfully deleted!', Utils.getSnackbarOptions('success'));
                    setIsLoading(true);
                    setShowModal(!showModal);
                }
            })
        }
    };
    const toggleModal = (rowData: any) => {
        if(!showModal) setRowData({...rowData});
        setShowModal(!showModal)
    }

    const filtersField: FilterProps[] = [
        { id: FiltersEntries.QUESTION_TITLE , type: FilterType.TEXT, header: locale.filters.title },
        { id: FiltersEntries.QUESTION_NAME, type: FilterType.TEXT, header: locale.filters.company_name },
        { id: FiltersEntries.QUESTION_CLUB, type: FilterType.TEXT, header: locale.filters.club },
        {
            id: FiltersEntries.QUESTION_STATUS,
            type: FilterType.SELECT,
            header: locale.filters.status,
            selectEntries: [
                {value: QuestionStatus.PENDING, label: FiltersOptions.PENDING},
                {value: QuestionStatus.APPROVED, label: FiltersOptions.APPROVED},
                {value: QuestionStatus.REFUSED, label: FiltersOptions.REFUSED}
            ]},
        { id: FiltersEntries.QUESTION_EXPIRY_FROM, type: FilterType.CALENDAR, header: locale.filters.expiry_from },
        { id: FiltersEntries.QUESTION_EXPIRY_TO, type: FilterType.CALENDAR, header: locale.filters.expiry_to },
        { id: FiltersEntries.QUESTION_PUBBLICATION_FROM, type: FilterType.CALENDAR, header: locale.filters.pubblication_from },
        { id: FiltersEntries.QUESTION_PUBLICATION_TO, type: FilterType.CALENDAR, header: locale.filters.pubblication_to },
        { id: FiltersEntries.QUESTION_EXPECTED_FROM, type: FilterType.CALENDAR, header: locale.filters.event_from },
        { id: FiltersEntries.QUESTION_EXPECTED_TO, type: FilterType.CALENDAR, header: locale.filters.event_to }
    ]

    const columns: Column<object>[] = [
        {
            render: (rowData: any) =>
                rowData.is_premium ? <StarRate color='secondary' /> : <></>,
            cellStyle: { paddingTop: 0, paddingBottom: 0, width: '5%' },
            sorting: false
        },
        {
            title: locale.table.id,
            field: tableKeyField,
            defaultSort: order?.orderBy === tableKeyField ? order.orderDirection : undefined,
            customSort: () => 0
        },
        {
            title: locale.table.title,
            field: 'title',
            defaultSort: order?.orderBy === 'title' ? order.orderDirection : undefined,
            customSort: () => 0
        },
        {
            title: locale.table.author,
            field: 'author',
            defaultSort: order?.orderBy === 'author' ? order.orderDirection : undefined,
            customSort: () => 0
        },
        {
            title: locale.table.category,
            field: 'category',
            defaultSort: order?.orderBy === 'category' ? order.orderDirection : undefined,
            customSort: () => 0
        },
        {
            title: locale.table.pubblication_date,
            field: 'specs.publication',
            type: 'date',
            defaultSort: order?.orderBy === 'publication' ? order.orderDirection : undefined,
            customSort: () => 0
        },
        {
            title: locale.table.expiry_date,
            field: 'specs.expiry',
            type: 'date',
            defaultSort: order?.orderBy === 'expiry' ? order.orderDirection : undefined,
            customSort: () => 0
        },
        {
            title: locale.table.event_date,
            field: 'specs.expected',
            type: 'date',
            defaultSort: order?.orderBy === 'expected' ? order.orderDirection : undefined,
            customSort: () => 0
        },
        {
            title: locale.table.status,
            render: (rowData: any) => {
                const questionStatus = rowData.specs?.approved
                return <Chip
                    label={Utils.getQuestionStatusLabel(questionStatus)}
                    classes={{ root: classes[Utils.getQuestionStatusColor(questionStatus)] }}
                />
            },
            cellStyle: { paddingTop: 0, paddingBottom: 0 },
            sorting: false
        }
    ]

    const getField = (orderBy: number): string => {
        switch (columns[orderBy]?.field) {
            case 'specs.expiry':
                return 'expiry'
            case 'specs.expected':
                return 'expected'
            case 'specs.publication':
                return 'publication'
            default:
                return columns[orderBy]?.field || ''
        }
    }

    useEffect(() => {
        if (isLoading) {
            QuestionService.getAll(pageSize, from, order, filters)
                .then(res => {
                    if (res.payload && res.items >= 0) {
                        setData(res.payload)
                        setItems(res.items)
                    }
                    else {
                        setData([])
                        Utils.responseError(enqueueSnackbar)
                    }
                })
                .catch(err => {
                    console.log(err)
                    Utils.responseError(enqueueSnackbar)
                })
                .finally(() => setIsLoading(false))
        }
    }, [isLoading, pageSize, from, order, filters]);

    const onStartPage = (size: number) => {
        setPage(0)
        setFrom(0)
        setPageSize(size)
        setIsLoading(true)
    }

    const onChangePage = (currentPage: number, currentPageSize: number) => {
        setPage(currentPage)
        if (currentPage > 0) {
            setFrom(pageSize * currentPage)
            setIsLoading(true)
        }
        else
            onStartPage(currentPageSize)
    }

    const onOrderChange = (orderBy: number, orderDirection: "asc" | "desc") => {
        const direction = orderDirection === "asc" ? SortOrder.ASC : SortOrder.DESC
        if (orderBy !== -1)
            setOrder({orderBy: getField(orderBy), orderDirection: direction})
        else
            setOrder(undefined)
        setPage(0)
        setFrom(0)
        setIsLoading(true)
    }

    const isDate = (filter?: string | Date | null | undefined): boolean => {
        return filter instanceof Date
    }

    const dateToString = (filter: string | Date | null | undefined, startOf?: boolean): string => {
        return startOf ? moment(filter).startOf('day').toISOString() :
            moment(filter).endOf('day').toISOString()
    }

    const getStringFilter = (k: keyof IQuestionFilters, filter?: string | Date | null | undefined) => {
        if (typeof filter === 'string')
            return { [k] : filter}
        return
    }

    const getStatusFilter = (filter?: string | Date | null | undefined) => {
        if (typeof filter === 'string') {
            const key = filter === QuestionStatus.APPROVED || filter === QuestionStatus.REFUSED ?
                'approved' : 'approved_null'
            const value = filter === QuestionStatus.APPROVED || filter === QuestionStatus.PENDING ?
                'true' : 'false'

            return { [key]: value }
        }

        return
    }

    const onApplyFilters = (filters: { [key: string]: string | Date | null | undefined }) => {
        const f: IQuestionFilters = {
            ...getStringFilter( 'title_contains', filters.title ),
            ...getStringFilter( 'company.name_contains', filters.name ),
            ...getStringFilter( 'journalist.club.name_contains', filters.club ),
            ...getStatusFilter(filters.status),
            ...isDate(filters?.expiryFrom) && { expiry_gte: dateToString(filters.expiryFrom, true) },
            ...isDate(filters?.expiryTo) && { expiry_lte: dateToString(filters.expiryTo) },
            ...isDate(filters?.publicationFrom) && { publication_gte: dateToString(filters.publicationFrom, true) },
            ...isDate(filters?.publicationTo) && { publication_lte: dateToString(filters.publicationTo) },
            ...isDate(filters?.expectedFrom) && { expected_gte: dateToString(filters.expectedFrom, true) },
            ...isDate(filters?.expectedTo) && { expected_lte: dateToString(filters.expectedTo) }
        }
        setFilters(f)
        setIsLoading(true)
    }

    return <>
        <ConfirmationModal
            open={showModal}
            itemInterface={AvailableInterfaces.QUESTION}
            onConfirm={onDelete}
            onToggle={toggleModal}
        />
        <AdvancedSearch
            header={locale.shared.search}
            filters={filtersField}
            onSubmit={onApplyFilters}
        />

        <TableView
            tableKeyField={tableKeyField}
            title={locale.shared.questions}
            columns={columns}
            data={data}
            items={items}
            page={page}
            currentPageSize={pageSize}
            loading={isLoading}
            onEdit={onEdit}
            onAdd={onAdd}
            onDelete={toggleModal}
            onChangeRowsPerPage={onStartPage}
            onChangePage={onChangePage}
            onOrderChange={onOrderChange}
        />
    </>
}

export default Questions;
