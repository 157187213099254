import React from "react";
import { makeStyles, useTheme } from "@material-ui/styles";
import classnames from "classnames";
import { Theme } from "@material-ui/core";
import { BaseColor } from "../BaseColor";

// styles
var useStyles = makeStyles((theme: Theme) => ({
  dotBase: {
    width: 8,
    height: 8,
    backgroundColor: theme.palette.text.hint,
    borderRadius: "50%",
    transition: theme.transitions.create("background-color"),
  },
  dotSmall: {
    width: 5,
    height: 5
  },
  dotLarge: {
    width: 11,
    height: 11,
  },
}));

export enum DotSize {
  LARGE = 'large',
  SMALL = 'small'
}

export interface DotProps {
  size?: DotSize
  color?: BaseColor
}

const Dot = (props: DotProps) => {
  var classes = useStyles();
  var theme: Theme = useTheme();

  const { size, color} = props

  return (
    <div
      className={classnames(classes.dotBase, {
        [classes.dotLarge]: size === "large",
        [classes.dotSmall]: size === "small",
      })}
      style={{
        backgroundColor:
          color && theme.palette[color] && theme.palette[color].main,
      }}
    />
  );
}

export default Dot;
