import React, { useEffect, useState } from 'react';
import "./create-premium.css";
import {
    Card,
    CardHeader, 
    CardContent, 
    FormGroup,
    TextField,
    CardActions,
    Button,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogContentText,
    DialogActions,
    Box,
    CircularProgress,
    Grid,
} from '@material-ui/core';
import SendIcon from '@material-ui/icons/Send';
import UndoIcon from '@material-ui/icons/Undo';
import IInvitation from '../../../models/invitation-model';
import InvitationService from '../../../services/Invitation-Service';
import { Role } from '../../../models/user-model';
import Autocomplete, {createFilterOptions } from '@material-ui/lab/Autocomplete';
import { Utils } from '../../../Utils/utils';
import { useSnackbar } from 'notistack';
import InvitesOutcomeTable from '../../dashboard/components/InvitesOutcomeTable/InvitesOutcomeTable';

const emailListOption: string[] = [];
const filter = createFilterOptions<string>();

function PremiumCreation() {
    const { enqueueSnackbar } = useSnackbar();
    const [isLoading, setIsLoading] = useState(false)
    const [userConfirmation, setUserConfirmation] = useState(false);
    const [showDialog, setShowDialog] = useState(false);
    const [email, setEmail] = useState<string[]>([])
    const [invitesOutcome, setInvitesOutcome] = useState<IInvitation[]>([])

    //POST REQ
    useEffect(() => {
        if(userConfirmation) {
            setIsLoading(true);
            setUserConfirmation(false);
            setShowDialog(false);
            setInvitesOutcome([]);
            const invitation: IInvitation[] = [];
            email.map(e => 
                invitation.push({
                    email: e,
                    role: Role.PREMIUM
                })
            )
            InvitationService.sendInvitation(invitation)
                .then(res => {
                    resetInvitation()
                    setInvitesOutcome(res);
                })
                .catch(err => {
                    console.log(err)
                    Utils.responseError(enqueueSnackbar)
                })
                .finally(() => setIsLoading(false))
        }
    },[email, userConfirmation, isLoading]);

    const resetInvitation = () => {
        setEmail([]);
        setInvitesOutcome([])
    }

    const emailValidation = !email.every(e => Utils.isValidEmail(e))

    return (
        <form>
            <Dialog
                open={showDialog}
            >
                <DialogTitle> Confirm </DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Are you sure you want to send an invitation?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={() => setShowDialog(false)}
                    > 
                    Back 
                    </Button>
                    <Button
                        color="primary"
                        onClick={() => setUserConfirmation(true)}
                        autoFocus
                    >
                    Confirm
                    </Button>
                </DialogActions>
            </Dialog>
            <Card>
                <CardHeader
                    title={"Invite Premium Users"}
                />
                <CardContent>
                    <FormGroup>
                        <Autocomplete
                            id="multiple-email-tags"
                            value={email}
                            onChange={(event, newValue) => {
                                setEmail(newValue);
                            }}
                            filterOptions={(options, params) => {
                                const filtered = filter(options, params);

                                if(params.inputValue !== '') {
                                    filtered.push(params.inputValue)
                                }

                                return filtered;
                            }}
                            options={emailListOption}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    id="email"
                                    name="email"
                                    label="Email"
                                    type="email"
                                    variant="outlined"
                                    InputLabelProps={{ "shrink": true}}
                                    margin="normal"
                                    multiline={true}
                                    error={emailValidation}
                                    helperText={emailValidation && 'Incorrect entry'}
                                />
                            )}
                            multiple
                            selectOnFocus
                            clearOnBlur
                            handleHomeEndKeys
                            freeSolo
                        />
                        {/* club */}
                    </FormGroup>
                </CardContent>
                <CardActions>
                    <Button
                        type="button"
                        variant="outlined"
                        size="medium"
                        startIcon={<UndoIcon />}
                        onClick={resetInvitation}
                        disabled={isLoading}
                    >
                        Reset
                    </Button>
                    <Button
                        type="button"
                        variant="contained"
                        color="primary"
                        size="medium"
                        startIcon={<SendIcon />}
                        onClick={() => setShowDialog(true)}
                        disabled={emailValidation || !email.length || isLoading}
                    >
                        Send
                    </Button>
                    {isLoading &&
                        <Box
                            display={'flex'}
                            justifyContent={'center'}
                            alignItems={'center'}
                            paddingLeft={'0.5rem'}
                        >
                            <CircularProgress size={'1.2rem'} />
                        </Box>
                    }
                </CardActions>
            </Card>
            {invitesOutcome.length ?
                <Grid
                    className="create-premium__outcome"
                    container
                    spacing={4}
                >
                    <Grid item xs={12}>
                        <InvitesOutcomeTable
                            data={invitesOutcome}
                        />
                    </Grid>
                </Grid>
                :
                <></>
            }
        </form>
    );
}

export default PremiumCreation;