import React from 'react';
import MaterialTable, {Action, Column, MaterialTableProps} from "material-table";
import { PAGINATION_DEFAULT_SIZE, PAGINATION_SIZE_OPTIONS } from '../../../Utils/constants';
import locale from '../../../languages/locale';

export interface TableViewProps extends MaterialTableProps<object> {
    tableKeyField: string;
    title?: string
    items?: number
    page?: number
    currentPageSize?: number
    loading?: boolean
    actions?: Action<object>[]
    onEdit?: (rowData: any) => void
    onAdd?: (e: any) => void,
    onDelete?: (rowData: any) => void
    onChangeRowsPerPage?: (pageSize: number) => void
    onChangePage?: (page: number, pageSize: number) => void
    onOrderChange?: (orderBy: number, orderDirection: "asc" | "desc") => void;
}

const TableView = (props: TableViewProps) => {

    const {
        tableKeyField,
        title,
        columns = [],
        data = [],
        items,
        page,
        currentPageSize,
        loading = false,
        actions,
        options,
        onEdit,
        onAdd,
        onDelete,
        onChangeRowsPerPage,
        onChangePage,
        onOrderChange,
        ...otherProps
    } = props;

    const getActions = (): (Action<any> | ((rowData: any) => Action<any>))[]  => {
        if (actions) return actions;
        return [
            ...data.length !== 0 ? [(rowData: any) => ({
                icon: 'drive_file_rename_outline',
                tooltip: locale.shared.edit,
                onClick: (e: any, rowData: any) => { onEdit && onEdit(rowData) },
                disabled: rowData.is_deleted || !rowData[tableKeyField]
            }),
            (rowData: any) => ({
                icon: 'delete_outline',
                tooltip: locale.shared.delete,
                onClick: (e:any, rowData: any) => { onDelete && onDelete(rowData) },
                disabled: rowData.is_deleted || false
            })] : [],
            {
                icon: "add_box",
                tooltip: locale.shared.add,
                position: "toolbar",
                onClick: (e) => { onAdd && onAdd(e) },
            },
        ]
    }

    return (
        <MaterialTable
            title={title}
            columns={columns}
            data={data}
            isLoading={loading}
            actions={getActions()}
            options={{
                pageSize: currentPageSize || PAGINATION_DEFAULT_SIZE,
                pageSizeOptions: PAGINATION_SIZE_OPTIONS,
                emptyRowsWhenPaging: false,
                actionsColumnIndex: -1,
                search: false,
                ...options
            }}
            onChangePage={onChangePage}
            onChangeRowsPerPage={onChangeRowsPerPage}
            totalCount={items}
            page={page}
            onOrderChange={onOrderChange}
            localization={locale.table.table_localization}
            {...otherProps}
        />
    )
}

export default TableView;
