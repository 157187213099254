import { SESSION_STORAGE_JWT_TOKEN } from "../Utils/constants";
import SessionService from "./Session-Service";
const BASE_URL = `${process.env.REACT_APP_BASE_URL}/upload`;

class UploadService {

    async add(file: File) {
        const form = new FormData();
        form.append("files", file);
        const requestOptions: RequestInit = {
            method: 'POST',
            headers: {
                'Authorization': `Bearer ${SessionService.getItem(SESSION_STORAGE_JWT_TOKEN)}`
            },
            body: form
        }
        return await fetch(`${BASE_URL}`, requestOptions)
            .then(res => res.json())
    }

    getPics(caption: string){
        const requestOptions: RequestInit = {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${SessionService.getItem(SESSION_STORAGE_JWT_TOKEN)}`
            }
        }
        const urlParams = new URLSearchParams({
            ...caption && {caption: caption}
        })
        return fetch(`${BASE_URL}/files?${urlParams}`, requestOptions)
            .then(res => res.json())
    }
}

export default new UploadService
