import { useSnackbar } from 'notistack';
import React, { useState, useEffect } from 'react';
import IInvitation from '../../../models/invitation-model';
import { Role } from '../../../models/user-model';
import InvitationService from '../../../services/Invitation-Service';
import UploadService from '../../../services/Upload-Service';
import { Utils } from '../../../Utils/utils';
import CustomForm, { DataType, FieldsProps, FormType } from '../../dashboard/subs/question-form/customForm';
import './create-journalist.css';

function CreateJournalist() {
    const { enqueueSnackbar } = useSnackbar();
    const [data, setData] = useState<IInvitation>({
        email: '',
        newspaper: '',
        description: '',
        link: '',
        clubMember: false,
        phone: '',
        fileName: ''
    });
    const [fields, setFields] = useState<FieldsProps[]>([
        {
            required: true,
            formType: FormType.INPUT,
            id: 'email',
            dataType: DataType.STRING,
            label: 'Email',
            error: false,
            helperText: 'Please provide a valid email',
            position: 0,
            touched: false,
            show: true
        },
        {
            required: true,
            formType: FormType.INPUT,
            id: 'newspaper',
            dataType: DataType.STRING,
            label: 'Newspaper',
            error: false,
            helperText: 'Please provide the name of the newspaper',
            position: 1,
            touched: false,
            show: true
        },
        {
            required: true,
            formType: FormType.INPUT,
            id: 'description',
            dataType: DataType.STRING,
            label: 'Description',
            error: false,
            helperText: 'Please provide a description',
            position: 2,
            touched: false,
            show: true
        },
        {
            required: false,
            formType: FormType.INPUT,
            id: 'link',
            dataType: DataType.STRING,
            label: 'Link',
            error: false,
            helperText: 'Please provide the newspaper link',
            position: 3,
            touched: false,
            show: true
        },
        {
            required: false,
            formType: FormType.INPUT,
            id: 'phone',
            dataType: DataType.NUMBER,
            label: 'Phone',
            error: false,
            helperText: 'Please provide a phone',
            position: 4,
            touched: false,
            show: true
        },
        {
            required: false,
            formType: FormType.FILEUPLOAD,
            id: 'fileName',
            dataType: DataType.FILE,
            label: 'Profile pic',
            error: false,
            helperText: 'Please select a profile pic',
            position: 5,
            touched: false,
            show: true
        },
        {
            required: false,
            formType: FormType.UPLOAD_PREVIEW,
            disabled: true,
            id: 'filePreview',
            dataType: DataType.FILE,
            label: 'Profile pic',
            error: false,
            position: 6,
            touched: false,
            show: true,
            customClass: 'create-journalist__upload-preview',
            link: ''
        },
        {
            required: false,
            formType: FormType.CHECKBOX,
            id: 'clubMember',
            dataType: DataType.BOOLEAN,
            label: 'Club',
            error: false,
            position: 7,
            touched: true,
            show: true
        },
    ]);
    const [backupData, setBackupData] = useState<IInvitation>({ ...data });
    const [disableButton, setDisableButton] = useState(true);
    const [showDialog, setShowDialog] = useState(false);
    const [file, setFile] = useState<File>();
    const [isLoading, setIsLoading] = useState(false);

    //open recap dialog
    const toggleDialog = (e: any) => {
        const newValue = !showDialog;
        setShowDialog(newValue);
    }

    //submit journalist
    const onSave = (e: any, imageInputRef?: any) => {
        setIsLoading(true)
        const journalist = [{...data}]
        journalist[0].role = Role.JOURNALIST
        InvitationService.sendInvitation(journalist)
            .then(res => {
                if (res[0]?.invited) {
                    enqueueSnackbar('Invitation sent!',
                        Utils.getSnackbarOptions('success'))
                    onReset(imageInputRef)
                } else
                    enqueueSnackbar('Invitation not sent!',
                        Utils.getSnackbarOptions('error'))
            })
            .catch(err => {
                console.log(err);
                Utils.responseError(enqueueSnackbar)
            })
            .finally(() => {
                setIsLoading(false)
                setShowDialog(false);
            })
    };

    //reset journalist data and fields props
    const onReset = (imageInputRef?: any) => {
        const newFields = fields.map(entry => {
            return {
                ...entry,
                error: false,
                touched: entry.id !== 'clubMember' ? 
                    false : entry.touched,
            }
        })
        setFields([...newFields]);
        setData(backupData);
        if (imageInputRef)
            imageInputRef.current.value = ''
        setFile(undefined);
    };

    //all-round change handler
    const handleChange = (e: any, newValue?: any[], elementKey?: string) => {
        if(e.target.files){
            const file: File = (e.target.files as FileList)[0]
            setFile(file);
        } else {
            const key = e.target.name || elementKey;
            const inputValue = key === 'clubMember' ? 
                e.target.checked : e.target.value || newValue || '';

            setData({
                ...data,
                [key]: inputValue
            });
        }
    };

    //set FieldsProps error
    const handleValidation = (element: FieldsProps) => {
        switch (element.id) {
            case 'email': {
                const newFields = [...fields]
                const value = data[element.id];
                const userInput: boolean = value === '' || !Utils.isValidEmail(value)
                const newInput = { ...element, error: (element.required && userInput), touched: !userInput };
                newFields[element.position] = newInput;
                setFields(newFields)
                break
            }
            case 'clubMember': {
                const newFields = [...fields]
                const value = data[element.id];
                const newInput = { ...element, error: (element.required && !value), touched: !!value };
                newFields[element.position] = newInput;
                setFields(newFields)
                break
            }
            default: {
                const newFields = [...fields]
                const value = data[element.id];
                const userInput: boolean = value === '' || value?.length === 0
                const newInput = { ...element, error: (element.required && userInput), touched: !userInput };
                newFields[element.position] = newInput;
                setFields(newFields)
                break
            }
        }
    };

    const generatePreview = (url: string) => {
        const newFields = [...fields];
        const preview = newFields.find(field => field.id === 'filePreview');
        preview!.link = url;
        setFields([...newFields]);
    };

    //check if there are any missing fields
    useEffect(() => {
        const missingFields = !fields.filter(key => key.required).every(key => key.touched && !key.error);
        setDisableButton(missingFields);
    }, [fields])

    //upload profile pic
    useEffect(() => {
        if(file){
            setIsLoading(true)
            UploadService.add(file)
                .then(res => {
                    setData({
                        ...data,
                        picId: res[0]?.id,
                        fileName: res[0]?.name
                    })
                    setIsLoading(false);
                    generatePreview(res[0].url)
                })
                .catch(err => {
                    console.log(err);
                    Utils.responseError(enqueueSnackbar)
                    setIsLoading(false)
                });
        }
    }, [file]);

    return (
        <React.Fragment>
            <CustomForm
                formInterface='Journalist'
                data={data}
                fields={fields}
                disableButton={disableButton}
                toggleDialog={toggleDialog}
                showDialog={showDialog}
                title='Invite journalist'
                isLoading={isLoading}
                onSave={onSave}
                onReset={onReset}
                handleChange={handleChange}
                handleValidation={handleValidation}
            />
        </React.Fragment>
    )
}

export default CreateJournalist;
