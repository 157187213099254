import IAuth from "../models/auth-model";
import ILogin from "../models/login-model";
const BASE_URL = `${process.env.REACT_APP_BASE_URL}/admin/login`;

class AuthService {

    async login(login: ILogin): Promise<IAuth> {
        const requestOptions: RequestInit = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(login)
        }

        return await fetch(BASE_URL, requestOptions)
            .then(res => res.json())
    }

}

export default new AuthService
