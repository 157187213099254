import React from 'react';
import "./filter.css"
import DateFnsUtils from '@date-io/date-fns';
import TextField from '@material-ui/core/TextField';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import NumberFormatCustom from './components/NumberFormatCustom/NumberFormatCustom';
import { MenuItem } from '@material-ui/core';
import locale from '../../../../languages/locale';
import { FiltersOptions } from '../../../../models/anagrafica-model';

export enum FilterType {
    TEXT = 'TEXT',
    CALENDAR = 'CALENDAR',
    NUMBER = 'NUMBER',
    SELECT = 'SELECT'
}

export interface FilterProps {
    id: string
    type: FilterType
    header?: string
    value?: string | Date | null
    selectEntries?: {value: string, label: FiltersOptions}[]
    onChange?: (id: string, value: string | Date | null | undefined) => void
}

const Filter = (props: FilterProps) => {

    const {
        id,
        type,
        header,
        value,
        selectEntries,
        onChange
    } = props;
    
    const handleDateChange = (date: Date | null) => {
        onChange?.(id, date ? date : null)
    };

    const handleChange = (value: string) => onChange?.(id, value)

    switch (type) {
        case FilterType.TEXT:
            return <TextField
                className="filter-text"
                id={`text-field-${id}`}
                name={header}
                label={header}
                margin="normal"
                multiline={true}
                value={value}
                onChange={(e: any) => handleChange(e.target.value)}
            />
        case FilterType.CALENDAR:
            return <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <KeyboardDatePicker
                    className="filter-calendar"
                    disableToolbar
                    variant="inline"
                    format="dd/MM/yyyy"
                    margin="normal"
                    id={`date-picker-${id}`}
                    label={header}
                    value={value}
                    onChange={handleDateChange}
                    KeyboardButtonProps={{
                        'aria-label': 'change date',
                    }}
                />
            </MuiPickersUtilsProvider>
        case FilterType.NUMBER:
            return <TextField
                className="filter-number"
                id={`number-field-${id}`}
                name={header}
                label={header}
                margin="normal"
                multiline={true}
                value={value}
                onChange={(e: any) => handleChange(e.target.value)}
                InputProps={{
                    inputComponent: NumberFormatCustom as any,
                }}
            />
        case FilterType.SELECT:
            return <TextField
                className="filter-select"
                id={`select-field-${id}`}
                name={header}
                label={header}
                margin="normal"
                value={value}
                onChange={(e: any) => handleChange(e.target.value)}
                select
            >
                {selectEntries?.map((entry: {value: string, label: FiltersOptions}, index: number) => 
                    <MenuItem 
                        key={`select-item-${index}`} 
                        value={entry.value}
                    >
                           {`${locale.filters[entry.label]}`}
                    </MenuItem>)}
            </TextField>
    }
}

export default Filter;
