import { Chip } from "@material-ui/core";
import MaterialTable, { Column } from "material-table";
import locale from "../../../../languages/locale";
import IInvitation from "../../../../models/invitation-model";
import useStyles from "../../subs/home/styles";

export interface InvitesOutcomeTableProps {
    data: IInvitation[]
}

function InvitesOutcomeTable({ data }: InvitesOutcomeTableProps) {
    const classes = useStyles()

    const columns: Column<object>[] = [
        {
            title: locale.table.email,
            field: 'email',
        },
        {
            title: locale.table.outcome,
            render: (rowData: any) => {
                const invited = rowData.invited
                return <Chip
                    label={invited ? locale.table.sent : locale.table.not_sent}
                    classes={{ root: classes[invited ? 'success' : 'secondary'] }}
                />
            },
            cellStyle: { paddingTop: 0, paddingBottom: 0 }
        }
    ]

    return <>
        <MaterialTable
            title={locale.table.invited_users}
            columns={columns}
            data={data}
            options={{
                search: false,
                sorting: false,
                paging: false
            }}
        />
    </>
}

export default InvitesOutcomeTable;
