class SessionService {

    setItem(key: string, value: string, local?: boolean): void {
        return this.storage(local).setItem(key, value);
    }

    getItem(key: string): string | null {
        return this.storage().getItem(key);
    }

    exists(key: string): boolean {
        return !!this.storage().getItem(key);
    }

    clear() {
        localStorage.clear();
        sessionStorage.clear();
    }

    private storage(isLocal?: boolean): typeof localStorage | typeof sessionStorage {
        
        if(typeof isLocal === 'boolean')
            return isLocal ? localStorage : sessionStorage;

        return localStorage.length ? localStorage : sessionStorage;
    }

}

export default new SessionService
