import { loadStripe } from "@stripe/stripe-js";

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_TOKEN!);

class PaymentService {

    async redirectToCheckout(sessionId: string): Promise<any> {

        const stripe = await stripePromise;
        if(stripe) return stripe.redirectToCheckout({sessionId: `${sessionId}`}) 
    }

}

export default new PaymentService
