import IInvitation, { IInvitationMapper } from "../models/invitation-model";
import { SESSION_STORAGE_JWT_TOKEN } from "../Utils/constants";
import SessionService from "./Session-Service";
const BASE_URL = `${process.env.REACT_APP_BASE_URL}/user/invite`;

class InvitationService {

    async sendInvitation(invitation: IInvitation[]): Promise<IInvitation[]> {
        const requestOptions: RequestInit = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${SessionService.getItem(SESSION_STORAGE_JWT_TOKEN)}`
            },
            body: JSON.stringify(IInvitationMapper.mapReverseArray(invitation))
        }

        return await fetch(BASE_URL, requestOptions)
            .then(res => res.json())
            .then(res => IInvitationMapper.mapArray(res.payload))
    }

}

export default new InvitationService
