import React, {useState, useEffect} from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import CssBaseline from '@material-ui/core/CssBaseline';
import AppBar from '@material-ui/core/AppBar';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import { Box, Typography, useMediaQuery } from "@material-ui/core";
import SearchBar from "../search-bar/SearchBar";
import { Redirect, Route, Switch, useHistory, useLocation} from "react-router";
import { NavLink } from 'react-router-dom';
import Questions from "./subs/Questions";
import QuestionsDetail from "./subs/question-detail/question-detail";
import Settings from "../settings/Settings";
import Users from "../users/Users";
import UserDetail from '../users/user-detail/user-detail';
import Account from "../profile";
import Journalist from "../journalist/Journalist";
import JournalistDetail from "../journalist/journalist-detail/journalist-detail";
import Premium from "../premium/Premium";
import PremiumDetail from '../premium/premium-detail/Premium-detail';
import CreateQuestion from './subs/question-form/create-question';
import UsersCreation from '../users/users-creation/UsersCreation';
import CreatePremium from '../premium/premium-form/create-premium';
import { SESSION_STORAGE_JWT_TOKEN } from '../../Utils/constants';
import CreateJournalist from '../journalist/create-journalist/create-journalist';
import Home from './subs/home/Home';
import SessionService from '../../services/Session-Service';
import locale from '../../languages/locale';
import { logo } from '../../assets/images';
import { IconButton } from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';
const drawerWidth = 240;

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            display: 'flex',
        },
        appBar: {
            width: `calc(100% - ${drawerWidth}px)`,
            marginLeft: drawerWidth,
            backgroundColor: theme.palette.background.paper,
            borderBottom: '1px solid #d2d2d2',
        },
        appBarMobile: {
            display: 'flex',
            backgroundColor: theme.palette.background.paper,
            borderBottom: '1px solid #d2d2d2',
            alignContent: 'left'
        },
        appBarTitle: {
            color: theme.palette.text.primary,
        },
        drawer: {
            width: drawerWidth,
            flexShrink: 0,
        },
        drawerPaper: {
            width: drawerWidth,
        },
        // necessary for content to be below app bar
        toolbar: theme.mixins.toolbar,
        content: {
            flexGrow: 1,
            backgroundColor: theme.palette.background.default,
            padding: theme.spacing(3),
        },
        link: {
            color: theme.palette.text.primary,
            textDecoration: 'none',
            display: 'block',
            marginTop: '0.2rem',
            marginLeft: '-0.8rem'
        },
        mobileLogo: {
            maxWidth: '300px',
            marginLeft: '20px'

        }
    }),
);

export default function Dashboard() {
    const classes = useStyles();
    const history = useHistory();
    const [drawerVisibility, setDrawerVisibility] = useState<boolean>(false);
    const location = useLocation();
    const logout = () => {
        SessionService.clear();
        history.push('/')
    }
    function MediaQuery() {
        if (useMediaQuery('(min-width:700px)'))
        return (
            <>
                <AppBar position="fixed" className={classes.appBar} elevation={0}>
                    <SearchBar />
                </AppBar>
                {drawerContent}
            </>
            )
        else return mobileDrawerContent
    };
    function toggleMobileDrawer(): void {
        setDrawerVisibility(!drawerVisibility);
    }

    const drawerList = (
        <List>
            <ListItem selected={history.location.pathname == "/dashboard"}>
                <ListItemIcon><span className="material-icons">home</span></ListItemIcon>
                <ListItemText><NavLink to={'/dashboard'} className={classes.link}><Typography
                    variant={"overline"}>{locale.shared.home}</Typography></NavLink></ListItemText>
            </ListItem>
            <ListItem selected={history.location.pathname.includes("/dashboard/questions")}>
                <ListItemIcon><span className="material-icons">question_answer</span></ListItemIcon>
                <ListItemText><NavLink to={'/dashboard/questions'} className={classes.link}><Typography
                    variant={"overline"}>{locale.shared.questions}</Typography></NavLink></ListItemText>
            </ListItem>
            <ListItem selected={history.location.pathname.includes("/dashboard/users")}>
                <ListItemIcon><span className="material-icons">person</span></ListItemIcon>
                <ListItemText><NavLink to={'/dashboard/users'} className={classes.link}><Typography
                    variant={"overline"}>{locale.shared.users}</Typography></NavLink></ListItemText>
            </ListItem>
            <ListItem selected={history.location.pathname.includes("/dashboard/journalist")}>
                <ListItemIcon><span className="material-icons">how_to_reg</span></ListItemIcon>
                <ListItemText><NavLink to={'/dashboard/journalist'} className={classes.link}><Typography
                    variant={"overline"}>{locale.shared.journalist}</Typography></NavLink></ListItemText>
            </ListItem>
            <ListItem selected={history.location.pathname.includes("/dashboard/premium")}>
                <ListItemIcon><span className="material-icons">star</span></ListItemIcon>
                <ListItemText><NavLink to={'/dashboard/premium'} className={classes.link}><Typography
                    variant={"overline"}>{locale.shared.premium}</Typography></NavLink></ListItemText>
            </ListItem>
            <Divider />
            <ListItem>
                <ListItemIcon><span className="material-icons">exit_to_app</span></ListItemIcon>
                <ListItemText><NavLink to={'#'} onClick={logout} className={classes.link}><Typography
                    variant={"overline"}>{locale.shared.logout}</Typography></NavLink></ListItemText>
            </ListItem>
        </List>
    )
    
    const drawerContent = (
        <>
            <Drawer
                className={classes.drawer}
                variant="permanent"
                classes={{
                    paper: classes.drawerPaper,
                }}
                anchor="left"
            >
                <Box display={'flex'} justifyContent={'center'} alignItems={'center'} height={'4rem'}>
                    <img width={'80%'} height={'auto'} src={logo}
                        alt={'Icon'} />
                </Box>
                <Divider />
                {drawerList}
            </Drawer>
        </>
    )
    const mobileDrawerContent = (
        <>      
        <AppBar  className={classes.appBarMobile} elevation={0}>
            <div className={classes.root}>
                <IconButton
                    onClick={toggleMobileDrawer}
                > 
                    <MenuIcon /> 
                </IconButton>
                <Box display={'flex'} justifyContent={'center'} alignItems={'center'} height={'4rem'}>
                    <img className={classes.mobileLogo} src={logo} alt={'Icon'} />
                </Box>
            </div>
        </AppBar>
        <Drawer 
            variant='temporary'
            anchor='left'
            open={drawerVisibility}
            onBackdropClick={toggleMobileDrawer}
        >
            {drawerList}
        </Drawer>
        </>
    );
    useEffect(() => {
        if(drawerVisibility) setDrawerVisibility(!drawerVisibility);
    },[location])

    return (
        <div className={classes.root}>
            <CssBaseline />
            {MediaQuery()}
            <Box className={classes.content}>
                <div className={classes.toolbar} />
                {SessionService.getItem(SESSION_STORAGE_JWT_TOKEN) ?
                    <Switch>
                        <Redirect from="/:url*(/+)" to={history?.location?.pathname.slice(0, -1)} />
                        <Route exact path={'/dashboard/questions/new'} component={CreateQuestion} />
                        <Route path={'/dashboard/questions/:id'} component={QuestionsDetail} />
                        <Route path={'/dashboard/questions'} component={Questions} />
                        <Route path={'/dashboard/users/creation'} component={UsersCreation} />
                        <Route path={`/dashboard/users/:id`} component={UserDetail} />
                        <Route path={'/dashboard/users'} component={Users} />
                        <Route exact path={'/dashboard/journalist/new'} component={CreateJournalist} />
                        <Route path={'/dashboard/questions/:id'} component={QuestionsDetail} />
                        <Route path={'/dashboard/journalist/:id'} component={JournalistDetail} />
                        <Route path={'/dashboard/journalist'} component={Journalist} />
                        <Route exact path={'/dashboard/premium/new'} component={CreatePremium} />
                        <Route path={'/dashboard/premium/:id'} component={PremiumDetail} />
                        <Route path={'/dashboard/premium'} component={Premium} />
                        <Route path={'/dashboard/profile'} component={Account} />
                        <Route path={'/dashboard/settings'} component={Settings} />
                        <Route path={'/dashboard'} component={Home} />
                    </Switch> :
                    <Redirect to={{ pathname: "/" }} />
                }
            </Box>
        </div>
    );
}
