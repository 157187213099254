import { Mapper } from './Mapper';
import moment from 'moment';
import IAnagrafica from "./anagrafica-model";
const BASE_URL = `${process.env.REACT_APP_BASE_URL}`;

export default interface IJournalist {
    [key: string] : any
    id: number,
    phone: string,
    submitted?: number,
    userEngaged?: number,
    userAnswered?: number,
    lastQuestion?: Date,
    club?: {id: number, name: string, journalist: number},
    journal?: string,
    link?: string,
    description?: string,
    profilePic?: string
    user: IAnagrafica
    userId: number,
    username: string,
    email: string,
    picId?: number,
    questions?: number,
    approvedQuestions?: number
}

export interface IServerJournalist {
    [key: string] : any,
    id: number,
    phone_number: string,
    questions_submitted: number,
    avg_user_engaged: number,
    avg_user_answered: number,
    last_question: string,
    club: {id: number, name: string, journalist: number},
    journal_name: string,
    link: string,
    description: string,
    profile_pic: any,
    anagrafica: IAnagrafica,
    questions: number,
    approved_questions: number
    
}

export abstract class IJournalistMapper implements Mapper<IJournalist, IServerJournalist> {

    static map(o: IServerJournalist): IJournalist {
        const object = {} as IJournalist

        object.id = o.id
        object.phone = o.phone_number
        object.submitted = o.questions_submitted
        object.userEngaged = o.avg_user_engaged
        object.userAnswered = o.avg_user_answered
        if (o.last_question)
            object.lastQuestion = new Date(o.last_question)
        object.club = o.club
        object.journal = o.journal_name
        object.link = o.link
        object.description = o.description
        object.questions = o.questions
        object.user = o.anagrafica
        object.userId = o.anagrafica.id 
        object.username = o.anagrafica.username
        object.email = o.anagrafica.email
        if(o.profile_pic && o.profile_pic.thumbnail){
            object.profilePic = o.profile_pic.thumbnail.url
        }
        object.approvedQuestions = o.approved_questions
        
        return object
    }

    static mapReverse(o: IJournalist): IServerJournalist {
        const object = {} as IServerJournalist
        object.id = o.id
        object.phone_number = o.phone
        if(o.journal)
            object.journal_name = o.journal 
        if(o.link)
            object.link = o.link
        if(o.description)
            object.description = o.description
        if(o.picId)
            object.profile_pic = o.picId;
        return object
    }

    static mapArray(models: IServerJournalist[]): IJournalist[] {
        return models.map(m => IJournalistMapper.map(m))
    }

    static mapReverseArray(models: IJournalist[]): IServerJournalist[] {
        return models.map(m => IJournalistMapper.mapReverse(m))
    }
}