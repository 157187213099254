import React, { useState, useEffect } from 'react';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Link from '@material-ui/core/Link';
import Paper from '@material-ui/core/Paper';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import { NavLink, useHistory } from 'react-router-dom';
import AuthService from '../../services/Auth-Service';
import { CircularProgress } from '@material-ui/core';
import ILogin from '../../models/login-model';
import { SESSION_STORAGE_JWT_TOKEN } from '../../Utils/constants';
import { useSnackbar } from 'notistack';
import { Utils } from '../../Utils/utils';
import SessionService from '../../services/Session-Service';
import { logo } from '../../assets/images';
import './Signin.css';

function Copyright() {
    return (
        <Typography variant="body2" color="textSecondary" align="center">
            {'Copyright © '}
            <Link color="inherit" href="https://www.rightrend.com/it/">
                Rightrend
            </Link>{' '}
            {new Date().getFullYear()}
            {'.'}
        </Typography>
    );
}

const useStyles = makeStyles((theme) => ({
    root: {
        height: '100vh',
    },
    image: {
        backgroundImage: 'none',
        backgroundRepeat: 'no-repeat',
        backgroundColor:
            theme.palette.type === 'light' ? theme.palette.grey[50] : theme.palette.grey[900],
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        borderRight: '1px solid #d2d2d2',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    },
    paper: {
        margin: theme.spacing(8, 4),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main,
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
    nav_link: {
        color: 'white',
        textDecoration: 'none',
        width: '100%',
        height: '100%',
    },
    sign_up: {
        textDecoration: 'none',
        color: theme.palette.primary.main,
        '&:hover': {
            textDecoration: 'underline',
        }
    }
}));

export default function SignIn() {
    const [isLogged, setIsLogged] = useState<boolean>(false);
    const classes = useStyles();
    const history = useHistory();
    const { enqueueSnackbar } = useSnackbar();
    
    const [isLoading, setIsLoading] = useState(false)
    const [error, setError] = useState('')
    const [remember, setRemember] = useState(false)

    const [login, setLogin] = useState<ILogin>({
        email: '',
        password: ''
    });

    const handleChangeEmail = (e: any) => {
        setError('')
        setLogin({
            ...login,
            email: e.target.value
        })
    }

    const handleChangePassword = (e: any) => {
        setError('')
        setLogin({
            ...login,
            password: e.target.value
        })
    }

    const handleChangeRemember = (e: any) => {
        setRemember(e.target.checked);
    }


    useEffect(() => {
        const logData = SessionService.getItem(SESSION_STORAGE_JWT_TOKEN);
        if(logData) setIsLogged(true);
        if(isLogged) history.push('/dashboard')
    },[isLogged])

    useEffect(() => {
        if (isLoading){
            AuthService.login(login)
                .then(response => {
                    if (response.data?.token) {
                        SessionService.setItem(SESSION_STORAGE_JWT_TOKEN, 
                            response.data.token, remember);
                        history.push('/dashboard')
                    } else if (response.message) {
                        setError(response.message)
                        setIsLoading(false)
                    }
                })
                .catch(e => {
                    console.log(e)
                    Utils.responseError(enqueueSnackbar)
                    setIsLoading(false)
                })
            }
    }, [history, isLoading, login]);


    return (
        <Grid container component="main" className={classes.root}>
            <CssBaseline />
            <Grid item xs={false} sm={4} md={7} className={classes.image}>
                    <img src={logo} className='signin__logo'/>
            </Grid>
            <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
                <div className={classes.paper}>
                    <Avatar className={classes.avatar}>
                        <span className="material-icons">lock</span>
                    </Avatar>
                    <Typography component="h1" variant="h5">
                        Sign in
                    </Typography>
                    <form className={classes.form} noValidate>
                        <TextField
                            variant="outlined"
                            margin="normal"
                            required
                            fullWidth
                            id="email"
                            label="Email Address"
                            name="email"
                            autoComplete="email"
                            autoFocus
                            onChange={handleChangeEmail}
                            error={!!error}
                        />
                        <TextField
                            variant="outlined"
                            margin="normal"
                            required
                            fullWidth
                            name="password"
                            label="Password"
                            type="password"
                            id="password"
                            autoComplete="current-password"
                            onChange={handleChangePassword}
                            error={!!error}
                            helperText={error}
                        />
                        <FormControlLabel
                            control={<Checkbox color="primary" onChange={handleChangeRemember} />}
                            label="Remember me"
                        />
                        {isLoading ?
                            <Box
                                display={'flex'}
                                justifyContent={'center'}
                                alignItems={'center'}
                                height={'50%'}
                                margin={'1rem 0'}
                            >
                                <CircularProgress/>
                            </Box> :
                            <Button
                                fullWidth
                                variant="contained"
                                color="primary"
                                className={classes.submit}
                                onClick={() => setIsLoading(true)}
                            >
                                Sign In
                            </Button>
                        }
                        {/* <Grid container>
                            <Grid item xs>
                                <Link href="#" variant="body2">
                                    Forgot password?
                                </Link>
                            </Grid>
                            <Grid item>
                                <NavLink to={'/sign-up'} className={classes.sign_up}>{"Don't have an account? Sign Up"}</NavLink>
                            </Grid>
                        </Grid> */}
                        <Box mt={5}>
                            <Copyright />
                        </Box>
                    </form>
                </div>
            </Grid>
        </Grid>
    );
}
