import React, {useEffect, useState} from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import TableView from "../dashboard/components/TableView";
import JournalistService from "../../services/Journalist-Service";
import SentInvitesService from '../../services/Invites-Service';
import IJournalist from "../../models/journalist-model"
import { PAGINATION_DEFAULT_SIZE } from '../../Utils/constants';
import { useSnackbar } from 'notistack';
import { Utils } from '../../Utils/utils';
import ISorting, { SortOrder } from '../../models/sorting-model';
import { FilterProps, FilterType } from '../dashboard/components/Filter/Filter';
import { Column } from 'material-table';
import AdvancedSearch from '../dashboard/components/AdvancedSearch/AdvancedSearch';
import locale from '../../languages/locale';
import { FiltersEntries, AnagraficaFilter, FiltersValues, FiltersOptions} from '../../models/anagrafica-model';
import ConfirmationModal from '../dashboard/components/ConfirmationModal/ConfirmationModal';
import { AvailableInterfaces } from '../../models/available-interfaces';

function Journalist() {
    const { enqueueSnackbar } = useSnackbar();
    const [data, setData] = useState<IJournalist[]>([]);
    const [isLoading, setIsLoading] = useState(true);
    const location = useLocation();
    const history = useHistory();
    const [pageSize, setPageSize] = useState<number>(PAGINATION_DEFAULT_SIZE)
    const [items, setItems] = useState<number>(0)
    const [page, setPage] = useState<number>(0)
    const [from, setFrom] = useState<number>(0)
    const [order, setOrder] = useState<ISorting | undefined>()
    const [filters, setFilters] = useState<{ [key: string]: string | Date | null | undefined }>({})
    const [showModal, setShowModal] = useState<boolean>(false);
    const [isInvite, setIsInvite] = useState<boolean>(false); 
    const [deleteId, setDeleteId] = useState<number>(0);
    const [deleteEmail, setDeleteEmail] = useState<string>('');
    const tableKeyField: string = 'journalist_id'

    const onEdit = (rowData: any) => { history.push(`${location.pathname}/${rowData[tableKeyField]}`) };
    const onAdd = (e: any) => { history.push(`${location.pathname}/new`) };
    const onDelete = () => {
        if(isInvite){
            SentInvitesService.deleteInvitation(deleteId, deleteEmail)
                .then(res => {
                    if(res.error) Utils.responseError(enqueueSnackbar);
                    else {
                        enqueueSnackbar('Invite successfully deleted!', Utils.getSnackbarOptions('success'));
                        setIsLoading(true);
                        setShowModal(!showModal);
                        setIsInvite(false);
                        setDeleteEmail('');
                        setDeleteId(0);
                    }
                })
        } else {
            JournalistService.delete(deleteId)
                .then(res => {
                    if(res.error) Utils.responseError(enqueueSnackbar);
                    else {
                        enqueueSnackbar('User successfully deleted!', Utils.getSnackbarOptions('success'));
                        setIsLoading(true);
                        setShowModal(!showModal);
                        setDeleteId(0);
                    }
                });
        }
    };
    const toggleModal = (rowData: any) => {
        if(!showModal) {
            if(rowData.invited_user){
                setDeleteId(rowData.invited_user);
                setDeleteEmail(rowData.email);
                setIsInvite(true);
            } else {
                setDeleteId(rowData.user_id);
                setDeleteEmail('');
                setIsInvite(false);
            }
        };
        setShowModal(!showModal);
    };

    const filtersField: FilterProps[] = [
        { id: FiltersEntries.EMAIL, type: FilterType.TEXT, header: locale.table.email },
        { id: FiltersEntries.JOURNAL_NAME, type: FilterType.TEXT, header: locale.table.journal },
        { id: FiltersEntries.ANAGRAFICA, type: FilterType.SELECT, 
            selectEntries: [
                {
                    value: FiltersValues.FALSE, label: FiltersOptions.IGNORE_INVITED
                },
                { 
                    value: FiltersValues.EMPTY, label: FiltersOptions.INCLUDE_INVITED
                }
            ],
            header: locale.table.anagrafica
        },
        {id: FiltersEntries.BLOCKED, type: FilterType.SELECT,
            selectEntries: [
                {
                    value: FiltersValues.FALSE, label: FiltersOptions.IGNORE
                },
                {
                    value: FiltersValues.EMPTY, label: FiltersOptions.INCLUDE
                }
            ],
            header: locale.table.deleted
        }
    ]

    const columns: Column<object>[] = [
        {
            title: locale.table.id,
            field: tableKeyField,
            defaultSort: order?.orderBy === 'id' ? order.orderDirection : undefined,
            customSort: () => 0
        },
        {
            title: locale.table.email,
            field: 'email',
            defaultSort: order?.orderBy === 'email' ? order.orderDirection : undefined,
            customSort: () => 0
        },
        {
            title: locale.table.journal,
            field: 'journal_name',
            defaultSort: order?.orderBy === 'journal_name' ? order.orderDirection : undefined,
            customSort: () => 0
        },
        {
            title: locale.table.phone,
            field: 'number_phone',
            defaultSort: order?.orderBy === 'number_phone' ? order.orderDirection : undefined,
            customSort: () => 0
        },
    ]

    const getField = (orderBy: number): string => {
        switch (columns[orderBy]?.field) {
            case tableKeyField: {
                return 'id'
            }
            case 'phone_number': {
                return 'number_phone'
            }
            default:
                return columns[orderBy]?.field || ''
        }
    }

    useEffect(() => {
        if (isLoading) {
            JournalistService.getAll(pageSize, from, order, filters)
                .then(res => {
                    if (res.payload && res.items >= 0) {
                        setData(res.payload)
                        setItems(res.items)
                    } else {
                        setData([])
                        Utils.responseError(enqueueSnackbar)
                    }
                })
                .catch(err => {
                    console.log(err)
                    Utils.responseError(enqueueSnackbar)
                })
                .finally(() => setIsLoading(false))
        }
    }, [isLoading, pageSize, from, order, filters]);

    const onStartPage = (size: number) => {
        setPage(0)
        setFrom(0)
        setPageSize(size)
        setIsLoading(true)
    }

    const onChangePage = (currentPage: number, currentPageSize: number) => {
        setPage(currentPage)
        if (currentPage > 0) {
            setFrom(pageSize * currentPage)
            setIsLoading(true)
        }
        else
            onStartPage(currentPageSize)
    }

    const onOrderChange = (orderBy: number, orderDirection: "asc" | "desc") => {
        const direction = orderDirection === "asc" ? SortOrder.ASC : SortOrder.DESC
        if (orderBy !== -1)
            setOrder({ orderBy: getField(orderBy), orderDirection: direction });
        else
            setOrder(undefined);
        setPage(0);
        setFrom(0);
        setIsLoading(true);
    }

    const onApplyFilters = (filters: { [key: string]: string | Date | null | undefined }) => {
        if(filters[FiltersEntries.ANAGRAFICA] && filters[FiltersEntries.ANAGRAFICA] === FiltersValues.FALSE){
            setFilters(filters)
        } else if(filters[FiltersEntries.ANAGRAFICA]){
            delete filters[FiltersEntries.ANAGRAFICA]
        };
        if(filters[FiltersEntries.BLOCKED] && filters[FiltersEntries.BLOCKED] === FiltersValues.FALSE){
            setFilters(filters)
        } else if(filters[FiltersEntries.BLOCKED]){
            delete filters[FiltersEntries.BLOCKED]
        };
        setFilters(filters);
        setIsLoading(true);
    }

    return <>
        <ConfirmationModal
            open={showModal}
            itemInterface={AvailableInterfaces.JOURNALIST}
            onConfirm={onDelete}
            onToggle={toggleModal}
        />

        <AdvancedSearch
            header={locale.shared.search}
            filters={filtersField}
            onSubmit={onApplyFilters}
        />
        <TableView
            tableKeyField={tableKeyField}
            title={locale.shared.journalist}
            columns={columns}
            data={data}
            items={items}
            page={page}
            currentPageSize={pageSize}
            loading={isLoading}
            onEdit={onEdit}
            onAdd={onAdd}
            onDelete={toggleModal}
            onChangeRowsPerPage={onStartPage}
            onChangePage={onChangePage}
            onOrderChange={onOrderChange}
        />
    </>
}

export default Journalist;
