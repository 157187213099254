import {Mapper} from "./Mapper";
import IAnagrafica from "./anagrafica-model";
import IClub, { IServerClub, IClubMapper } from "./club-model";

export default interface IUser {
    [key: string] : any;
    id: number;
    username: string;
    email: string;
    challenges?: number;
    nonUserChallenged?: number;
    userChallenged?: number;
    challengesReceived?: number;
    challengesWon?: number;
    challengesMatched?: number;
    challengesLost?: number;
    pointsEarned?: number;
    pointsSpent?: number;
    balance?: number;
    lastAnswer?: Date;
    club?: IClub[];
    profilePic?: string;
    questionAmount?: number; 
    answerAmount?: number; 
    anagrafica: IAnagrafica;
    clubArray?:number[]
};

export interface IServerUser {
    [key: string] : any;
    id: number;
    potential_user: number;
    new_user: number;
    challenges: number;
    non_user_challenged: number;
    user_challenged: number;
    challenges_received: number;
    challenges_won: number;
    challenges_matched: number;
    challenges_lost: number;
    points_earned: number;
    points_spent: number;
    balance: number;
    last_answer: string;
    profile_pic: {
        large: ServerPicture | null; 
        medium: ServerPicture | null;
        original: ServerPicture | null;
        small: ServerPicture | null;
        thumbnail: ServerPicture | null;
    };
    questions: number; //check
    answers: number; //check
    club: IServerClub[]; //check
    anagrafica: IAnagrafica
};

export interface ServerPicture {
    url?: string;
    width?: number;
    height?: number;
}

export interface IRequestUser {
    id: number;
    club: number[];
    profile_pic: number
}

export enum Role {
    CONSUMER = 'consumer',
    JOURNALIST = 'journalist',
    PREMIUM = 'premium'
};

export abstract class IUserMapper implements Mapper<IUser, IServerUser>{

    static map(o:IServerUser) : IUser {
        const object = {} as IUser
        object.id = o.id
        object.username = o.anagrafica.username
        object.email = o.anagrafica.email
        object.challenges = o.challenges
        object.nonUserChallenged = o.non_user_challenged
        object.userChallenged = o.user_challenged
        object.challengesReceived = o.challenges_received
        object.challengesWon = o.challenges_won
        object.challengesMatched = o.challenges_matched
        object.challengesLost = o.challenges_lost
        object.pointsEarned = o.points_earned
        object.pointsSpent = o.points_spent
        object.balance = o.balance
        object.lastAnswer = new Date(o.last_answer)
        object.club = IClubMapper.mapArray(o.club) 
        object.profilePic = o.profile_pic.thumbnail?.url;
        object.questionAmount = o.questions
        object.answerAmount = o.answers
        object.anagrafica = o.anagrafica

        return object
    };


    static mapReverse(o: IUser) : IRequestUser {
        const object = {} as IRequestUser
        
        object.id = o.id 
        if(o.club)
            object.club = o.club.map(entry => {return entry.id})
        if(o.profilePic)
            object.profile_pic = parseInt(o.profilePic)
        return object
    };

    static mapArray(models: IServerUser[]): IUser[]{
        return models.map(m => IUserMapper.map(m))
    };
    
    static mapReverseArray(models: IUser[]): IRequestUser[]{
        return models.map(m => IUserMapper.mapReverse(m))
    }
}
