import { Mapper } from "./Mapper";

export default interface ICategory {
    id: number
    value: string
    subCategories?: ISubCategory[]
}

export interface IServerCategory {
    id: number
    name: string
    sub_categories?: IServerSubCategory[]
}

export abstract class ICategoryMapper implements Mapper<ICategory, IServerCategory> {

    static map(o: IServerCategory): ICategory {
        const object = {} as ICategory

        object.id = o.id
        object.value = o.name
        if (o.sub_categories)
            object.subCategories = ISubCategoryMapper.mapArray(o.sub_categories)

        return object
    }

    static mapReverse(o: ICategory): IServerCategory {
        const object = {} as IServerCategory

        object.id = o.id
        object.name = o.value
        if (o.subCategories)
            object.sub_categories = ISubCategoryMapper.mapReverseArray(o.subCategories)

        return object
    }

    static mapArray(models: IServerCategory[]): ICategory[] {
        return models.map(m => ICategoryMapper.map(m))
    }

    static mapReverseArray(models: ICategory[]): IServerCategory[] {
        return models.map(m => ICategoryMapper.mapReverse(m))
    }

}

export interface ISubCategory {
    id: number
    value: string
}

export interface IServerSubCategory {
    category?: number
    id: number
    name: string
}

export abstract class ISubCategoryMapper implements Mapper<ISubCategory, IServerSubCategory> {

    static map(o: IServerSubCategory): ISubCategory {
        const object = {} as ISubCategory

        object.id = o.id
        object.value = o.name

        return object
    }

    static mapReverse(o: ISubCategory): IServerSubCategory {
        const object = {} as IServerSubCategory

        object.id = o.id
        object.name = o.value

        return object
    }

    static mapArray(models: IServerSubCategory[]): ISubCategory[] {
        return models.map(m => ISubCategoryMapper.map(m))
    }

    static mapReverseArray(models: ISubCategory[]): IServerSubCategory[] {
        return models.map(m => ISubCategoryMapper.mapReverse(m))
    }

}
