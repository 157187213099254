import React, { useState, useEffect } from 'react';
import './Premium-detail.css'
import {useHistory} from 'react-router-dom';
import IPremium from "../../../models/premium-model";
import PremiumService from "../../../services/Premium-Service";
import AnagraficaService from "../../../services/Anagrafica-Service";
import { useParams } from 'react-router-dom';
import CustomForm, { FormType, DataType, FieldsProps} from '../../../pages/dashboard/subs/question-form/customForm';
import Tab from '@material-ui/core/Tab';
import TabContext from '@material-ui/lab/TabContext';
import TabList from '@material-ui/lab/TabList';
import TabPanel from '@material-ui/lab/TabPanel';
import Paper from '@material-ui/core/Paper';
import { useSnackbar } from 'notistack';
import { Utils } from '../../../Utils/utils';
import { List, ListItem, ListItemIcon, ListItemText } from '@material-ui/core';
import DateRangeIcon from '@material-ui/icons/DateRange';
import GroupIcon from '@material-ui/icons/Group';
import RateReviewIcon from '@material-ui/icons/RateReview';
import PaymentIcon from '@material-ui/icons/Payment';
import ReceiptIcon from '@material-ui/icons/Receipt';
import PremiumQuestions from "./premium-questions";
import PremiumTransactions from './premium-transactions';
import { AvailableInterfaces } from '../../../models/available-interfaces';
import QuestionAnswerIcon from '@material-ui/icons/QuestionAnswer';
import ThumbUpIcon from '@material-ui/icons/ThumbUp';

function PremiumDetail() {
    const { enqueueSnackbar } = useSnackbar();
    const [isLoading, setIsLoading] = useState(true);
    const [initCheck, setInitCheck] = useState(false);
    const [showDialog, setShowDialog] = useState(false);
    const [disableButton, setDisableButton] = useState(true);
    const [tabValue, setTabValue] = useState('1');
    const history = useHistory();
    let params: any = useParams();
    
    const [currentPremium, setCurrent] = useState<IPremium>({
        id: 0,
        piva: '',
        phone: 0,
        office: '',
        questionAmount: 0,
        lastQuestion: new Date(),
        userEngaged: 0,
        userAnswered: 0,
        paid: 0,
        name: '',
        reference: '',
        username: '',
        email: '',
        user: {id: 0, email: '', username: ''},
        approvedQuestions: 0
    });

    const [backupPremium, setBackup] = useState<IPremium>({
        id: 0,
        piva: '',
        phone: 0,
        office: '',
        questionAmount: 0,
        lastQuestion: new Date(),
        userEngaged: 0,
        userAnswered: 0,
        paid: 0,
        name: '',
        reference: '',
        username: '',
        email: '',
        user: {id: 0, email: '', username: ''},
        approvedQuestions: 0
    });
    const [fields, setFields] = useState<FieldsProps[]>([
        {
            required: false,
            formType: FormType.INPUT,
            id: "name",
            dataType: DataType.STRING,
            label: "Company Name",
            error: false,
            position: 0,
            touched: false,
            show: true,
            customClass: 'premium-detail__field'
        },
        {
            required: false,
            formType: FormType.INPUT,
            id: "office",
            dataType: DataType.STRING,
            label: "Registered Office",
            error: false,
            position: 1,
            touched: false,
            show: true,
            customClass: 'premium-detail__field'
        },
        {
            required: true,
            formType: FormType.INPUT,
            id: "piva",
            dataType: DataType.STRING,
            label: "Vat Number",
            error: false,
            helperText: "Please enter a valid VAT number",
            position: 2,
            touched: true,
            show: true,
            customClass: 'premium-detail__field'
        },
        {
            required: false,
            formType: FormType.INPUT,
            id: "reference",
            dataType: DataType.STRING,
            label: "Company Reference",
            error: false,
            position: 3,
            touched: false,
            show: true,
            customClass: 'premium-detail__field'
        },
        {
            required: true,
            formType: FormType.INPUT,
            id: "email",
            dataType: DataType.STRING,
            label:"E-Mail",
            error: false,
            position: 4,
            touched: false,
            show: true,
            customClass: 'premium-detail__field'
        },
        {
            required: true,
            formType: FormType.INPUT,
            id: "phone",
            dataType: DataType.NUMBER,
            label: "Phone Number",
            error: false,
            helperText: "Please enter a phone number",
            position: 5,
            touched: true,
            show: true,
            customClass: 'premium-detail__field'
        },
        {
            required: true,
            formType: FormType.INPUT,
            id: "username",
            dataType: DataType.STRING,
            label: "Username",
            error: false,
            position: 6,
            touched: false,
            show: true,
            customClass: 'premium-detail__field'
        },
    ]); 
    const [stats, setStats] = useState<any[]> ([
        {
            id: 'questionAmount',
            label: 'Amount of submitted questions',
            icon: <RateReviewIcon/>
        },
        {
            id: 'transactionAmount',
            label: 'Amount of transactions',
            icon: <ReceiptIcon/>
        },
        {
            id: 'lastQuestion',
            label: 'Date of last submitted question',
            icon: <DateRangeIcon/>
        },
        {
            id: 'userEngaged',
            label: 'Amount of engaged users',
            icon: <GroupIcon/>
        },
        {
            id: 'paid',
            label: 'Payments total',
            icon: <PaymentIcon/>
        },
        {
            id: 'userAnswered',
            label: 'Number of answers',
            icon: <QuestionAnswerIcon />
        },
        {
            id: 'approvedQuestions',
            label: 'Number of approved questions',
            icon: <ThumbUpIcon />
        }
    ])
    //INIT GET
    useEffect(() => {
        if(isLoading) {
            PremiumService.getOne(params.id)
                .then(res => {  
                    setCurrent(res);
                    setBackup({...res});
                })
                .then(() => setIsLoading(false))
        };
        setInitCheck(true);
    }, [isLoading]);
    //UPDATE FIELDS TOUCHED STATE ACCORDING TO SERVER RESPONSE
    useEffect(() => {
        if(initCheck){
            fields.map(entry => {
                if(currentPremium[entry.id] !== undefined || currentPremium[entry.id] !== null){
                    var newFields = [...fields];
                    newFields[entry.position].touched = true;
                    setFields([
                        ...newFields
                    ]);
                };
            })
            setInitCheck(false)
        }
    }, [initCheck])
     // CHANGE HANDLER
     const handleChange = (e: any) => {
        const newValue = e.target.value;
        const key = e.target.id;
        if(key === 'username' || key === 'email'){
            setCurrent({
                ...currentPremium,
                [key]: newValue,
                user: {...currentPremium.user, [key]: newValue}
            })
        }else{
            setCurrent({
                ...currentPremium,
                [key]: newValue
            });
        }
    };
    //PUT REQUEST
    const onSave = (e: any) => {
        PremiumService.update(currentPremium.id, currentPremium)
            .then(res => {
                enqueueSnackbar('Premium successfully updated!',
                    Utils.getSnackbarOptions('success'))
            })
            .catch(err => {
                console.log('incoming error ', err);
                Utils.responseError(enqueueSnackbar)
            });
        AnagraficaService.update(currentPremium.user.id, currentPremium.user)
            .then(res => console.log(res))
            .catch(err => console.log(err))
        setBackup(currentPremium);
        setShowDialog(false);
    };
    //UNDO CHANGES
    const onReset = () => {
        const newFields = fields.map(entry => {
            return {
                ...entry,
                error: false,
                touched: entry.required
            }
        })
        setFields([...newFields]);
        setCurrent(backupPremium);
        setInitCheck(true);
    };
    //VALIDATE ON INPUT
    const handleValidation = (element: FieldsProps) => {
        const newFields = [...fields]
        const value = currentPremium[element.id];
        const userInput: boolean = value === '' || value.length === 0
        const newInput = { ...element, error: (element.required && userInput), touched: !userInput };
        newFields[element.position] = newInput;
        setFields(newFields)
    };
    //CHECK MISSING FIELDS && ENABLE SUBMIT BUTTON
    useEffect(()=>{
        if(fields){
            const missingFields = !fields.filter(key => key.required).every(key => key.touched && !key.error);
            setDisableButton(missingFields);
            }
    }, [fields]);
    //SHOW HIDE DIALOG
    const toggleDialog = (e: any) => {
        const newValue = !showDialog;
        setShowDialog(newValue);
    };
    //TABS NAVIGATION
    const handleTabs = (e:any, value: string) => {
        setTabValue(value);
    
    };
    return (
        <React.Fragment>   
                <TabContext value={tabValue} key="mui-tab-navigations">
                    <Paper className='premium-detail__navbar'>
                        <TabList centered={true} indicatorColor="primary" onChange={handleTabs} variant="standard">
                            <Tab label="Company" value="1" key="first-tab"></Tab>
                            <Tab label="Questions" value="2" key="second-tab"></Tab>
                            <Tab label="Transactions" value="3" key="third-tab"></Tab>
                            <Tab label="Statistics" value="4" key="fourth-tab"></Tab>
                        </TabList>
                    </Paper>
                <TabPanel 
                    value="1" 
                    key="premium-form-tab"
                >
                    <CustomForm
                        customClassName="premium-detail"
                        formInterface={AvailableInterfaces.PREMIUM}
                        data={currentPremium}
                        fields={fields}
                        disableButton={disableButton}
                        toggleDialog={toggleDialog}
                        showDialog={showDialog}
                        alertElement={false}
                        title='Edit a Premium'
                        onSave={onSave}
                        onReset={onReset}
                        handleChange={handleChange}
                        handleValidation={handleValidation}
                    />
                </TabPanel>
                <TabPanel 
                    value="2" 
                    key="premium-question-tab"
                >
                   <PremiumQuestions
                        key={`${currentPremium.id}-premium-questions`}
                        id={currentPremium.id.toString()}
                   />
                </TabPanel>
                <TabPanel 
                    value="3" 
                    key="premium-transactions-tab"
                >
                    <PremiumTransactions
                        key={`${currentPremium.id}-premium-transactions`}
                        id={currentPremium.id.toString()}
                    />
                </TabPanel>
                <TabPanel
                    value="4"
                    key="premium-statistics-tab"
                >
                    <Paper>
                        {stats.map(entry => {
                            return (
                                <List className="premium-detail__list">
                                    <ListItem>
                                        <ListItemIcon>{entry.icon}</ListItemIcon>
                                        <ListItemText
                                            primary={
                                                entry.id === 'lastQuestion' ? 
                                                currentPremium.lastQuestion?.toDateString() || 'n.d.' : 
                                                    (currentPremium[entry.id] !== null ? currentPremium[entry.id] : 'n.d.')
                                            }
                                            secondary={entry.label}
                                        />
                                    </ListItem>
                                </List>
                            )
                        })}
                    </Paper>
                </TabPanel>
                </TabContext>     
        </React.Fragment>

    );
}

export default PremiumDetail;




