import React, { useEffect, useState } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import TableView from "../dashboard/components/TableView";
import PremiumService from "../../services/Premium-Service";
import IPremium  from "../../models/premium-model";
import { PAGINATION_DEFAULT_SIZE } from '../../Utils/constants';
import { useSnackbar } from 'notistack';
import { Utils } from '../../Utils/utils';
import ISorting, { SortOrder } from '../../models/sorting-model';
import { FilterProps, FilterType } from '../dashboard/components/Filter/Filter';
import { Column } from 'material-table';
import AdvancedSearch from '../dashboard/components/AdvancedSearch/AdvancedSearch';
import locale from '../../languages/locale';
import { FiltersEntries } from '../../models/anagrafica-model';
import ConfirmationModal from '../dashboard/components/ConfirmationModal/ConfirmationModal';
import { AvailableInterfaces } from '../../models/available-interfaces';
import SentInvitesService from '../../services/Invites-Service';
import {FiltersValues, FiltersOptions} from '../../models/anagrafica-model';

function Premium() {
    const { enqueueSnackbar } = useSnackbar();
    const [data, setData] = useState<IPremium[]>([]);
    const [isLoading, setIsLoading] = useState(true);
    const location = useLocation();
    const history = useHistory();
    const [pageSize, setPageSize] = useState<number>(PAGINATION_DEFAULT_SIZE)
    const [items, setItems] = useState<number>(0)
    const [page, setPage] = useState<number>(0)
    const [from, setFrom] = useState<number>(0)
    const [order, setOrder] = useState<ISorting | undefined>()
    const [filters, setFilters] = useState<{ [key: string]: string | Date | null | undefined }>({})
    const [showModal, setShowModal] = useState<boolean>(false);
    const [deleteId, setDeleteId] = useState<number>(0);
    const [deleteEmail, setDeleteEmail] = useState<string>('');
    const [deleteInvitation, setDeleteInvitation] = useState<boolean>(false);

    const tableKeyField: string = 'company_id'

    const onEdit = (rowData: any) => { history.push(`${location.pathname}/${rowData[tableKeyField]}`) };
    const onAdd = (e: any) => { history.push(`${location.pathname}/new`)};
    
    const onDelete = () => {
        if(!deleteInvitation) {
        PremiumService.delete(deleteId)
            .then(res => {
                if(res.error) Utils.responseError(enqueueSnackbar)
                else {
                    enqueueSnackbar('Premium successfully deleted!', Utils.getSnackbarOptions('success'));
                    setIsLoading(true);
                    setShowModal(!showModal);
                }
            })
        } else {
        SentInvitesService.deleteInvitation(deleteId, deleteEmail)
            .then(res => {
                if(res.error) Utils.responseError(enqueueSnackbar);
                else {
                    enqueueSnackbar('Invite successfully deleted!', Utils.getSnackbarOptions('success'));
                    setIsLoading(true);
                    setShowModal(!showModal);
                    setDeleteInvitation(false);
                    setDeleteEmail('');
                    setDeleteId(0);
                }
            })
        }
    };

    const toggleModal = (rowData: any) => {
        if(!showModal) {
            if(!rowData.invited_user && rowData.user?.id && rowData.user?.id !== null) {
                setDeleteId(rowData.user?.id);
            } else {
                setDeleteId(rowData.invited_user);
                setDeleteEmail(rowData.user?.email);
                setDeleteInvitation(true);
            }
        }
        
        setShowModal(!showModal);
    };

    const filtersField: FilterProps[] = [
        { id: FiltersEntries.PIVA, type: FilterType.NUMBER, header: locale.table.vat },
        { id: FiltersEntries.NAME, type: FilterType.TEXT, header: locale.table.name },
        { id: FiltersEntries.USERNAME, type: FilterType.TEXT, header: locale.table.username },
        { id: FiltersEntries.EMAIL, type: FilterType.TEXT, header: locale.table.email },
        { id: FiltersEntries.ANAGRAFICA, type: FilterType.SELECT,
            selectEntries: [
                {
                    value: FiltersValues.FALSE, label: FiltersOptions.INCLUDE_INVITED
                },
                { 
                    value: FiltersValues.EMPTY, label: FiltersOptions.IGNORE_INVITED
                }
            ],
            header: locale.table.anagrafica
        }
    ]

    const columns: Column<object>[] = [
        {
            title: locale.table.id,
            field: tableKeyField,
            defaultSort: order?.orderBy === 'id' ? order.orderDirection : undefined,
            customSort: () => 0
        },
        {
            title: locale.table.vat,
            field: 'p_iva',
            defaultSort: order?.orderBy === 'piva' ? order.orderDirection : undefined,
            customSort: () => 0
        },
        {
            title: locale.table.name,
            field: 'name',
            defaultSort: order?.orderBy === 'name' ? order.orderDirection : undefined,
            customSort: () => 0
        },
        {
            title: locale.table.username,
            field: 'user.username',
            defaultSort: order?.orderBy === 'anagrafica.username' ? order.orderDirection : undefined,
            customSort: () => 0
        },
        {
            title: locale.table.email,
            field: 'user.email',
            defaultSort: order?.orderBy === 'anagrafica.email' ? order.orderDirection : undefined,
            customSort: () => 0
        },
    ]

    const getField = (orderBy: number): string => {
        switch (columns[orderBy]?.field) {
            case tableKeyField:
                return 'id'
            case 'p_iva':
                return 'piva'
            case 'user.username':
                return 'anagrafica.username'
            case 'user.email':
                return 'anagrafica.email'
            default:
                return columns[orderBy]?.field || ''
        }
    }

    useEffect(() => {
        if (isLoading) {
            const newfilters = {
                ...filters,
                'anagrafica.blocked': 'false'
            }
            PremiumService.getAll(pageSize, from, order, newfilters)
                .then(res => {
                    if (res.payload && res.items >= 0){
                        const parsedData = res.payload.map((entry: any) => {
                            if(entry.company_id) return entry;
                            else return {...entry, user: {email: entry.email}}
                        })
                        setData(parsedData)
                        setItems(res.items)
                    } else {
                        setData([])
                        Utils.responseError(enqueueSnackbar)
                    }
                    
                })
                .catch(err => {
                    console.log(err)
                    Utils.responseError(enqueueSnackbar)
                })
                .finally(() => setIsLoading(false))
        }
    }, [isLoading, pageSize, from, order, filters]);

    const onStartPage = (size: number) => {
        setPage(0)
        setFrom(0)
        setPageSize(size)
        setIsLoading(true)
    }

    const onChangePage = (currentPage: number, currentPageSize: number) => {
        setPage(currentPage)
        if (currentPage > 0) {
            setFrom(pageSize * currentPage)
            setIsLoading(true)
        }
        else
            onStartPage(currentPageSize)
    }

    const onOrderChange = (orderBy: number, orderDirection: "asc" | "desc") => {
        const direction = orderDirection === "asc" ? SortOrder.ASC : SortOrder.DESC
        if (orderBy !== -1)
            setOrder({ orderBy: getField(orderBy), orderDirection: direction })
        else
            setOrder(undefined)
        setPage(0)
        setFrom(0)
        setIsLoading(true)
    }

    const onApplyFilters = (filters: { [key: string]: string | Date | null | undefined }) => {
        if(filters[FiltersEntries.ANAGRAFICA] && filters[FiltersEntries.ANAGRAFICA] === FiltersValues.FALSE){
            setFilters(filters)
        } else if(filters[FiltersEntries.ANAGRAFICA]){
            delete filters[FiltersEntries.ANAGRAFICA]
        };
        setFilters(filters)
        setIsLoading(true)
    }

    return <>
            <ConfirmationModal
                open={showModal}
                itemInterface={AvailableInterfaces.PREMIUM}
                onConfirm={onDelete}
                onToggle={toggleModal}
            />
            <AdvancedSearch
                header={locale.shared.search}
                filters={filtersField}
                onSubmit={onApplyFilters}
            />

            <TableView
                tableKeyField={tableKeyField}
                title={locale.shared.premium}
                columns={columns}
                data={data}
                items={items}
                page={page}
                currentPageSize={pageSize}
                loading={isLoading}
                onEdit={onEdit}
                onAdd={onAdd}
                onDelete={toggleModal}
                onChangeRowsPerPage={onStartPage}
                onChangePage={onChangePage}
                onOrderChange={onOrderChange}
            />
        </>
}

export default Premium;
