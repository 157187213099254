import { useSnackbar } from 'notistack';
import React, { useState, useEffect } from 'react';
import "./create-question.css"
import ICategory from '../../../../models/category-model';
import { ISubCategory } from '../../../../models/category-model';
import IQuestion, { QuestionKinds, QuestionTypes } from "../../../../models/question-model";
import CategoryService from '../../../../services/Category-Service';
import QuestionService from "../../../../services/Question-Service";
import SubcategoryService from '../../../../services/Subcategory-Service';
import PriceService from '../../../../services/Price-Service';
import { Utils } from '../../../../Utils/utils';
import CustomForm, { FormType, DataType, FieldsProps } from '../question-form/customForm';
import {
    QUESTION_DESCRIPTION_CHARACTER_LIMIT,
    QUESTION_DESCRIPTION_ROWS,
    QUESTION_TITLE_CHARACTER_LIMIT,
    QUESTION_TITLE_ROWS
} from '../../../../Utils/constants';
import ClubService from '../../../../services/Club-Service';
import { Alert } from '@material-ui/lab';
import InfoIcon from '@material-ui/icons/Info';
import UserService from '../../../../services/User-Service';
import PaymentService from '../../../../services/Payment-Service';
import TransactionService from '../../../../services/Transaction-Service';
import { AvailableInterfaces } from '../../../../models/available-interfaces';
import moment from 'moment';

function CreateQuestion() {

    const { enqueueSnackbar } = useSnackbar();
    const [isLoading, setIsLoading] = useState(false)
    const timezone = `(UTC ${moment.parseZone(moment().format()).format('Z')})`
    const [data, setData] = useState<IQuestion>({
        id: 0,
        title: '',
        description: '',
        publicationDate: new Date(),
        type: QuestionTypes.STANDARD,
        kind: QuestionKinds.QUALITATIVE,
        endingDate: new Date(),
        leftValue: '',
        rightValue: '',
        category: { id: 0, value: '' },
        subCategories: [],
        subCategories2: [],
        totalUsers: 0,
        price: 0,
        usersClub: [],
        usersCategory: [],
        priceId: 0,
    });
    const [editFields, setEditFields] = useState(false);
    const [fields, setFields] = useState<FieldsProps[]>([
        {
            required: true,
            formType: FormType.INPUT,
            id: 'title',
            dataType: DataType.STRING,
            label: 'Question Title',
            error: false,
            helperText: 'Please provide a title',
            position: 0,
            touched: false,
            show: true,
            multiline: true,
            rows: QUESTION_TITLE_ROWS,
            characterLimit: QUESTION_TITLE_CHARACTER_LIMIT,
            customClass: 'create-question__field-full',
            characterCount: QUESTION_TITLE_CHARACTER_LIMIT.toString()
        },
        {
            required: true,
            formType: FormType.INPUT,
            id: 'description',
            dataType: DataType.STRING,
            label: 'Question Description',
            error: false,
            helperText: 'Please provide a description',
            position: 1,
            touched: false,
            show: true,
            multiline: true,
            rows: QUESTION_DESCRIPTION_ROWS,
            characterLimit: QUESTION_DESCRIPTION_CHARACTER_LIMIT,
            customClass: 'create-question__field-full',
            characterCount: QUESTION_DESCRIPTION_CHARACTER_LIMIT.toString()
        },
        {
            required: true,
            formType: FormType.SELECT,
            id: 'type',
            dataType: DataType.STRING,
            label: 'Question Type',
            error: false,
            helperText: 'Please select a type',
            selectEntries: [QuestionTypes.STANDARD, QuestionTypes.PREMIUM],
            position: 2,
            touched: true,
            show: true,
            customClass: 'create-question__half-field',
        },
        {
            required: true,
            formType: FormType.SELECT,
            id: 'kind',
            dataType: DataType.STRING,
            label: 'Question Kind',
            error: false,
            helperText: 'Please select a kind',
            selectEntries: [QuestionKinds.QUANTITATIVE, QuestionKinds.QUALITATIVE],
            position: 3,
            touched: true,
            show: true,
            customClass: 'create-question__half-field'
        },
        {
            required: true,
            formType: FormType.INPUT,
            id: 'publicationDate',
            dataType: DataType.DATE,
            label: `Date and time of publication ${timezone}`,
            error: false,
            helperText: 'Publication date must be set past now',
            position: 4,
            touched: false,
            show: true,
            customClass: 'create-question__date-field'
        },
        {
            required: true,
            formType: FormType.INPUT,
            id: 'endingDate',
            dataType: DataType.DATE,
            label: `Ending Date ${timezone}`,
            error: false,
            helperText: 'Ending date must be set past the date of publication',
            position: 5,
            touched: false,
            show: true,
            customClass: 'create-question__date-field'
        },
        {
            required: true,
            formType: FormType.INPUT,
            id: 'eventDate',
            dataType: DataType.DATE,
            label: `Expected Event Date ${timezone}`,
            error: false,
            helperText: 'Date of event must be set past ending date (or equal to it)',
            position: 6,
            touched: false,
            show: true,
            customClass: 'create-question__date-field'
        },
        {
            required: true,
            formType: FormType.INPUT,
            id: 'leftValue',
            dataType: DataType.STRING,
            label: 'Minimum Value',
            error: false,
            helperText: 'Please provide a minimum value',
            position: 7,
            touched: false,
            show: true,
            customClass: 'create-question__value-field'
        },
        {
            required: false,
            formType: FormType.INPUT,
            id: 'midValue',
            dataType: DataType.STRING,
            label: 'Medium Value',
            error: false,
            helperText: 'Please provide a medium value',
            position: 8,
            touched: false,
            show: true,
            customClass: 'create-question__value-field'
        },
        {
            required: true,
            formType: FormType.INPUT,
            id: 'rightValue',
            dataType: DataType.STRING,
            label: 'Maximum Value',
            error: false,
            helperText: 'Please provide a maximum value',
            position: 9,
            touched: false,
            show: true,
            customClass: 'create-question__value-field'
        },
        {
            required: false,
            formType: FormType.SELECT,
            id: 'module',
            dataType: DataType.STRING,
            label: 'Measure Unit',
            error: false,
            helperText: 'Please select a measure unit',
            selectEntries: ['','€', '$', 'Kg', '%'],
            position: 10,
            touched: false,
            show: false,
            customClass: 'create-question__value-field'
        },
        {
            required: true,
            formType: FormType.SELECT,
            id: 'category',
            dataType: DataType.OBJECT,
            label: 'Question category',
            error: false,
            helperText: 'Please choose a category',
            position: 11,
            touched: false,
            show: true,
            customClass: 'create-question__half-field',
            omitRecap: true
        },
        {
            required: false,
            formType: FormType.SELECT,
            id:"category2",
            dataType: DataType.OBJECT,
            label: "Question second category",
            error: false,
            helperText: "The choose two different categories",
            position: 12,
            touched: false,
            show: true,
            customClass: "create-question__half-field",
            omitRecap: true
        },
        {
            required: false,
            formType: FormType.AUTOCOMPLETE,
            id: 'subCategories',
            dataType: DataType.STRING,
            label: 'Question sub-category',
            error: false,
            position: 13,
            touched: false,
            show: true,
            customClass: 'create-question__half-field',
            selectEntries: [],
            omitRecap: true
        },
        {
            required: false,
            formType: FormType.AUTOCOMPLETE,
            id: 'subCategories2',
            dataType: DataType.STRING,
            label: 'Question second category sub-category',
            error: false,
            position: 14,
            touched: false,
            show: true,
            customClass: 'create-question__half-field',
            selectEntries: [],
            omitRecap: true
        },
        {
            required: false,
            formType: FormType.AUTOCOMPLETE,
            id: 'usersClub',
            dataType: DataType.STRING,
            label: 'Filter users by club',
            error: false,
            position: 15,
            touched: false,
            show: false,
            customClass: 'create-question__field-full',
            omitRecap: true
        },
        {
            required: false,
            formType: FormType.AUTOCOMPLETE,
            id: 'usersCategory',
            dataType: DataType.STRING,
            label: 'Filter users by category',
            error: false,
            position: 16,
            touched: false,
            show: false,
            customClass: 'create-question__field-full',
            omitRecap: true
        },
        {
            required: false,
            formType: FormType.CHECKBOX,
            id: 'showAuthor',
            dataType: DataType.BOOLEAN,
            label: 'Show Author',
            error: false,
            position: 17,
            touched: false,
            show: false,
            customClass: 'create-question__field-full create-question__show-author',
        },
        {
            required: false,
            formType: FormType.SLIDER,
            id: 'totalUsers',
            dataType: DataType.NUMBER,
            label: 'Number of Rightrend users to query',
            error: false,
            helperText: 'Please choose a number of rightrend users to query',
            position: 18,
            touched: false,
            show: false,
            customClass: 'create-question__slider',
        }
    ]);
    const [backupData, setBackupData] = useState<IQuestion>({ ...data });
    const [disableButton, setDisableButton] = useState(true);
    const [showDialog, setShowDialog] = useState(false);
    const alertHeadString = `Your question's final price is only: `
    const [alertMessage, setAlertMessage] = useState(``);
    const [alertElement, setAlertElement] = useState(false);
    const [categories, setCategories] = useState<ICategory[]>([]);
    const [isPremium, setIsPremium] = useState<boolean>(false);
    const [premiumConfig, setPremiumConfig] = useState<boolean>(false);
    const [maxUsers, setMaxUsers ] = useState<number>(0);
    const [userPrice, setUserPrice] = useState<number>(0);
    const [serverPrice, setServerPrice] = useState<number>(0);
    const discount = 0; //placeholder
    const [sliderValue, setSliderValue] = useState<number>(0);

    //open recap dialog
    const toggleDialog = (e: any) => {
        const newValue = !showDialog;
        setShowDialog(newValue);
    }

    //submit question
    const onSave = (e: any) => {
        setIsLoading(true)
        setShowDialog(false);
        QuestionService.add(data, isPremium)
            .then(res => {
                if(res.error){
                    Utils.responseError(enqueueSnackbar)
                } else {
                    enqueueSnackbar('Question successfully sent!', Utils.getSnackbarOptions('success'));
                    if(data.type === QuestionTypes.PREMIUM){
                        TransactionService.getSessionId(res.payload.id)
                            .then(res => {
                                if(res.error){
                                    enqueueSnackbar('A problem occurred during checkout, please try again', Utils.getSnackbarOptions('error'))
                                } else {
                                    PaymentService.redirectToCheckout(res.payload.id)
                                }
                            })
                    }
                    onReset()
                }
            })
            .catch(err => {
                console.log(err);
                Utils.responseError(enqueueSnackbar)
            })
            .finally(() => {
                setIsLoading(false);
            })
    };

    //reset question data and fields props
    const onReset = () => {
        const newFields = fields.map(entry => {
            return {
                ...entry,
                error: false,
                touched: (entry.id === 'type' || entry.id === 'kind'),
                show: entry.id === 'totalUsers' ?
                    false : entry.show,
                selectEntries: entry.id === 'subCategories' ?
                    undefined : entry.selectEntries,
            }
        })
        //RESET CHAR COUNTERS
        const newTitle = newFields.find(field => field.id === 'title');
            if(newTitle) newTitle.characterCount = QUESTION_TITLE_CHARACTER_LIMIT.toString();
        const newDesc = newFields.find(field => field.id === 'description');
            if(newDesc) newDesc.characterCount = QUESTION_DESCRIPTION_CHARACTER_LIMIT.toString();
        setFields([...newFields]);
        setData(backupData);
        setEditFields(true);
    };
    //UPDATE SUB-CATEGORY OPTIONS
    const updateSubcategories = (categoryone: boolean, category?: number) => {
        if(category){
            SubcategoryService.getSubcategories(category)
                .then(res => {
                    const response = res;
                    const newFields = [...fields];
                    var toUpdate = categoryone ? newFields.find(entry => entry.id === "subCategories") : newFields.find(entry => entry.id === "subCategories2")
                    newFields[toUpdate!.position].selectEntries = response.map(entry => {return {id: entry.id, value: entry.value}})
                    setFields([
                        ...newFields
                    ]);
                });
        }
    };
    const getPrice = (users: number) => {
        const chosenUsers = users;
        PriceService.getPrice(users)
            .then(res => {
                if(res.payload){
                    setServerPrice(res.payload.price);
                    setUserPrice(Math.round(res.payload.price / chosenUsers * 100) / 100);
                    setData({
                        ...data,
                        totalUsers: chosenUsers,
                        price: res.payload.price,
                        priceId: res.payload.id,
                    });
                }
            })
            .catch(err => {
                enqueueSnackbar('Something went wrong while elaborating the price, please try again')
            })
    };
    
    
    //all-round change handler
    const handleChange = (e: any, newValue?: any[], elementKey?: string) => {
            const inputValue = e.target.value || newValue || '';
            const key = e.target.name || elementKey;
            switch(key){
                case "usersClub":
                case "usersCategory": {
                    setPremiumConfig(true);
                    setData({
                        ...data,
                        [key]: inputValue,
                        totalUsers: 0
                    });
                    break
                }
                case "title": {
                    var newFields = [...fields];
                    var updated = newFields.find(entry => {if(entry.id === "title") return entry});
                    updated!.characterCount = (QUESTION_TITLE_CHARACTER_LIMIT -  inputValue.length).toString();
                    setFields([
                        ...newFields
                    ]);
                    setData({
                        ...data,
                        [key]: inputValue
                    });
                    break
                }
                case "description": {
                    var newFields = [...fields];
                    var updated = newFields.find(entry => {if(entry.id === "description") return entry});
                    updated!.characterCount = (QUESTION_DESCRIPTION_CHARACTER_LIMIT - inputValue.length).toString();
                    setFields([
                        ...newFields
                    ]);
                    setData({
                        ...data,
                        [key]: inputValue
                    });
                    break
                }
                case "kind":
                case "type": {
                    setEditFields(true);
                    setData({
                        ...data,
                        [key]: inputValue
                    });
                    break
                }
                case "category": {
                    const newFields = [...fields];
                    const toUpdate = newFields.find(entry => entry.id === "category2" );
                    const filterCats = categories.filter(entry => entry.id !== inputValue);
                    newFields[toUpdate!.position].selectEntries = filterCats.concat([{id: 0, value: ''}]);
                    let updatedData = {...data};
                    if(inputValue === data.category2?.id) updatedData.category2 = undefined;
                    let selectedCategory = categories.find(entry => entry.id === inputValue);
                    setFields([
                        ...newFields
                    ]);
                    setData({
                        ...updatedData,
                        [key]: selectedCategory,
                        subCategories: [],
                        subCategories2: []
                    });
                    break
                }
                case "category2": {
                    if(data.category && inputValue === data.category.id) break
                    else{
                        let selectedCategory = categories.find(entry => entry.id === inputValue);
                        setFields([...fields])
                        setData({
                            ...data,
                            [key]: selectedCategory,
                            subCategories2: []
                        });
                    }
                    break
                }
                case "showAuthor": {
                    setData({
                        ...data,
                        [key]: e.target.checked
                    });

                    break
                }
                default: {
                    setData({
                        ...data,
                        [key]: inputValue
                    });
                }
            };
    };
    const handleSlider = (e: any, value: number | number[]) => {
        if(typeof value === 'number') {
            setSliderValue(value)
        }
    };
    const handleSliderCommitted = (e:any, value: number | number[]) => {
        if(typeof value === 'number') {
            getPrice(value)
        }
    }

    //set FieldsProps error
    const handleValidation = (element: FieldsProps) => {
        switch (element.id) {
            case 'publicationDate': {
                const newFields = [...fields]
                const now = new Date();
                const input = new Date(data[element.id]);
                const ending = new Date(data.endingDate);
                const endingIndex = fields.findIndex(field => field.id === 'endingDate');
                const endingTouched = fields[endingIndex].touched;
                const newInput: FieldsProps = {
                    ...element,
                    error: input < now || (input > ending && endingTouched),
                    touched: true,
                    helperText: input > ending && endingTouched ?
                        'Publication date must be set before ending date' :
                        'Publication date must be set past now'
                };
                if (endingTouched) {
                    if (input < ending)
                        newFields[endingIndex].error = false;
                    else
                        newFields[endingIndex].error = true;
                }
                newFields[element.position] = newInput;
                setFields(newFields)
                break
            }
            case 'endingDate': {
                const newFields = [...fields]
                const publication = new Date(data.publicationDate);
                const ending = new Date(data[element.id]);
                const event = new Date(data.eventDate!);
                const eventIndex = fields.findIndex(field => field.id === 'eventDate');
                const eventTouched = fields[eventIndex].touched;
                const newInput = { ...element, error: publication > ending, touched: true }
                if (eventTouched) {
                    if (event >= ending)
                        newFields[eventIndex].error = false;
                    else
                        newFields[eventIndex].error = true;
                }
                newFields[element.position] = newInput;
                setFields(newFields)
                break
            }
            case 'eventDate': {
                const newFields = [...fields]
                const ending = data.endingDate;
                const event = data[element.id];
                const newInput = { ...element, error: !(event && event >= ending), touched: true }
                newFields[element.position] = newInput;
                setFields(newFields)
                break
            }
            case 'totalUsers': {
                const newFields = [...fields]
                const userInput: boolean = data[element.id]! < 1;
                const newInput = { ...element, error: userInput, touched: !userInput };
                newFields[element.position] = newInput;
                setFields(newFields)
                break
            }
            case "category":
            case "category2": {
                const newFields = [...fields]
                var updated = newFields[element.position]
                updated = {...updated, touched: true}
                newFields[updated.position] = updated
                setFields([
                    ...newFields
                ])
                break
            }
            case "leftValue":
            case "rightValue": {
                const newFields = [...fields];
                    const value = data[element.id];
                    const userInput: boolean = !!value && value !== ''; 
                if(data.kind === QuestionKinds.QUANTITATIVE){
                    const test: boolean = isNaN(parseInt(data[element.id]!));
                    console.log(userInput)
                    const newInput = {
                        ...element, 
                        error: test ? true : !userInput,
                        touched: userInput,
                        helperText: "Please provide a numeric value"
                    };
                    newFields[element.position] = newInput;
                    
                } else {
                    const newInput = { 
                        ...element, 
                        error: !(element.required && userInput), 
                        touched: userInput,
                        helperText: "Please provide a value" 
                    };
                    newFields[element.position] = newInput;
                   
                }
                setFields(newFields)
                break
            }
            default: {
                const newFields = [...fields]
                const value = data[element.id];
                const userInput = value && value !== undefined;
                const newInput = { 
                    ...element, 
                    error: element.required ? !userInput : false, 
                    touched: userInput 
                };
                newFields[element.position] = newInput;
                setFields(newFields)
                break
            }
        }
    };

    //check if there are any missing fields
    useEffect(()=>{
        const missingFields = !fields.filter(key => key.required).every(key => key.touched && !key.error);
        setDisableButton(missingFields);
        setAlertMessage(`${alertHeadString} ${data.price?.toFixed(2)} €`);
        setAlertElement(!missingFields && data.type === QuestionTypes.PREMIUM);
    }, [fields])

    //get categories and subcategories
    useEffect(() => {
        CategoryService.getAll()
            .then(res => {
                setCategories(res)
                const f = [...fields]
                const category = res.map(c => {
                    return { id: c.id, value: c.value }
                })
                f[f.findIndex(field => field.id === 'category')].selectEntries = category
                f[f.findIndex(field => field.id === "category2")].selectEntries = category.filter(entry => entry.id !== data.category.id)
                f[f.findIndex(field => field.id === 'usersCategory')].selectEntries = category
                setFields(f)
            })
            .catch(e => {
                console.log(e)
                Utils.responseError(enqueueSnackbar)
            })
    }, [])

    //set show and touched state of fields
    useEffect(() => {
        if (editFields) {
            //switch required and visibility of fields
            const newData = { ...data }
            const newFields: FieldsProps[] = fields.map(key => {
                switch (key.id) {
                    case 'module': {
                        const updatedKey = {
                            ...key,
                            show: (data.kind === QuestionKinds.QUANTITATIVE)
                        }
                        if (data.kind === QuestionKinds.QUALITATIVE) {
                            newData.module = ''
                            setData(newData)
                            updatedKey.touched = false
                        }
                        return updatedKey
                    }
                    case 'midValue': {
                        const updatedKey = {
                            ...key,
                            show: (data.kind === QuestionKinds.QUALITATIVE)
                        }
                        if (data.kind === QuestionKinds.QUANTITATIVE) {
                            newData.midValue = ''
                            setData(newData)
                            updatedKey.touched = false
                        }
                        return updatedKey
                    }
                    case 'totalUsers': {
                        const updatedKey = {
                            ...key,
                            required: (data.type === QuestionTypes.PREMIUM),
                            show: (data.type === QuestionTypes.PREMIUM),
                            touched: (data.type === QuestionTypes.PREMIUM && data.totalUsers! > 0)
                        }
                        return updatedKey
                    }
                    case 'eventDate': {
                        const updatedKey = {
                            ...key,
                            required: (data.type === QuestionTypes.STANDARD),
                            show: (data.type === QuestionTypes.STANDARD)
                        }
                        if (data.type === QuestionTypes.PREMIUM) {
                            newData.eventDate = new Date()
                            setData(newData)
                            updatedKey.touched = false
                        }
                        return updatedKey
                    }
                    case 'usersClub': {
                        const updatedKey = {
                            ...key,
                            show: (data.type === QuestionTypes.PREMIUM),
                        }
                        if (data.type === QuestionTypes.STANDARD) {
                            newData.usersClub = []
                            setData(newData)
                            updatedKey.touched = false
                        }
                        return updatedKey
                    }
                    case 'usersCategory': {
                        const updatedKey = {
                            ...key,
                            show: (data.type === QuestionTypes.PREMIUM),
                        }
                        if (data.type === QuestionTypes.STANDARD) {
                            newData.usersCategory = []
                            setData(newData)
                            updatedKey.touched = false
                        }
                        return updatedKey
                    }
                    case 'type': {
                        if (data.type === QuestionTypes.PREMIUM)
                            setIsPremium(true)
                        else
                            setIsPremium(false)
                        return key
                    }
                    case 'publicationDate':
                    case 'endingDate': {
                        const updatedKey = {
                            ...key,
                            customClass: (data.type === QuestionTypes.STANDARD ? "create-question__date-field" : "create-question__premium-date-field")
                        }
                        return updatedKey
                    }
                    case 'showAuthor': {
                        const updatedKey = {
                            ...key,
                            show: (data.type === QuestionTypes.PREMIUM),
                            touched: (data.type === QuestionTypes.PREMIUM)
                        }
                        if (data.type === QuestionTypes.STANDARD) {
                            newData.showAuthor = undefined
                            setData(newData)
                            updatedKey.touched = false
                        } else
                            newData.showAuthor = false
                        return updatedKey
                    }
                    default: {
                        return key
                    }
                };
            });
            setFields([...newFields]);
            setEditFields(false);
        }
    }, [editFields]);
    //GET CLUBS
    useEffect(() => {
        if(isPremium){
            setIsLoading(true)
            ClubService.getClub()
                .then(club => {
                    if (club) {
                        const f = [...fields]
                        f[f.findIndex(field => field.id === 'usersClub')].selectEntries = club
                        setFields(f)
                        setPremiumConfig(true)
                    } else
                        Utils.responseError(enqueueSnackbar)
                })
                .catch(e => {
                    console.log(e)
                    Utils.responseError(enqueueSnackbar)
                })
                .finally(() => setIsLoading(false))
        }
    }, [isPremium])
    //GET MAX USER NUMBER
    useEffect(() => {
        if (premiumConfig) {
            const usersClub = data['usersClub']?.length ? data['usersClub'].map(c => c.id.toString()) : undefined
            const usersCategory = data['usersCategory']?.length ? data["usersCategory"].map(cat => cat.id.toString()) : undefined

            setIsLoading(true)
            UserService.getUsersNumber(usersClub, usersCategory)
            .then(res =>{
                if (res.payload >= 0) {
                    setMaxUsers(res.payload)
                    const f = [...fields]
                    f[f.findIndex(field => field.id === 'totalUsers')].max = res.payload
                    setFields([...f])
                    setPremiumConfig(false)
                }
                else
                    Utils.responseError(enqueueSnackbar)
            })
            .catch(e => {
                console.log(e)
                Utils.responseError(enqueueSnackbar)
            })
            .finally(() => {
                setIsLoading(false)
                setPremiumConfig(false)
            })
        }
    }, [premiumConfig]);
    //get and filter subcategories when data categories change
    useEffect(() => {
        updateSubcategories(true , data.category.id)

    },[data.category]);
    
    useEffect(() => {
        updateSubcategories(false , data.category2?.id)

    },[data.category2]);
   

    const summary = () => data.type === QuestionTypes.PREMIUM ?
        <div className='create-question__summary'>
            {data.totalUsers! > 0 &&
                <Alert
                    variant='filled'
                    icon={<InfoIcon />}
                    color='success'
                >
                    For the next 20 minutes the maximum price of the question is: {data.price?.toFixed(2)} € ({userPrice.toFixed(2)} € per user)
                </Alert>
            }
        </div>
        : <></>

    return (
        <React.Fragment>
            <CustomForm
                customClassName="create-question"
                formInterface={AvailableInterfaces.QUESTION}
                data={data}
                fields={fields}
                disableButton={disableButton}
                toggleDialog={toggleDialog}
                showDialog={showDialog}
                alertElement={alertElement}
                alertMessage={alertMessage}
                title='Submit a question'
                onSave={onSave}
                onReset={onReset}
                handleChange={handleChange}
                handleValidation={handleValidation}
                isLoading={isLoading}
                summary={summary()}
                sliderValue={sliderValue}
                handleSlider={handleSlider}
                handleSliderCommitted={handleSliderCommitted}
                />
        </React.Fragment>
    )
}

export default CreateQuestion;
